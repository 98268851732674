import React, { useState, useRef } from "react";
import { Col, Form, Button, Row } from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import { twofaenableDisable } from "../coreFiles/action";
import config from "../coreFiles/config";
const Profile2faOne = (props) => {
  const [code, setCode] = useState({
    one: "",
    two: "",
    three: "",
    four: "",
    five: "",
    six: "",
    tab: 1,
  });

  // Refs for input fields
  const inputOneRef = useRef(null);
  const inputTwoRef = useRef(null);
  const inputThreeRef = useRef(null);
  const inputFourRef = useRef(null);
  const inputFiveRef = useRef(null);
  const inputSixRef = useRef(null);

  const nextScreen = () => {
    props.nextScreen(6);
  };

  const submitCode = async () => {
    let res = await twofaenableDisable({
      secretKey: `${code.one}${code.two}${code.three}${code.four}${code.five}${code.six}`,
    });
    if (res.success) {
      toast.success(res.msg);
      setCode({
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
        six: "",
        tab: 1,
      });
      window.location.href = `${config.baseUrl}2faauthentication`
      props.nextScreen(6);
    } else {
      toast.error(res.msg);
    }
  };

  const focusNextInput = (currentTab, value) => {

    if (currentTab === 1 && value) inputTwoRef.current.focus();
    else if (currentTab === 2 && value) inputThreeRef.current.focus();
    else if (currentTab === 3 && value) inputFourRef.current.focus();
    else if (currentTab === 4 && value) inputFiveRef.current.focus();
    else if (currentTab === 5 && value) inputSixRef.current.focus();
    else if (currentTab === 2 && !value) inputOneRef.current.focus();
    else if (currentTab === 3 && !value) inputTwoRef.current.focus();
    else if (currentTab === 4 && !value) inputThreeRef.current.focus();
    else if (currentTab === 5 && !value) inputFourRef.current.focus();
    else if (currentTab === 6 && !value) inputFiveRef.current.focus();
  };

  return (
    <>
      <Toaster />
      <Col xl={8} lg={8} md={12} xs={12} className="pt-5">
        <div className="profileContent p-lg-5 p-md-5 p-3">
          <Row>
            <Col xl={12} lg={12} md={12} xs={12} className="">
              <div className="profile">
                <h2 className="mb-4 text-white">
                  2FA
                  <span className="ms-1 maincolor2">
                    {props.enabled == 1 ? "Enabled" : "Disabled"}
                  </span>
                </h2>
                <p className="text-center">
                  Enter 6-digit code from Google Authenticate to make sure{" "}
                  <br />
                  everything works
                </p>
              </div>
            </Col>
          </Row>
          <Form className="pt-4">
            <Row>
              <Col
                xl={9}
                lg={12}
                md={12}
                xs={12}
                className="verifyContent mx-auto"
              >
                <Row>
                  <Col xl={2} lg={2} md={2} xs={2} className="">
                    <Form.Group className="mb-3 verifyFiled">
                      <Form.Control
                        type="tel"
                        onKeyPress={(event) => {
                          if (!/^\d{0,1}$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        ref={inputOneRef}
                        value={code.one}
                        placeholder=""
                        autoFocus={code.one.length === 0 && code.tab === 1}

                        onChange={(e) => {
                          const newValue = e.target.value.slice(0, 1);
                          if (code.one.length <= 1) {
                            setCode((prevCode) => ({
                              ...prevCode,
                              one: newValue,
                            }));
                          }
                          focusNextInput(1, newValue);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={2} lg={2} md={2} xs={2} className="">
                    <Form.Group className="mb-3 verifyFiled">
                      <Form.Control
                        type="tel"
                        onKeyPress={(event) => {
                          if (!/^\d{0,1}$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder=""
                        value={code.two}
                        ref={inputTwoRef}
                        onChange={(e) => {
                          const newValue = e.target.value.slice(0, 1);
                          if (code.two.length <= 1) {
                            setCode((prevCode) => ({
                              ...prevCode,
                              two: newValue,
                            }));
                          }
                          focusNextInput(2, newValue);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={2} lg={2} md={2} xs={2} className="">
                    <Form.Group className="mb-3 verifyFiled">
                      <Form.Control
                        type="tel"
                        onKeyPress={(event) => {
                          if (!/^\d{0,1}$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder=""
                        value={code.three}
                        ref={inputThreeRef}
                        onChange={(e) => {
                          const newValue = e.target.value.slice(0, 1);
                          if (code.three.length <= 1) {
                            setCode((prevCode) => ({
                              ...prevCode,
                              three: newValue,
                            }));
                          }
                          focusNextInput(3, newValue);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={2} lg={2} md={2} xs={2} className="">
                    <Form.Group className="mb-3 verifyFiled">
                      <Form.Control
                        type="tel"
                        onKeyPress={(event) => {
                          if (!/^\d{0,1}$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder=""
                        value={code.four}
                        ref={inputFourRef}
                        onChange={(e) => {
                          const newValue = e.target.value.slice(0, 1);
                          if (code.four.length <= 1) {
                            setCode((prevCode) => ({
                              ...prevCode,
                              four: newValue,
                            }));
                          }
                          focusNextInput(4, newValue);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={2} lg={2} md={2} xs={2} className="">
                    <Form.Group className="mb-3 verifyFiled">
                      <Form.Control
                        type="tel"
                        onKeyPress={(event) => {
                          if (!/^\d{0,1}$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder=""
                        value={code.five}
                        ref={inputFiveRef}
                        onChange={(e) => {
                          const newValue = e.target.value.slice(0, 1);
                          if (code.five.length <= 1) {
                            setCode((prevCode) => ({
                              ...prevCode,
                              five: newValue,
                            }));
                          }
                          focusNextInput(5, newValue);
                        }}
                      />
                    </Form.Group>
                  </Col>
                  <Col xl={2} lg={2} md={2} xs={2} className="">
                    <Form.Group className="mb-3 verifyFiled">
                      <Form.Control
                        type="tel"
                        onKeyPress={(event) => {
                          if (!/^\d{0,1}$/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        placeholder=""

                        ref={inputSixRef}
                        value={code.six}
                        onChange={(e) => {
                          const newValue = e.target.value.slice(-1); // Only take the last character
                          if (code.six.length <= 1) {
                            setCode((prevCode) => ({
                              ...prevCode,
                              six: newValue,
                            }));
                          }
                          focusNextInput(6, newValue);
                        }}
                      />
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <Row>
            <Col xl={6} lg={6} md={6} xs={12} className=" mx-auto">
              <Row>
                <Col xl={6} lg={6} md={6} xs={12} className="pt-4">
                  <Button
                    variant="custombtn2"
                    className="px-4 w-100"
                    onClick={nextScreen}
                  >
                    Back
                  </Button>
                </Col>
                <Col xl={6} lg={6} md={6} xs={12} className="pt-4">
                  <Button
                    variant="custombtn1"
                    className="px-4 w-100"
                    onClick={submitCode}
                  >
                    Done
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default Profile2faOne;
