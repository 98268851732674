import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { getReferrals } from '../coreFiles/action';
import '../components/componentCss/profile.css'
import { HiMiniChevronUpDown } from "react-icons/hi2";
import config from "../coreFiles/config";
import { Link } from 'react-router-dom';
const ReferralHistory = (props) => {
    const [history, setHistory] = useState([])

    const nextScreen = () => {
        props.nextScreen(10)
    }

    useEffect(() => {
        getReferralsList()
    }, [])

    const getReferralsList = async () => {
        let res = await getReferrals()
        if (res.success) {
            setHistory(res.data)
        }
    }

    return (
        <>

            <Col xl={8} lg={8} md={12} xs={12} className="pt-5">
                <div className="profileContent p-lg-5 p-md-5 p-3">
                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="">
                            <div className=" walletTable">
                                <div className=" table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>ID<span><HiMiniChevronUpDown className="dropIcon" /></span></th>
                                                <th>Username <span><HiMiniChevronUpDown className="dropIcon" /></span></th>
                                                <th>Phone  <span><HiMiniChevronUpDown className="dropIcon" /></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {history && history.length > 0 ? history.map(item =>
                                                <tr className="active">
                                                    <td>
                                                        <h6>
                                                            {item.id}
                                                        </h6>

                                                    </td>
                                                    <td>
                                                        <h6>
                                                            {!item.username ? "Anonymous" : item.username}
                                                        </h6>

                                                    </td>
                                                    <td>
                                                        <h6>
                                                            {item.phone}
                                                        </h6>

                                                    </td>
                                                </tr>) : ""}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6} lg={6} md={6} xs={12} className="mt-3 mx-auto text-center">
                            <Button
                                variant="custombtn1"
                                className="px-4"
                                onClick={e => nextScreen(e)}
                            >
                                Back
                            </Button>
                        </Col>
                    </Row>

                </div>
            </Col>



        </>
    )
}

export default ReferralHistory;
