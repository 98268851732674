const config = {
    /* Local Server */

//     baseUrl: '/',
//     imageUrl: "http://localhost:8000/api/user/uploads/",
//     apiUrl: "http://localhost:8000/api/user/",
//     socketURL:"http://localhost:5000",
// // socketURL: "https://spaceapp.espsofttech.org:3022",
//     webSiteUrl:"http://localhost:3000",

    /*  ESP Server */

    baseUrl: '/',
    imageUrl: "https://spaceapp.espsofttech.org:3022/api/user/uploads/",
    apiUrl: "https://spaceapp.espsofttech.org:3022/api/user/",
    socketURL: "https://spaceapp.espsofttech.org:3022",
    webSiteUrl: "https://spacebitz.espsofttech.org"

    /*  Live Server */

    // baseUrl: '/',
    // imageUrl: "https://spacebitz.com/media/",
    // apiUrl: "https://spacebitz.com/api/user/",
    // socketURL: "https://spacebitz.com:3022",
    // webSiteUrl: "https://spacebitz.com"



}
export default config;