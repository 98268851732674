import React, { Component, useEffect, useState } from "react";
import LoginHeader from "../directives/header";
import Footer from "../directives/footer";
import Spinner from 'react-bootstrap/Spinner';
import {
  Container,
  Row,
  Col
} from "react-bootstrap";
import { getWidgetListAction } from "../coreFiles/action";
import config from "../coreFiles/config";
const Market = () => {
  const [widgetList, setWidgetList] = useState([]);

  useEffect(() => {
    getWidgetList();
  }, []);

  const getWidgetList = async () => {
    let res = await getWidgetListAction();
    if (res.success) {
      setWidgetList(res.data);
    }
  };

  return (
    <>

      <div className="outer">
        <LoginHeader />

        <div className="outer__inner">
          <div className="section-mb0 home mb-5 pb-0">
            <Container>
              <Row>
                <Col xl={6} lg={6} md={6} xs={12}>
                  <div className="home__wrap marketText">
                    <h1 className="home__title h1">Today’s Cryptocurrency prices</h1>
                    <div className="home__text">
                      The global crypto market cap is <strong>$1.86T</strong>
                    </div>
                  </div>
                </Col>
                <Col xl={6} lg={6} md={6} xs={12}>
                  <div className="home__bg">
                    <img
                      src="assets/images/marketImg.png" className="marketImg"
                      alt="Cards"
                    />
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xl={12} lg={12} md={12} xs={12} className="pt-lg-5 pt-md-5 pt-3 mt-lg-5 mt-md-5 mt-0">
                  <div className="panel m-0">
                    <div className="panel__center p-0 pb-4 mx-4">
                      <div className="panel__wrapper">
                        <div className="panel__body">
                          <div className="panel__list">
                            {widgetList.slice(0, 3).map((item, index) => (
                              <a className="panel__item" href={`${config.baseUrl}exchangenew/${item.symbol}`}>
                                <div className="panel__icon">
                                  <img
                                    src={`img/coinImage/${item.symbol}.png`} style={{ width: "45px" }}
                                    alt="Bitcoin"
                                  />
                                </div>
                                <div className="panel__details">
                                  <div className="panel__line">
                                    <div className="panel__title" >{item.symbol}/USDT</div>
                                    <div translate="no" className="panel__positive">{parseFloat(item.percentage24).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}%</div>
                                  </div>
                                  <div translate="no" className="panel__price">${parseFloat(item.price).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
                                </div>
                                <div className="panel__chart">
                                  <div className="js-chart-positive" />
                                </div>
                              </a>
                            ))}
                          </div>
                        </div>
                        <div className="panel__foot d-none">
                          <div className="nav">
                            <button className="nav__link active">
                              Cryptocurrencies
                            </button>
                            <button className="nav__link">DeFi</button>
                            <button className="nav__link">Innovation</button>
                            <button className="nav__link">POS</button>
                            <button className="nav__link">NFT</button>
                            <button className="nav__link">POW</button>
                          </div>
                          <a
                            className="button-stroke button-small panel__button"
                            href="exchange.html"
                          >
                            Trade
                          </a>
                          <select className="select">
                            <option>Cryptocurrencies</option>
                            <option>DeFi</option>
                            <option>Innovation</option>
                            <option>POS</option>
                            <option>NFT</option>
                            <option>POW</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>

          </div>
        </div>


      </div>

      <div className="section market mb-5 pb-5">
        <Container>
          <Row>
            <Col xl={12} lg={12} md={12} xs={12}>
              <div className="market__center">
                <div
                  className="market__table-wrapper"
                  style={{ width: "100%", overflowX: "auto" }}
                >
                  <div className="market__table pt-3">
                    <div className="market__row">
                      <div className="market__col">
                        <div className="sorting">#</div>
                      </div>
                      <div className="market__col">
                        <div className="sorting">Name</div>
                      </div>
                      <div className="market__col">
                        <div className="sorting">Price</div>
                      </div>
                      <div className="market__col">24h %</div>
                      <div className="market__col">7d %</div>
                      <div className="market__col">
                        Marketcap
                        <svg className="icon icon-coin">
                          <use xlinkHref="#icon-coin" />
                        </svg>
                      </div>
                      <div className="market__col">
                        Volume (24h)
                        <svg className="icon icon-chart">
                          <use xlinkHref="#icon-chart" />
                        </svg>
                      </div>
                      <div className="market__col">
                        <div className="sorting">Action</div>
                      </div>

                    </div>
                    {widgetList.length > 0 ? widgetList.map((item, index) => (
                      <div className="market__row">
                        <div className="market__col">
                          <div className="market__line">

                            {index + 1}
                          </div>
                        </div>
                        <div className="market__col">
                          <div className="market__item">
                            <div className="market__icon">
                              <img
                                src={`img/coinImage/${item.symbol}.png`}
                                alt="Bitcoin"
                              />
                            </div>
                            <div className="market__details">
                              <span className="market__subtitle">
                                {item.name}
                              </span>
                              <span className="market__currency">
                                {item.symbol}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div translate="no" className="market__col">
                          <div className="market__label">Price</div>$
                          {parseFloat(item.price).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                        <div className="market__col">
                          <div className="market__label">24h</div>
                          <div translate="no" className="market__positive">
                            {parseFloat(item.percentage24).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}%
                          </div>
                        </div>
                        <div className="market__col">
                          <div className="market__label">7d</div>
                          <div className="market__negative" translate="no">
                            {parseFloat(item.percentage7Days).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}%
                          </div>
                        </div>
                        <div className="market__col">
                          <div translate="no" className="market__label">Marketcap</div>$
                          {parseFloat(item.marketCap).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                        </div>
                        <div className="market__col" translate="no">
                          <div className="market__label" >Volume (24h)</div>
                          {parseFloat(item.volumeChange24h) < 0 ? (
                            <div >-${`${Math.abs(parseFloat(item.volumeChange24h)).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</div>
                          ) : (
                            <div >${`${parseFloat(item.volumeChange24h).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}`}</div>
                          )}
                        </div>
                        <div className="market__col">
                          <div className="market__chart">
                            <div className="js-chart-positive" />
                          </div>
                          <a
                            className="button-small market__button"
                            href={`${config.baseUrl}exchangenew/${item.symbol}`}
                          >
                            Buy
                          </a>
                        </div>
                      </div>
                    )) : <div className="market__row">
                      <div className="market__col"></div>
                      <div className="market__col"></div>
                      <div className="market__col"></div>
                      <div className="market__col"></div>
                      <div className="market__col ">  <Spinner animation="border" role="status" variant="light"></Spinner></div>
                      <div className="market__col"></div>
                      <div className="market__col"></div>
                      <div className="market__col"></div>
                    </div>}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>

      <Footer />
    </>
  );
};
export default Market;
