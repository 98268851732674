import React, { useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { Container, Row, Col } from "react-bootstrap";
import "../components/componentCss/home.css";
import { getTermsConditionAction } from "../coreFiles/action";

const TermsCondition = () => {
  const [termsCondition, setTermsCondition] = useState({});

  useEffect(() => {
    getTermsCondition();
  }, []);

  const getTermsCondition = async () => {
    let res = await getTermsConditionAction();
    if (res.success) {
      setTermsCondition(res.data);
    }
  };

  return (
    <>
      <Header />

      <section className="banner pt-lg-5 pt-md-5 pt-3 pb-lg-5 pb-md-5 pb-3">
        <Container>
          <Row className="align-items-center">
            <Col lg={12} md={12} sm={12} xs={12}>
              <div className="bnrtText">
                <h1 className="mb-3 text-center">Terms & Conditions </h1>
                <h4 className="mb-4 text-white">{termsCondition?.title}</h4>
                <p>
                  <div
                    dangerouslySetInnerHTML={{ __html: termsCondition?.description }}
                  />
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <Footer />
    </>
  );
};
export default TermsCondition;
