import React, { useEffect, useState } from "react";
import config from "../coreFiles/config";
import Cookies from "js-cookie";
import {
  Container,
  Nav,
  Navbar,
  Button,
  Dropdown, Offcanvas, 
} from "react-bootstrap";

import "../components/componentCss/header.css";
import { getprofileDetail } from "../coreFiles/action";
import Translator from "../components/Translator";
import { Link, useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import socketIOClient from "socket.io-client";

const loginData = !Cookies.get("loginASIEX")
  ? {}
  : JSON.parse(Cookies.get("loginASIEX"));

const Header = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState({});
  const [socket, setSocket] = useState({ emit: "", on: "", connected: false });
  const [isScriptAdded,setisScriptAdded]=useState(false)

  useEffect(() => {
    if (loginData.id == undefined) {
      return;
    } else {
      getDetail();
    }

  }, []);

  const getDetail = async () => {
    let res = await getprofileDetail();
    if (res.success) {
      setProfile(res.data);
    }

  };

  const logoutUser = () => {
    Cookies.remove("loginASIEX");
    const cookieValue = Cookies.get("loginASIEX");
    setTimeout(() => {
      if (!cookieValue) {
        console.log('Cookie removed, navigating to another page.');
        // navigate(`${config.baseUrl}sign-in`); // Replace with your desired path
        window.location.href = `${config.baseUrl}sign-in`
      }
    }, 2000);



  };

  const scriptHandlet=(value)=>{
    setisScriptAdded(value)
  }

  useEffect(() => {
    if (!socket.connected) {
      const newSocket = socketIOClient(config.socketURL);
      newSocket.connected = true;
      setSocket(newSocket);

      newSocket.on("getBlockStatus", (data) => {
        if (data && data.success == false) {
          logoutUser()
          toast.error(data.msg)
        }
      });

      newSocket.on("disconnect", () => {
        newSocket.connected = false;
        setSocket(newSocket);
      });
    }
  }, [socket]);

  if (socket.connected && loginData.id) {
    setInterval(() => {
      socket.emit("checkBlockStatus", { userId: loginData.id })
    }, 5000)
  }

  const clickToggle=(e)=>{
    setisScriptAdded(false)
  }
  return (
    <>
      {loginData && loginData?.authToken ? (
        /*----------------------------header start for login user ------------------------------------------------------*/
        // <header className="">
        //   <Toaster />
        //   <Navbar expand="lg" className="">
        //     <Container fluid className="px-lg-4 px-md-4 px-3">
        //       <Navbar.Brand href="#home">
        //         <img
        //           src="assets/images/mainLogo2.svg"
        //           alt="mainLogo"
        //           className="mainLogo"
        //         />
        //       </Navbar.Brand>

        //       <Navbar.Toggle aria-controls="basic-navbar-nav" />

        //       <Navbar.Collapse id="basic-navbar-nav">
        //         <Nav className="me-auto">
        //           <div className="linebox d-lg-block d-none"></div>
        //           <Nav.Link href={`${config.baseUrl}`}>Home</Nav.Link>
        //           <Nav.Link href={`${config.baseUrl}Market`}>Market</Nav.Link>
        //           <Nav.Link href={`${config.baseUrl}Exchangenew`}>
        //             Trade
        //           </Nav.Link>
        //           <Nav.Link href={`${config.baseUrl}Wallet`}>Assets</Nav.Link>
        //         </Nav>

        //         <Translator />


        //         <div className="d-flex mt-lg-0 mt-4">
        //           <div className="header__item header__item_notifications js-header-item">
        //             <div className="header__body js-header-body">
        //               <div className="header__title">Notifications</div>
        //               <div className="header__list">
        //                 <a
        //                   className="header__notification header__notification_new"
        //                   href="notifications.html"
        //                 >
        //                   <div className="header__subtitle">
        //                     Login attempted from new IP
        //                   </div>
        //                   <div className="header__date">
        //                     2021-03-10 20:19:15
        //                   </div>
        //                 </a>
        //                 <a
        //                   className="header__notification header__notification_new"
        //                   href="notifications.html"
        //                 >
        //                   <div className="header__subtitle">
        //                     Request to reset security
        //                   </div>
        //                   <div className="header__date">
        //                     2021-03-10 20:19:15
        //                   </div>
        //                 </a>
        //                 <a
        //                   className="header__notification header__notification_new"
        //                   href="notifications.html"
        //                 >
        //                   <div className="header__subtitle">
        //                     Login attempted from new IP
        //                   </div>
        //                   <div className="header__date">
        //                     2021-03-10 20:19:15
        //                   </div>
        //                 </a>
        //                 <a
        //                   className="header__notification header__notification_new"
        //                   href="notifications.html"
        //                 >
        //                   <div className="header__subtitle">
        //                     Request to reset security
        //                   </div>
        //                   <div className="header__date">
        //                     2021-03-10 20:19:15
        //                   </div>
        //                 </a>
        //                 <a
        //                   className="header__notification"
        //                   href="notifications.html"
        //                 >
        //                   <div className="header__subtitle">
        //                     Login attempted from new IP
        //                   </div>
        //                   <div className="header__date">
        //                     2021-03-10 20:19:15
        //                   </div>
        //                 </a>
        //                 <a
        //                   className="header__notification"
        //                   href="notifications.html"
        //                 >
        //                   <div className="header__subtitle">
        //                     Request to reset security
        //                   </div>
        //                   <div className="header__date">
        //                     2021-03-10 20:19:15
        //                   </div>
        //                 </a>
        //               </div>
        //               <div className="header__btns">
        //                 <Link
        //                   className="button-small header__button"
        //                   to={`${config.baseUrl}Notification`}
        //                 >
        //                   View all
        //                 </Link>
        //                 <button className="button-stroke button-small header__button">
        //                   Clear all
        //                 </button>
        //               </div>
        //             </div>
        //           </div>
        //           <div className=" d-flex justify-content-end">
        //             <Link
        //               className="button-stroke button-small  header__button me-4 my-lg-0 my-lg-4 my-md-4 my-0"
        //               to={`${config.baseUrl}Wallet`}
        //             >
        //               Wallet
        //             </Link>

        //             <div className="d-flex align-items-center">


        //               <div className="headdropdown profileDrop ">
        //                 <Dropdown className="flagdrop">
        //                   <Dropdown.Toggle
        //                     variant=""
        //                     id="dropdown-basic"
        //                     className="text-white ps-0"
        //                   >
        //                     <img
        //                       style={{ width: "40px", height: "40px" }}
        //                       src={config.imageUrl + profile.profile_image}
        //                       className="headprofile"
        //                     />
        //                   </Dropdown.Toggle>

        //                   <Dropdown.Menu>
        //                     <Dropdown.Item href={`${config.baseUrl}profile`}>
        //                       Profile
        //                     </Dropdown.Item>
        //                     <Dropdown.Item onClick={(e) => logoutUser(e)}>
        //                       Logout
        //                     </Dropdown.Item>

        //                   </Dropdown.Menu>
        //                 </Dropdown>
        //               </div>
        //             </div>
        //           </div>
        //         </div>


        //       </Navbar.Collapse>
        //     </Container>
        //   </Navbar>
        // </header>


        <header>
          <Toaster />
          {['lg'].map((expand) => (
            <Navbar key={expand} expand={expand} className="">
              <Container fluid className="px-lg-5 px-md-5 px-3">
                <Navbar.Brand href="#home">
                  <img
                    src="assets/images/mainLogo2.svg"
                    alt="mainLogo"
                    className="mainLogo"
                  />
                </Navbar.Brand>
                <Navbar.Toggle onClick={e=>clickToggle(e)} aria-controls={`offcanvasNavbar-expand-${expand}`} />
                <Navbar.Offcanvas className="w-100" style={{ backgroundColor: "#17181b" }}
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton onClick={e=>clickToggle(e)}>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                      <img
                        src="assets/images/mainLogo2.svg"
                        alt="mainLogo"
                        className="mainLogo"
                      />
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3 mobLink">
                      <Nav.Link href={`${config.baseUrl}`}>Home</Nav.Link>
                      <Nav.Link href={`${config.baseUrl}Market`}>Market</Nav.Link>
                      <Nav.Link href={`${config.baseUrl}Exchangenew`}>
                        Trade
                      </Nav.Link>
                      <Nav.Link href={`${config.baseUrl}Wallet`}>Assets</Nav.Link>
                    </Nav>
                    <div className="d-flex align-items-xl-center align-items-lg-center align-items-md-center align-items-start flex-xl-row flex-lg-row flex-md-column flex-column justify-content-start my-lg-0 my-md-0 my-4">
                      <Translator isScriptAdded={isScriptAdded}  scriptHandlet={e=>scriptHandlet(e)}/>
                      <div className="d-flex mt-lg-0 mt-4">
                        <div className="header__item header__item_notifications js-header-item">
                          <div className="header__body js-header-body">
                            <div className="header__title">Notifications</div>
                            <div className="header__list">
                              <a
                                className="header__notification header__notification_new"
                                href="notifications.html"
                              >
                                <div className="header__subtitle">
                                  Login attempted from new IP
                                </div>
                                <div className="header__date">
                                  2021-03-10 20:19:15
                                </div>
                              </a>
                              <a
                                className="header__notification header__notification_new"
                                href="notifications.html"
                              >
                                <div className="header__subtitle">
                                  Request to reset security
                                </div>
                                <div className="header__date">
                                  2021-03-10 20:19:15
                                </div>
                              </a>
                              <a
                                className="header__notification header__notification_new"
                                href="notifications.html"
                              >
                                <div className="header__subtitle">
                                  Login attempted from new IP
                                </div>
                                <div className="header__date">
                                  2021-03-10 20:19:15
                                </div>
                              </a>
                              <a
                                className="header__notification header__notification_new"
                                href="notifications.html"
                              >
                                <div className="header__subtitle">
                                  Request to reset security
                                </div>
                                <div className="header__date">
                                  2021-03-10 20:19:15
                                </div>
                              </a>
                              <a
                                className="header__notification"
                                href="notifications.html"
                              >
                                <div className="header__subtitle">
                                  Login attempted from new IP
                                </div>
                                <div className="header__date">
                                  2021-03-10 20:19:15
                                </div>
                              </a>
                              <a
                                className="header__notification"
                                href="notifications.html"
                              >
                                <div className="header__subtitle">
                                  Request to reset security
                                </div>
                                <div className="header__date">
                                  2021-03-10 20:19:15
                                </div>
                              </a>
                            </div>
                            <div className="header__btns">
                              <Link
                                className="button-small header__button"
                                to={`${config.baseUrl}Notification`}
                              >
                                View all
                              </Link>
                              <button className="button-stroke button-small header__button">
                                Clear all
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className=" d-flex justify-content-end">
                          <Link
                            className="button-stroke button-small  header__button me-4 my-lg-0 my-lg-4 my-md-4 my-0"
                            to={`${config.baseUrl}Wallet`}
                          >
                            Wallet
                          </Link>

                          <div className="d-flex align-items-center">


                            <div className="headdropdown profileDrop ">
                              <Dropdown className="flagdrop">
                                <Dropdown.Toggle
                                  variant=""
                                  id="dropdown-basic"
                                  className="text-white ps-0"
                                >
                                  <img
                                    style={{ width: "40px", height: "40px" }}
                                    src={config.imageUrl + profile.profile_image}
                                    className="headprofile"
                                  />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item href={`${config.baseUrl}profile`}>
                                    Profile
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={(e) => logoutUser(e)}>
                                    Logout
                                  </Dropdown.Item>

                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </header>






      ) : (
        /*----------------------------header end for login user ------------------------------------------------------*/
        /*-------------------------------header start for not login user ----------------------------------------------*/
        // <header className="">
        //   <Toaster />
        //   <Navbar expand="lg" className="">
        //     <Container fluid className="px-lg-5 px-md-5 px-3">
        //       <Navbar.Brand href="#home">
        //         <img
        //           src="assets/images/mainLogo2.svg"
        //           alt="mainLogo2"
        //           className="mainLogo"
        //         />
        //       </Navbar.Brand>
        //       <Navbar.Toggle aria-controls="basic-navbar-nav" />
        //       <Navbar.Collapse id="basic-navbar-nav">
        //         <div className="linebox"></div>
        //         <Nav className="me-auto">
        //           <Nav.Link href={`${config.baseUrl}`}>Home</Nav.Link>
        //           <Nav.Link href={`${config.baseUrl}Market`}>Market</Nav.Link>
        //           <Nav.Link href={`${config.baseUrl}Exchangenew`}>
        //             Trade
        //           </Nav.Link>
        //           <Nav.Link href={`${config.baseUrl}Wallet`}>Assets</Nav.Link>
        //         </Nav>
        //         <Translator />

        //         <div className="headbtnbox d-flex mt-lg-0 mt-md-4 mt-4">
        //           <Link to={`${config.baseUrl}sign-up`}>
        //             {" "}
        //             <Button variant="custombtn1" className="px-4 me-3">
        //               {" "}
        //               Sign up
        //             </Button>
        //           </Link>

        //           <Link to={`${config.baseUrl}sign-in`}>
        //             {" "}
        //             <Button variant="custombtn2" className="px-4">
        //               Login
        //             </Button>
        //           </Link>
        //         </div>
        //       </Navbar.Collapse>
        //     </Container>
        //   </Navbar>
        // </header>

        <header>
          <Toaster />
          {['lg'].map((expand) => (
            <Navbar key={expand} expand={expand} className="">
              <Container fluid className="px-lg-5 px-md-5 px-3">
                <Navbar.Brand href="#home">
                  <img
                    src="assets/images/mainLogo2.svg"
                    alt="mainLogo"
                    className="mainLogo"
                  />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={e=>clickToggle(e)} />
                <Navbar.Offcanvas className="w-100" style={{ backgroundColor: "#17181b" }}
                  id={`offcanvasNavbar-expand-${expand}`}
                  aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
                  placement="end"
                >
                  <Offcanvas.Header closeButton onClick={e=>clickToggle(e)}>
                    <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                      <img
                        src="assets/images/mainLogo2.svg"
                        alt="mainLogo"
                        className="mainLogo"
                      />
                    </Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <Nav className="justify-content-end flex-grow-1 pe-3 mobLink">
                      <Nav.Link href={`${config.baseUrl}`}>Home</Nav.Link>
                      <Nav.Link href={`${config.baseUrl}Market`}>Market</Nav.Link>
                      <Nav.Link href={`${config.baseUrl}Exchangenew`}>
                        Trade
                      </Nav.Link>
                      <Nav.Link href={`${config.baseUrl}Wallet`}>Assets</Nav.Link>
                    </Nav>
                    <div className="d-flex align-items-xl-center align-items-lg-center align-items-md-center align-items-start flex-xl-row flex-lg-row flex-md-column flex-column justify-content-start my-lg-0 my-md-0 my-4">
                      <Translator isScriptAdded={isScriptAdded}  scriptHandlet={e=>scriptHandlet(e)} />
                      <div className="headbtnbox d-flex mt-lg-0 mt-md-4 mt-4">
                        <Link to={`${config.baseUrl}sign-up`}>
                          {" "}
                          <Button variant="custombtn1" className="px-4 me-3">
                            {" "}
                            Sign up
                          </Button>
                        </Link>

                        <Link to={`${config.baseUrl}sign-in`}>
                          {" "}
                          <Button variant="custombtn2" className="px-4">
                            Login
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </Offcanvas.Body>
                </Navbar.Offcanvas>
              </Container>
            </Navbar>
          ))}
        </header>


      )}






    </>
  );
};

export default Header;
