import React, { useEffect, useState } from "react";
import {
  Col,
 
} from "react-bootstrap";
import Cookies from "js-cookie";
import toast, { Toaster } from "react-hot-toast";
import "../components/componentCss/profile.css";
import config from "../coreFiles/config";
import { changepassword } from "../coreFiles/action";

import { Link } from "react-router-dom";

import "react-image-upload/dist/index.css";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";

const ChangePassword = () => {
  const [userData, setuserData] = useState({
    old_password: "",
    password: "",
    confirm_password: "",
  });
  const [error, seterror] = useState([]);
  const [phideshow, setphideshow] = useState(false);
  const [ophideshow, setophideshow] = useState(false);
  const [cphideshow, setcphideshow] = useState(false);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setuserData((old) => {
      return { ...old, [name]: value };
    });
  };

  const validate = () => {
    let errors = [];

    if (userData.old_password == "") {
      errors.push({ name: "old_password", err: "Old Password is Required!" });
    }

    if (userData.password == "") {
      errors.push({ name: "password", err: "Password is Required!" });
    }

    if (userData.confirm_password == "") {
      errors.push({
        name: "confirm_password",
        err: "Confirm Password is Required!",
      });
    }



    if (userData.password.length < 6) {
      errors.push({
        name: "password",
        err: "Your password must be at least 6 characters",
      });
    }
  

    if (userData.confirm_password != userData.password) {
      errors.push({
        name: "confirm_password",
        err: "Password and Confirm Password not match!",
      });
    }

    if (errors.length > 0) {
      seterror(errors);

      return false;
    }
    seterror(errors);
    return true;
  };

  const SubmitForm = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
    } else {
      let res = await changepassword(userData);
      if (res.success) {
        setuserData({ old_password: "", password: "", confirm_password: "" });
        toast.success(res.msg);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(res.msg);
      }
    }
  };


  return (
    <>
      <Toaster />
      <Col xl={8} lg={8} md={12} xs={12} className="pt-5">
        <div className="login__wrap">
          <div className="entry">
            <div className="entry__top border-0 p-0">
              <h3 className="entry__title h3 text-white">Change password</h3>
            </div>
            <div className="entry__form">
              <div className="entry__tabs js-tabs">
                <div className="entry__container">
                  <div
                    className="entry__item js-tabs-item"
                    style={{ display: "block" }}
                  >
                    <div className="field field_view">
                      <div className="field__label">old password</div>
                      <div className="field__wrap">
                        <input
                          className="field__input"
                          type={ophideshow == false ? "password" : "text"}
                          onChange={(e) => changeHandler(e)}
                          name="old_password"
                          placeholder="Old password"
                          required=""
                        />
                        <button
                          className="field__view"
                          onClick={(e) => setophideshow(!ophideshow)}
                        >
                          {ophideshow == false ? (
                            <FaRegEye className="eyeIcon" />
                          ) : (
                            <FaRegEyeSlash className="eyeIcon" />
                          )}
                        </button>
                      </div>
                    </div>
                    {error.length > 0 && error[0].name == "old_password" ? (
                      <div>
                        <span className="alert_validation">{error[0].err}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="field field_view mb-4">
                  <div className="field__label">New password</div>
                  <div className="field__wrap">
                    <input
                      className="field__input"
                      type={phideshow == false ? "password" : "text"}
                      onChange={(e) => changeHandler(e)}
                      name="password"
                      placeholder="Password"
                      required=""
                    />
                    <button
                      className="field__view"
                      onClick={(e) => setphideshow(!phideshow)}
                    >
                      {phideshow == false ? (
                        <FaRegEye className="eyeIcon" />
                      ) : (
                        <FaRegEyeSlash className="eyeIcon" />
                      )}
                    </button>
                  </div>
                  {error.length > 0 && error[0].name == "password" ? (
                    <div>
                      <span className="alert_validation">{error[0].err}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <div className="field field_view">
                  <div className="field__label">Confirm password</div>
                  <div className="field__wrap">
                    <input
                      className="field__input"
                      type={cphideshow == false ? "password" : "text"}
                      onChange={(e) => changeHandler(e)}
                      name="confirm_password"
                      placeholder="Password"
                      required=""
                    />
                    <button
                      className="field__view"
                      onClick={(e) => setcphideshow(!cphideshow)}
                    >
                      {cphideshow == false ? (
                        <FaRegEye className="eyeIcon" />
                      ) : (
                        <FaRegEyeSlash className="eyeIcon" />
                      )}
                    </button>
                  </div>
                  {error.length > 0 && error[0].name == "confirm_password" ? (
                    <div>
                      <span className="alert_validation">{error[0].err}</span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                <a
                  className="button entry__button"
                  onClick={(e) => SubmitForm(e)}
                >
                  Change
                </a>
              </div>
            </div>
            <div className="entry__wrap">
              <div className="entry__box">
                <div className="entry__details">
                  <div className="entry__code">
                    <img src="img/content/qr-code.jpg" alt="Qr code" />
                  </div>
                  <button className="button-stroke entry__button">
                    Download app
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Col>
    </>
  );
};

export default ChangePassword;
