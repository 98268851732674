import React, { Component, useEffect, useState } from "react";
import { Link, NavLink } from "react-router-dom";
import Header from "../directives/header";
import Footer from "../directives/footer";
import config from "../coreFiles/config";
import { MdOutlineMail } from "react-icons/md";
import { forgotPasswordAction, getDashboardStatisticsUserAction } from "../coreFiles/action";
import toast, { Toaster } from "react-hot-toast";
import Swal from 'sweetalert2';
import { IoArrowBackOutline } from "react-icons/io5";

const Forgot = () => {
  const [formNews, setFormNews] = useState({
    email: "",
  });
  const [error, seterror] = useState([]);
  const [settingCount, setSettingCount] = useState({});
  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormNews((old) => {
      return { ...old, [name]: value };
    });
  };

  useEffect(() => {
    getDashboardStatisticsUser()
  }, [])

  const getDashboardStatisticsUser = async () => {
    let res = await getDashboardStatisticsUserAction();
    if (res.success) {
      setSettingCount(res.data);
    }
    if (res.data.forgotPassword == 0) {
      await Swal.fire({
        title: '<h1 style="font-size: 20px;">Forgot Password  functionality has been temporarily disabled by the admin; please be patient.</h1>',
        icon: 'info',
        width: 500,
        confirmButtonColor: '#3772FF',
        allowOutsideClick: false,
        confirmButtonText: 'Continue',
      })
    }

  };

  const validate = () => {
    let errors = [];

    if (formNews.email == "") {
      errors.push({ name: "email", err: "Email is Required!" });
    }

    if (formNews.email.search(/\S+@\S+\.\S+/) < 0) {
      errors.push({ name: "email", err: "Enter Valid E-mail!" });
    }
    if (errors.length > 0) {
      seterror(errors);

      return false;
    }
    seterror(errors);
    return true;
  };

  const forgetPasswordSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
    } else {
      let res = await forgotPasswordAction(formNews);
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(res.msg);
      }
    }
  };

  return (
    <>
      <div className="outer">
        {/* <Header /> */}
        <Toaster />

        <div className="outer__inner">
          <div className="login">
            <div
              className="login__col"
              style={{ backgroundImage: 'url("assets/images/loginBack.png")' }}
            >
              {/* <Link className="login__logo" to={`${config.baseUrl}`}>
                                <img src="img/logo-light.svg" alt="BitCloud" />
                                <img src="img/logo-dark.svg" alt="BitCloud" /></Link> */}
            </div>
            <div className="login__col">
              <div className="backdiv d-lg-none d-md-none d-block">
                <Link to={`${config.baseUrl}`}><span><IoArrowBackOutline className="backbtnIcon" /></span></Link>
              </div>
              <div className="respologo">
                <img src="assets/images/mainLogo2.svg" className="" />
              </div>
              <div className="login__head">
                Don’t have an account?
                <Link className="login__link" to={`${config.baseUrl}sign-up`}>
                  Register for free
                </Link>
              </div>
              <div className="login__wrap">
                <div className="login__form">
                  <div className="login__top">
                    <h3 className="login__title h3 text-white">
                      Forgot password
                    </h3>
                    <div className="login__info">
                      For security purposes, no withdrawals are permitted for 24
                      hours after password changed.
                    </div>
                  </div>
                  <div className="field field_icon">
                    <div className="field__label">Enter Your Email</div>
                    <div className="field__wrap">
                      <input
                        className="field__input"
                        type="email"
                        value={formNews.email}
                        onChange={(e) => changeHandler(e)}
                        name="email"
                        placeholder="Your email"
                        required=""
                      />
                      <div className="field__icon">
                        <MdOutlineMail className="mailIcon" />
                      </div>
                    </div>

                    {error.length > 0 && error[0].name == "email" ? (
                      <div>
                        <span className="alert_validation">{error[0].err}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>


                  

                  <button
                    className="button login__button"
                    disabled={settingCount.forgotPassword == 0}
                    onClick={(e) => forgetPasswordSubmit(e)}
                  >
                    Continue
                  </button>
                  <div className="login__foot">
                    <a href={`${config.baseUrl}sign-in`} className="login__link">
                      {" "}
                      Nevermind, I got it
                    </a>
                  </div>
                </div>
                <div className="login__verification">
                  <h3 className="login__title h3">Forgot password</h3>
                  <div className="login__note">
                    To secure your account, please complete the following
                    verification.
                  </div>
                  <div className="login__text">
                    Enter the 6 digit code received by <span>t*m@u*8.net</span>
                  </div>
                  <div className="login__numbers">
                    <div className="registration__number">
                      <input type="tel" />
                    </div>
                    <div className="registration__number">
                      <input type="tel" />
                    </div>
                    <div className="registration__number">
                      <input type="tel" />
                    </div>
                    <div className="registration__number">
                      <input type="tel" />
                    </div>
                  </div>
                  <div className="login__btns">
                    <button className="button-stroke button-small login__button">
                      Resend code
                    </button>
                    <button className="button button-small login__button" disabled={settingCount.forgotPassword == 0}>
                      Continue
                    </button>
                  </div>
                </div>
                <div className="login__new">
                  <div className="login__title h3">New password</div>
                  <div className="field">
                    <div className="field__label">Email</div>
                    <div className="field__wrap">
                      <input
                        className="field__input"
                        type="email"
                        name="email"
                        placeholder="Email"
                        required=""
                      />
                    </div>
                  </div>
                  <div className="field field_view">
                    <div className="field__label">new password</div>
                    <div className="field__wrap">
                      <input
                        className="field__input"
                        type="password"
                        name="new-password"
                        placeholder="New password"
                        required=""
                      />
                      <button className="field__view">
                        <svg className="icon icon-eye">
                          <use xlinkHref="#icon-eye" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div className="field field_view">
                    <div className="field__label">confirm password</div>
                    <div className="field__wrap">
                      <input
                        className="field__input"
                        type="password"
                        name="confirm-password"
                        placeholder="Confirm password"
                        required=""
                      />
                      <button className="field__view">
                        <svg className="icon icon-eye">
                          <use xlinkHref="#icon-eye" />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <button disabled={settingCount.forgotPassword == 0} className="button login__button">Continue</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    </>
  );
};
export default Forgot;
