import React, { Component, useEffect, useState } from "react";
import { Link, NavLink } from 'react-router-dom'
import Cookies from 'js-cookie';
import config from "../coreFiles/config";
import toast, { Toaster } from "react-hot-toast";
import { login, getDashboardStatisticsUserAction } from '../coreFiles/action'
import { RiLock2Fill } from "react-icons/ri";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import Swal from 'sweetalert2';
import { IoArrowBackOutline } from "react-icons/io5";



const SignIn = () => {

    const [userData, setuserData] = useState({ phone: '', password: '' })
    const [phideshow, setphideshow] = useState(false)
    const [error, seterror] = useState([])
    const [settingCount, setSettingCount] = useState({});

    const changeHandler = (e) => {
        const { name, value } = e.target
        setuserData((old) => {
            return { ...old, [name]: value }
        })
    }

    useEffect(() => {
        getDashboardStatisticsUser();
    }, [])

    const getDashboardStatisticsUser = async () => {
        let res = await getDashboardStatisticsUserAction();
        if (res.success) {
            setSettingCount(res.data);
        }
        if (res.data.entireWebsite == 0) {
            await Swal.fire({
                title: '<h1 style="font-size: 20px;">Entire Website functionality has been temporarily disabled by the admin; please be patient.</h1>',
                icon: 'info',
                width: 500,
                confirmButtonColor: '#3772FF',
                allowOutsideClick: false,
                confirmButtonText: 'Continue',
            })
        }

    };


    const validate = () => {

        let errors = [];

        if (userData.phone == '') {
            errors.push({ name: 'phone', err: "Phone number or email is Required!" });
        }

        if (userData.password == '') {
            errors.push({ name: "password", err: "Password is Required!" });
        }

        if (errors.length > 0) {

            seterror(errors)

            return false;
        }
        seterror(errors)
        return true;
    }

    const SubmitForm = async (e) => {
        e.preventDefault();
        const isValid = validate();
        if (!isValid) {
        } else {
            let res = await login(userData);
            if (res.success) {

                if (res.data['2fa_enabled'] == 1) {
                    Cookies.set("loginASIEX2FA", JSON.stringify(res.data));
                    setTimeout(() => {
                        window.location.href = `${config.baseUrl}login2fa`;
                    }, 200)
                } else {

                    Cookies.set("loginASIEX", JSON.stringify(res.data));

                    if (res.data.kycupload == false) {

                        await Swal.fire({
                            title: 'Please Update Your Personal Info!',
                            icon: 'info',
                            width: 500,
                            confirmButtonColor: '#3772FF',
                            allowOutsideClick: false,
                            confirmButtonText: 'Continue',
                        });

                        setTimeout(() => {
                            window.location.href = `${config.baseUrl}editprofile`;
                        }, 200);

                    } else {
                        toast.success(res.msg);
                        setTimeout(() => {
                            window.location.href = `${config.baseUrl}Wallet`;
                        }, 200);
                    }

                }

            } else {
                toast.error(res.msg);
            }
        }
    }

    const loginDirect = () => {
        window.location.href = `${config.baseUrl}`
    }


    return (
        <>
            <div className="outer">
                {/* <Header /> */}
                <Toaster />
                <div className="outer__inner">

                    <div className="login">

                        <div onClick={loginDirect}
                            className="login__col"
                            style={{ backgroundImage: 'url("assets/images/loginBack.png")', cursor: "pointer" }}
                        >
                        
                        </div>

                        <div className="login__col " style={{ paddingTop: "90px" }}>
                            <div className="backdiv d-lg-none d-md-none d-block">
                                <Link to={`${config.baseUrl}`}><span><IoArrowBackOutline className="backbtnIcon" /></span></Link>
                            </div>
                            <div className="respologo">
                                <img src="assets/images/mainLogo2.svg" className="" />
                            </div>

                            <div className="login__head">
                                Don’t have an account?
                                <Link className="login__link" to={`${config.baseUrl}sign-up`}>
                                    Register for free
                                </Link>
                            </div>
                            <div className="login__wrap">
                                <div className="entry">
                                    <div className="entry__top">
                                        <h3 className="entry__title h3 text-white">Log in to Spacebit</h3>
                                        <div className="entry__info">
                                            Please ensure you are visiting the correct url.
                                        </div>
                                        <div className="entry__correct">
                                            <svg className="icon icon-lock d-none">
                                                <use xlinkHref="#icon-lock" />
                                            </svg>
                                            <div className="entry__url">
                                                <span><RiLock2Fill className='lockicon me-2' /></span>
                                                https://{window.location.hostname}<span>{config.baseUrl}sign-in</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="entry__form">

                                        <div className="entry__tabs js-tabs">
                                            <form onSubmit={e => SubmitForm(e)}>
                                          
                                                <div className="entry__container">
                                                    <div
                                                        className="entry__item js-tabs-item"
                                                        style={{ display: "block" }}
                                                    >
                                                        <div className="field field_view ">
                                                            <div className="field__label">Phone Number / E-mail</div>
                                                            <div className="field__wrap">
                                                                <input
                                                                    className="field__input mb-2"
                                                                    type="text"
                                                                    onChange={e => changeHandler(e)}
                                                                    value={userData.phone}
                                                                    name="phone"
                                                                    placeholder="Phone Number  or E-mail"
                                                                    required=""
                                                                />
                                                                <button className="field__view">
                                                                    {/* <MdOutlinphone className="mailIcon" /> */}
                                                                </button>
                                                            </div>
                                                        </div>
                                                        {error.length > 0 && error[0].name == 'phone' ? <div className="mb-3"><span className='alert_validation'>{error[0].err}</span></div> : ''}
                                                    </div>

                                                    <div className="field field_view mb-3">
                                                        <div className="field__label">password</div>
                                                        <div className="field__wrap mb-3">
                                                            <input
                                                                className="field__input"
                                                                type={phideshow == false ? "password" : "text"}
                                                                onChange={e => changeHandler(e)}
                                                                value={userData.password}
                                                                name="password"
                                                                placeholder="Password"
                                                                required=""
                                                            />
                                                            <span className="field__view eye_color" onClick={e => setphideshow(!phideshow)}>
                                                                {phideshow == false ?
                                                                    <FaRegEye className="eyeIcon" />
                                                                    : <FaRegEyeSlash className="eyeIcon" />}
                                                            </span>
                                                        </div>
                                                        {error.length > 0 && error[0].name == 'password' ? <div><span className='alert_validation'>{error[0].err}</span></div> : ''}

                                                    </div>

                                                    <div className="entry__foot d-flex justify-content-end">

                                                        <Link className="entry__link" to={`${config.baseUrl}Forgot`}>
                                                            Forgot password?
                                                        </Link>

                                                    </div>
                                                    <button className="button entry__button" type="submit" disabled={settingCount.entireWebsite == 0}>
                                                        Login
                                                    </button>
                                                </div>
                                            </form>
                                        </div>

                                    </div>

                                    <div className="entry__wrap">
                                        <div className="entry__box">
                                            <div className="entry__details">
                                                <div className="entry__code">
                                                    <img src="img/content/qr-code.jpg" alt="Qr code" />
                                                </div>
                                                <button className="button-stroke entry__button">
                                                    Download app
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* <Footer /> */}
            </div>
        </>
    );
};
export default SignIn;
