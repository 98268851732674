import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import { FaLongArrowAltRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import config from "../coreFiles/config";
import { IoChevronDownOutline } from "react-icons/io5";
import { addSubscriberAction } from "../coreFiles/action";
import toast, { Toaster } from "react-hot-toast";

const Footer = () => {
  const [formNews, setFormNews] = useState({
    email: "",
  });
  const [error, seterror] = useState([]);

  const changeHandler = (e) => {
    const { name, value } = e.target;
    setFormNews((old) => {
      return { ...old, [name]: value };
    });
  };

  const validate = () => {
    let errors = [];

    if (formNews.email == "") {
      errors.push({ name: "email", err: "Email is Required!" });
    }

    if (formNews.email.search(/\S+@\S+\.\S+/) < 0) {
      errors.push({ name: "email", err: "Enter Valid E-mail!" });
    }
    if (errors.length > 0) {
      seterror(errors);

      return false;
    }
    seterror(errors);
    return true;
  };

  const newsLetterSubmit = async (e) => {
    e.preventDefault();
    const isValid = validate();
    if (!isValid) {
    } else {
      let res = await addSubscriberAction(formNews);
      if (res.success) {
        toast.success(res.msg);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        toast.error(res.msg);
      }
    }
  };

  return (
    <>
      <footer className="py-5">
        <Toaster />
        <Container>
          <Row>
            <Col lg={4} md={6} xs={12}>
              <div className="f-logoblock ">
                <div>
                  <img
                    src="assets/images/mainLogo2.svg"
                    alt="mainLogo2"
                    className="mainLogo me-3 mb-lg-0 mb-md-0 mb-4"
                  />
                </div>

                <div>
                  <ul className="">
                    <li>
                      <Link to={`${config.baseUrl}about-us`}> About </Link>
                    </li>
                    <li>
                      <Link to={`${config.baseUrl}privacy-policy`}>
                        Privacy Policy
                      </Link>
                    </li>
                    <li>
                      <Link to={`${config.baseUrl}terms-condition`}>
                        Terms & Conditions
                      </Link>
                    </li>
                    <li>
                      <Link to={`${config.baseUrl}announcement`}>
                        Announcement
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} md={6} xs={12}>
              <div className="f-logoblock borderbox">
                <h4>Contact</h4>
                <div>
                  <ul>
                    <li>
                      <p>43252 Borer Mountains</p>
                    </li>
                    <li>
                      <p>Zackerychester</p>
                    </li>
                    <li>
                      <p>Bahamas</p>
                    </li>
                    <li>
                      <p>732-528-4945</p>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>

            <Col lg={4} md={6} xs={12}>
              <div className="f-newslatter">
                <h4>Newsletter</h4>
                <div>
                  <h5>
                    Subscribe our newsletter to get more free design course and
                    resource
                  </h5>
                  <form class="subscription">
                    <input
                      class="subscription__input"
                      value={formNews.email}
                      type="email"
                      name="email"
                      placeholder="Enter your email"
                      required="required"
                      onChange={(e) => changeHandler(e)}
                    />
                    <button
                      class="subscription__btn"
                      onClick={(e) => newsLetterSubmit(e)}
                    >
                      {/* <svg class="icon icon-arrow-next">
                                                <use xlink:href="#icon-arrow-next"></use>
                                            </svg> */}
                      <FaLongArrowAltRight className="subsIcon" />
                    </button>
                    {error.length > 0 && error[0].name == "email" ? (
                      <div>
                        <span className="alert_validation">{error[0].err}</span>
                      </div>
                    ) : (
                      ""
                    )}
                  </form>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
};
export default Footer;
