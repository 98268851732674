import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Tabs, Tab, Form, NavDropdown, Dropdown } from 'react-bootstrap';

import toast, { Toaster } from "react-hot-toast";
import '../components/componentCss/profile.css'
import config from "../coreFiles/config";
import { coinListAPI, depositRequest } from '../coreFiles/action'

import { Link } from 'react-router-dom';

import 'react-image-upload/dist/index.css'
import LoginHeader from "../directives/header";
import '../components/componentCss/rewardhistory.css'
import { IoChevronBackOutline } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { BsCalendarRange } from "react-icons/bs";
import { HiMiniChevronUpDown } from "react-icons/hi2";
import Footer from '../directives/footer';



const Rewardhistory = () => {

    const [coins, setCoins] = useState([])
    const [depositForm, setdepositForm] = useState({ amount: 0, currencyId: 1, receipt: '' })


    useEffect(() => {
        getCoins()
    }, [])

    const getCoins = async () => {
        let res = await coinListAPI()
        if (res.success) {
            setCoins(res.data)
        }
    }


    return (
        <>
            <LoginHeader />


            <Toaster />


            <div>
                <Container className=''>
                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="">
                            <div className='backArrowhead mt-lg-5 mt-md-5 mt-3'>
                                <span className='me-3'><Link to={`${config.baseUrl}`}> <span><IoChevronBackOutline /></span></Link></span><h1 className='mb-0'>Rewards History</h1>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>



            <section className='rewards_sec pt-5 pb-5 mt-5'>
                <Container className=''>

                    <Row className='boderLine'>
                        <Col xl={3} lg={3} md={6} xs={12} className="d-flex align-items-center">
                            <div className="searchbox ">
                                <input
                                    type="search"
                                    className="form-control"
                                    placeholder="Search"
                                />
                                <span>
                                    <CiSearch className="searchIcon" />
                                </span>
                            </div>
                        </Col>

                        <Col xl={9} lg={9} md={6} xs={12} className="">
                            <div className='calanderdrop ms-lg-2 ms-md-2 ms-0'>
                                <Dropdown className=''>
                                    <Dropdown.Toggle variant="" id="dropdown-basic" className=' '>
                                        All time <span><BsCalendarRange className='calenderIcon' /></span>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="">
                            <div className='tatalReward mt-5'>
                                <h6>Total rewards</h6>
                                <h2 className='mb-3'>1,056.00<span className='ms-1 maincolor2'>USDT</span></h2>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="pt-3">
                            <div className=" rewardshistoyTable">
                                <div className=" table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Time <span><HiMiniChevronUpDown className="dropIcon" /></span></th>
                                                <th>Amount <span><HiMiniChevronUpDown className="dropIcon" /></span></th>
                                                <th>Account Name <span><HiMiniChevronUpDown className="dropIcon" /></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="active">
                                                <td>2021-06-05 04:12:30 </td>
                                                <td>0.789 USDT </td>
                                                <td>muoidoanvan1009 </td>
                                            </tr>
                                            <tr className="">
                                                <td>2021-06-05 04:12:30 </td>
                                                <td>0.789 USDT </td>
                                                <td>muoidoanvan1009 </td>
                                            </tr>
                                            <tr className="">
                                                <td>2021-06-05 04:12:30 </td>
                                                <td>0.789 USDT </td>
                                                <td>muoidoanvan1009 </td>
                                            </tr>
                                            <tr className="">
                                                <td>2021-06-05 04:12:30 </td>
                                                <td>0.789 USDT </td>
                                                <td>muoidoanvan1009 </td>
                                            </tr>
                                            <tr className="">
                                                <td>2021-06-05 04:12:30 </td>
                                                <td>0.789 USDT </td>
                                                <td>muoidoanvan1009 </td>
                                            </tr>
                                            <tr className="">
                                                <td>2021-06-05 04:12:30 </td>
                                                <td>0.789 USDT </td>
                                                <td>muoidoanvan1009 </td>
                                            </tr>
                                            <tr className="">
                                                <td>2021-06-05 04:12:30 </td>
                                                <td>0.789 USDT </td>
                                                <td>muoidoanvan1009 </td>
                                            </tr>

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container >
            </section >




            <Footer />

        </>
    )
}

export default Rewardhistory;
