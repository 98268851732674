import React, { useEffect, useState } from 'react';
import {  Row, Col, Button, Form } from 'react-bootstrap';
import Cookies from 'js-cookie';
import '../components/componentCss/profile.css'
import config from "../coreFiles/config";
import { getprofileDetail, countryList } from '../coreFiles/action'
import { Link } from 'react-router-dom';
import { MdOutlineContentCopy } from "react-icons/md";
import toast, { Toaster } from "react-hot-toast";
import {CopyToClipboard} from 'react-copy-to-clipboard';




const ProfileDetail = (props) => {

    const [profile, setProfile] = useState({})
    const [countryLists, setcountryList] = useState([])

    useEffect(() => {
        getDetail()
        getCountry()
    }, [])

     
 const copieBtn = async () => {
    toast.success(`Copied!!`);
}

    const getCountry = async () => {
        let res = await countryList()
        setcountryList(res.data)
    }

    const getDetail = async () => {
        let res = await getprofileDetail()
        if (res.success) {
            setProfile(res.data)
        }
    }

    const editProfile = () => {
        props.modalEdit()
    }

    const logoutUser = () => {
        Cookies.remove("loginASIEX")
        window.location.href = `${config.baseUrl}sign-in`
    }

    return (
        <>

            <Col xl={8} lg={8} md={12} xs={12} className="pt-lg-5 pt-md-5 pt-3">
                <Toaster/>
                <div className="profileContent p-lg-5 p-md-5 p-3 pt-4 mt-lg-0 mt-md-0 mt-4">
                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="">
                            <div className='paterBox'>
                                <div className='paterBoxleft'>
                                    <div>
                                        <img src={config.imageUrl + profile.profile_image} style={{width:"120px",height:"120px"}}  alt='' className='userImg me-xl-5 me-lg-0 me-0 mb-3' />
                                    </div>
                                    <div className='paterText'>
                                        <h4>{profile.username || 'Anonymous'}</h4>
                                     
                                    </div>
                                </div>
                              

                            </div>
                        </Col>
                    </Row>


                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="pt-lg-0 pt-md-0 pt-0">
                            <div className='detailBlock'>
                                <div className='detailBlockleft'>
                                    <ul>
                                        <li>
                                            <h6>ID</h6>
                                        </li>
                                        <li>
                                            <h6>Phone number</h6>
                                        </li>
                                        <li>
                                            <h6>Identification Number</h6>
                                        </li>
                                        <li>
                                            <h6>Email</h6>
                                        </li>
                                        <li>
                                            <h6>2 FA</h6>
                                        </li>
                                    </ul>
                                </div>
                                <div className='detailBlockright'>
                                    <ul>
                                        <li>
                                            <h6 className='mb-0'>{profile.id}</h6> 
                                          
                                            
                                            <span>
                                            <CopyToClipboard text={profile.id}>
                                                <MdOutlineContentCopy className='copyIcons ms-2' onClick={copieBtn} style={{ cursor: "pointer" }}/></CopyToClipboard></span>
                                        </li>
                                        <li>
                                            <h6>{profile.phone}</h6>
                                        </li>
                                        <li>
                                            <h6>{profile.documentNumber == null ? 'NA':profile.documentNumber}</h6>
                                        </li>
                                        <li>
                                            <h6>{profile.email}</h6>
                                        </li>
                                        <li>
                                            <div className="checkswitch">
                                                <Form>
                                                    <Form.Check
                                                        type="switch"
                                                        checked={profile['2fa_enabled'] == 1 ? true : false}
                                                        id="custom-switch"
                                                        label=""
                                                    />
                                                </Form>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={6} lg={6} md={6} xs={12} className="pt-lg-5 pt-md-5 pt-3">
                            <Button onClick={e => editProfile(e)}
                                variant="custombtn2"
                                className="px-4 w-100"
                            >
                                <Link > Edit</Link>
                            </Button>
                        </Col>
                        <Col xl={6} lg={6} md={6} xs={12} className="pt-lg-5 pt-md-5 pt-3">
                            <Button
                                variant="custombtn1"
                                className="px-4 w-100"
                                onClick={e => logoutUser(e)}
                            >
                                Log out
                            </Button>
                        </Col>
                    </Row>

                </div>
            </Col>



        </>
    )
}

export default ProfileDetail;
