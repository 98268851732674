import React, { useEffect, useState } from "react";
import config from "../coreFiles/config";
import LoginHeader from "../directives/header";
import Footer from "../directives/footer";
import { HiMiniChevronUpDown } from "react-icons/hi2";
import {

  Row,
  Col,
  Button,
  Tabs,
  Tab,

} from "react-bootstrap";

import { Link } from "react-router-dom";
import {
  getPortFolio,
  getWallet,
  getDeposit,
  getWithdraw,
} from "../coreFiles/action";
import moment from "moment";


const Wallet = () => {
  const [balance, setbalance] = useState({ usdBalance: 0, btcBalance: 0 });
  const [TabId, setTabId] = useState(1);
  const [walletData, setwalletData] = useState([]);
  const [depositData, setdepositData] = useState([]);
  const [withdrawData, setwithdrawData] = useState([]);
  const [currentlyActive, setCurrentlyActive] = useState("Asset");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    getBalance();
  }, []);

  const filteredWalletData = walletData.filter((item) => {
    return item.name.toLowerCase().includes(searchTerm.toLowerCase()) || item.symbol.toLowerCase().includes(searchTerm.toLowerCase());
  });

  const getBalance = async () => {
    let deposit = await getDeposit();

    if (deposit.success) {
      setdepositData(deposit.data);
    }

    let withdraw = await getWithdraw();

    if (withdraw.success) {
      setwithdrawData(withdraw.data);
    }

    let wallet = await getWallet();

    if (wallet.success) {
      setwalletData(wallet.data);
    }

    let res = await getPortFolio();

    if (res.success) {
      setbalance(res.data);
    }
  };


  const navClick = (data) => {
    setCurrentlyActive(data);
    // If Overview is clicked, set TabId to 1 (Asset)
  };

  const handleTabSelect = (key) => {  
    setCurrentlyActive(key);
    // If the tab is changed to "Asset", set TabId to 1
  };

  const viewFile = (e, src) => {
    window.open(src);
  }

  return (
    <>
      <div className="outer">
        <LoginHeader />
        <div className="outer__inner">
          <div className="wallet">
            <div className="wallet__sidebar">
              <div className="walletBox ">
                <ul>
                  <li className={currentlyActive === "Asset" ? "active" : ""}>
                    <Link onClick={() => navClick("Asset")}>
                      <span>
                        <img
                          src="assets/icons/overviewIcon.svg"
                          className="walletIcons"
                        />
                      </span>
                      Overview
                    </Link>
                  </li>
                  <li style={{ display: "none" }}>
                    <Link to={`${config.baseUrl}`}>
                      <span>
                        <img
                          src="assets/icons/transferIcon.svg"
                          className="walletIcons"
                        />
                      </span>
                      Transfer
                    </Link>
                  </li>
                  <li className={currentlyActive === "Deposit" ? "" : ""}>
                    <Link
                      to={`${config.baseUrl}Deposit`}
                    >
                      <span>
                        <img
                          src="assets/icons/depositIcon.svg"
                          className="walletIcons"
                        />
                      </span>
                      Deposit
                    </Link>
                  </li>
                  <li
                    className={currentlyActive === "" ? "active" : ""}
                  >
                    <Link
                      to={`${config.baseUrl}Withdraw`}
                    >
                      <span>
                        <img
                          src="assets/icons/withdrawIcon.svg"
                          className="walletIcons"
                        />
                      </span>
                      Withdraw
                    </Link>
                  </li>
                </ul>

                <hr style={{ border: "1px solid #6e727c" }} />
              </div>
              <div className="wallet__btns d-none">
                <a
                  className="button button-small wallet__button"
                  href="deposit-fiat.html"
                >
                  Deposit
                </a>
                <a
                  className="button-stroke button-small wallet__button js-popup-open"
                  href="#popup-withdraw"
                  data-effect="mfp-zoom-in"
                >
                  Withdraw
                </a>
                <a
                  className="button-stroke button-small wallet__button js-popup-open"
                  href="#popup-transfer"
                  data-effect="mfp-zoom-in"
                >
                  Transfer
                </a>
              </div>
            </div>
            <div className="wallet__wrapper">
              <div className="wallet__main overviewText">
                <div className="wallet__line">
                  <h4 className="wallet__title h4">Overview</h4>

                  {currentlyActive === "Deposit" ||
                    currentlyActive === "Withdraw" ? (
                    ""
                  ) : (
                    <div className="wallet__wrap">
                      <form className="subscription">
                        <input
                          className="subscription__input"
                          type="text"
                          name="search"
                          placeholder="Search coin"
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                          required="required"
                        />
                        <button className="subscription__btn">
                          <svg className="icon icon-search">
                            <use xlinkHref="#icon-search" />
                          </svg>
                        </button>
                      </form>
                  
                    </div>
                  )}
                </div>
                <div className="wallet__details">
                  <div className="wallet__info">Total balance</div>
                  <div className="wallet__currency">
                    <div className="wallet__number" translate="no">
            
                      {parseFloat(balance.btcBalance).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                    </div>
                    <div className="category-green wallet__category">BTC</div>
                  </div>
                  <div className="wallet__price" translate="no">
                    ${parseFloat(balance.usdBalance).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                  </div>
                </div>
              </div>
              <div className="wallet__list">
                <div className="wallet__item">
                  <div className="wallet__head px-0 pt-5">
                    <Tabs
                      activeKey={currentlyActive}
                      id="uncontrolled-tab-example"
                      className="limitmarket_tabs wallettabs mb-5 border-0"
                      onSelect={handleTabSelect}
                      onClick={(e) =>
                        setTabId(
                          e.target.id == "uncontrolled-tab-example-tab-Asset"
                            ? 1
                            : e.target.id ==
                              "uncontrolled-tab-example-tab-Deposit"
                              ? 2
                              : 3
                        )
                      }
                    >
                      <Tab eventKey="Asset" title="Asset">
                        <Row>
                          <Col xl={12} lg={12} md={12} xs={12} className="">
                            <div className=" walletTable">
                              <div className=" table-responsive">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>
                                        Asset{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                      <th>
                                        Amount{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                      <th>
                                        Action{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {filteredWalletData &&
                                      filteredWalletData.length > 0 ? (
                                      filteredWalletData.map((item) => (
                                        <tr className="active">
                                          <td>
                                            <div>
                                              <div className="coindetails">
                                                <div>
                                                  <img
                                                    src={`${config.imageUrl}${item.icon}`}
                                                    className="bitcoin me-3 cryto_icons"
                                                  />
                                                </div>
                                                <div>
                                                  <h6>{item.symbol}</h6>
                                                  <span>{item.name}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <h6 translate="no">
                                              {parseFloat(item.balance).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                            </h6>
                                          </td>

                                          <td>
                                            <div className="buysellBtn">
                                            <Link
                                                  className=""
                                                > <Button onClick={e=>window.location.href=`${config.baseUrl}exchangenew/${item.symbol}`}>
                                               
                                                  Buy/Sell
                                               
                                              </Button>
                                              </Link>
                                            </div>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr className="active">
                                        <td colSpan="4" className="text-center">
                                          No Coins Found
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                      <Tab eventKey="Deposit" title="Deposit History">
                        <Row>
                          <Col xl={12} lg={12} md={12} xs={12} className="pt-3">
                            <div className=" walletTable">
                              <div className=" table-responsive">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>
                                        Currency{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                      <th>
                                        Amount{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                      <th>
                                        Receipt{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                      <th>
                                        Status{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                      <th>
                                        Date{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {depositData && depositData.length > 0 ? (
                                      depositData.map((item) => (
                                        <tr className="active">
                                          <td>
                                            <div>
                                              <div className="coindetails">
                                                <div>
                                                  <img
                                                    src={`${config.imageUrl}${item.icon}`}
                                                    className="bitcoin me-3 cryto_icons"
                                                  />
                                                </div>
                                                <div>
                                                  <h6>{item.symbol}</h6>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <h6 translate="no">
                                              {parseFloat(item.amount).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                            </h6>
                                          </td>
                                          <td>
                                            <h6>
                                              <img
                                                onClick={e => viewFile(e, `${config.imageUrl}${item.receipt}`)}
                                                src={`${config.imageUrl}${item.receipt}`}
                                                className="bitcoin me-3 cryto_icons"
                                              />
                                            </h6>
                                          </td>
                                          <td>
                                            <h6>
                                              {item.status == 0
                                                ? "Pending"
                                                : item.status == 1
                                                  ? "Completed"
                                                  : "Rejected"}
                                            </h6>
                                          </td>
                                          <td>
                                            <h6>{moment(item.dateTime).format(
                                              "LLL"
                                            )}</h6>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr className="text-center">
                                        <td colSpan="7">
                                          <p>No Transactions Found</p>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Tab>

                      <Tab eventKey="Withdraw" title="Withdraw History">
                        <Row>
                          <Col xl={12} lg={12} md={12} xs={12} className="pt-3">
                            <div className=" walletTable">
                              <div className=" table-responsive">
                                <table class="table">
                                  <thead>
                                    <tr>
                                      <th>
                                        Currency{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                      <th>
                                        Amount{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                      <th>
                                        Bank Name{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                      <th>
                                        Account Name{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                      <th>
                                        Account Number{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>

                                      <th>
                                        Status{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                      <th>
                                        Date{" "}
                                        <span>
                                          <HiMiniChevronUpDown className="dropIcon" />
                                        </span>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {withdrawData && withdrawData.length > 0 ? (
                                      withdrawData.map((item) => (
                                        <tr className="active">
                                          <td>
                                            <div>
                                              <div className="coindetails">
                                                <div>
                                                  <img
                                                    src={`${config.imageUrl}${item.icon}`}
                                                    className="bitcoin me-3 cryto_icons"
                                                  />
                                                </div>
                                                <div>
                                                  <h6>{item.symbol}</h6>
                                                </div>
                                              </div>
                                            </div>
                                          </td>
                                          <td>
                                            <h6 translate="no">
                                              {parseFloat(item.amount).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                            </h6>
                                          </td>

                                          <td>
                                            <h6>{item.bankName}</h6>
                                          </td>
                                          <td>
                                            <h6>{item.accountName}</h6>
                                          </td>
                                          <td>
                                            <h6>{item.accountNumber}</h6>
                                          </td>
                                          <td>
                                            <h6>
                                              {item.status == 0
                                                ? "Pending"
                                                : item.status == 1
                                                  ? "Completed"
                                                  : "Rejected"}
                                            </h6>
                                          </td>
                                          <td>
                                            <h6>{moment(item.dateTime).format(
                                              "LLL"
                                            )}</h6>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr className="text-center">
                                        <td colSpan="7">
                                          <p>No Transactions Found</p>
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="popup popup_transfer mfp-hide" id="popup-transfer">
            <div className="popup__title h4">
              <svg className="icon icon-arrow-left">
                <use xlinkHref="#icon-arrow-left" />
              </svg>
              Transfer
            </div>
            <div className="popup__wrap">
              <div className="popup__category">
                Available <br />
                balance
              </div>
              <div className="popup__details">
                <div className="popup__currency">2.56213968 ETH</div>
                <div className="popup__price">$10,095.35</div>
              </div>
            </div>
            <div className="field">
              <div className="field__label">from</div>
              <div className="field__wrap">
                <select className="select">
                  <option>Margin</option>
                  <option>Fiat and Spot</option>
                </select>
              </div>
            </div>
            <div className="popup__sign">
              <svg className="icon icon-arrows">
                <use xlinkHref="#icon-arrows" />
              </svg>
            </div>
            <div className="field">
              <div className="field__label">to</div>
              <div className="field__wrap">
                <select className="select">
                  <option>Fiat and Spot</option>
                  <option>Margin</option>
                </select>
              </div>
            </div>
            <div className="field">
              <div className="field__label">coin</div>
              <div className="field__wrap">
                <select className="select">
                  <option>ETH Ethereum</option>
                  <option>BTC Bitcoin</option>
                  <option>TRX Tron</option>
                </select>
              </div>
            </div>
            <div className="field field_button">
              <div className="field__label">Amount to transfer</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  name="amount"
                  required="required"
                />
                <button className="button-stroke button-small field__button">
                  Max amount
                </button>
              </div>
              <div className="field__note">
                2.14646231064565653 ETH available
              </div>
            </div>
            <button className="button popup__button">Transfer</button>
          </div>
          <div className="popup popup_withdraw mfp-hide" id="popup-withdraw">
            <div className="popup__title h4">
              <svg className="icon icon-arrow-left">
                <use xlinkHref="#icon-arrow-left" />
              </svg>
              Withdraw USDT
            </div>
            <div className="field">
              <div className="field__label">address</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  name="address"
                  required="required"
                />
              </div>
              <div className="field__note">
                Do not send Tether USD unless you are certain the destination
                supports TRC-20 transactions. If it does not, you could
                permanently lose access to your coins.
              </div>
            </div>
            <label className="checkbox">
              <input
                className="checkbox__input"
                type="checkbox"
                defaultChecked="checked"
              />
              <span className="checkbox__inner">
                <span className="checkbox__tick" />
                <span className="checkbox__text">
                  Save address for next time
                </span>
              </span>
            </label>
            <div className="popup__wrap">
              <div className="popup__category">
                Available <br />
                balance
              </div>
              <div className="popup__details">
                <div className="popup__currency">10075.56213968 USDT</div>
                <div className="popup__price">$10,095.35</div>
              </div>
            </div>
            <div className="field field_button">
              <div className="field__label">Amount to withdraw</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  name="amount"
                  required="required"
                />
                <button className="button-stroke button-small field__button">
                  Max amount
                </button>
              </div>
              <div className="field__note">
                $1,000,000.00 daily withdrawal limit remaining.
              </div>
            </div>
            <div className="field">
              <div className="field__label">transaction fee</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  name="transaction"
                  placeholder="0.00000000 USDT"
                  required="required"
                />
              </div>
            </div>
            <div className="field">
              <div className="field__label">total</div>
              <div className="field__wrap">
                <input
                  className="field__input"
                  type="text"
                  name="total"
                  placeholder="0.00000000 USDT"
                  required="required"
                />
              </div>
            </div>
            <a
              className="button popup__button js-popup-open"
              href="#popup-successfully"
              data-effect="mfp-zoom-in"
            >
              Withdraw
            </a>
          </div>
          <div
            className="popup popup_successfully mfp-hide"
            id="popup-successfully"
          >
            <div className="successfully">
              <div className="successfully__title h2">Yay! 🎉</div>
              <div className="successfully__info">
                You successfully a withdrawal <span>100.00 USDT</span> from
                Bitcloud
              </div>
              <div className="successfully__list">
                <div className="successfully__item">
                  <div className="successfully__category">Status</div>
                  <div className="successfully__content">Completed</div>
                </div>
                <div className="successfully__item">
                  <div className="successfully__category">Transaction ID</div>
                  <div className="successfully__content">
                    0msx836930...87r398
                  </div>
                </div>
                <div className="successfully__item">
                  <div className="successfully__category">Address</div>
                  <div className="successfully__content">
                    0xf8aaa2b1842e796191e736434sda....479
                  </div>
                </div>
              </div>
              <a
                className="button successfully__button"
                href="wallet-overview.html"
              >
                Wallet
              </a>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
};
export default Wallet;
