import React, { useEffect, useState, useRef } from "react";
import {
  Row,
  Col,
  Button
} from "react-bootstrap";

import toast, { Toaster } from "react-hot-toast";
import "../components/componentCss/profile.css";
import config from "../coreFiles/config";
import {
  coinListAPI,
  depositRequest,
  getDashboardStatisticsUserAction,
} from "../coreFiles/action";
import { Link } from "react-router-dom";

import ImageUploader from "react-image-upload";
import "react-image-upload/dist/index.css";
import Swal from 'sweetalert2'
const Deposit = () => {
  const imageUploaderRef = useRef();
  const [coins, setCoins] = useState([]);
  const [depositForm, setdepositForm] = useState({
    amount: "",
    receipt: "",
    comment: "",
  });
  const [settingCount, setSettingCount] = useState({});

  function getImageFileObject(imageFile) {
    let file = { imageFile };
    setdepositForm((old) => {
      return { ...old, ["receipt"]: file.imageFile.file };
    });

  }

  function runAfterImageDelete(file) {
    console.log({ file });
  }

  useEffect(() => {
    getCoins();
    getDashboardStatisticsUser();
  }, []);

  const getDashboardStatisticsUser = async () => {
    let res = await getDashboardStatisticsUserAction();
    if (res.success) {
      setSettingCount(res.data);
    }
    if (res.data.deposit == 0) {
      await Swal.fire({
        title: '<h1 style="font-size: 20px;">Deposit functionality has been temporarily disabled by the admin; please be patient.</h1>',
        icon: 'info',
        width: 500,
        confirmButtonColor: '#3772FF',
        allowOutsideClick: false,
        confirmButtonText: 'Continue',
      })
    }
  };

  const getCoins = async () => {
    let res = await coinListAPI();
    if (res.success) {
      setCoins(res.data);
    }
  };

  const handler = (e) => {
    const { name, value } = e.target;
    setdepositForm((old) => {
      return { ...old, [name]: value };
    });
  };

  const submitForm = async (e) => {


    var amount = parseFloat(depositForm.amount);
    var minDeposit = parseFloat(settingCount.minDeposit);
    var maxDeposit = parseFloat(settingCount.maxDeposit);

    if (amount < minDeposit) {
      toast.error("Amount should be greater than Min Deposit");
      return;
    }

    if (amount > maxDeposit) {
      toast.error("Amount should be less than Max Deposit");
      return;
    }


    if (depositForm.receipt == "") {
      toast.error("Reciept is required");
      return;
    } else {
      let res = await depositRequest(depositForm);
      if (res.success) {
        toast.success(res.msg);
        setdepositForm({ amount: 0, currencyId: 1, receipt: "", comment: "" });
        const images = document.getElementsByClassName('uploader__file')
        for (var i = 0; i < images.length; i++) {
          images[i].setAttribute('src', 'assets/images/uploadImg1.png');
        }

      } else {
        toast.error(res.msg)
      }
    }
  };

  const cancelBtn = () => {
    window.location.reload();
  };



  return (
    <>
      <Toaster />
      <Col xl={8} lg={8} md={12} xs={12} className="pt-lg-5 pt-md-5 pt-3">
        <div className="profileContent p-lg-5 p-md-5 p-3 pt-4 mt-lg-0 mt-md-0 mt-4">
          <Row>
            <Col xl={12} lg={12} md={12} xs={12} className="">
              <div className="deposit_sec">
                <h2 className="mb-4">Deposit</h2>
                <p>
                  Please get the latest account number every time if you
                  transfer the money.
                </p>
                <h4>Bank Account number</h4>
                <div className="qrcodeBox text-center py-3">
                  <img
                    style={{ background: "white" }}
                    src={config.imageUrl + settingCount.walletBarCode}
                    className="depositqrImg"
                  />
                </div>


                <Row>
                  <Col xl={12} lg={12} md={12} xs={12} className="pt-3">
                    <div className="detailBlock">
                      <div className="detailBlockleft">
                        <ul>
                          <li>
                            <h6>Account Holder Name </h6>
                          </li>
                          <li>
                            <h6>Bank Name </h6>
                          </li>
                          <li>
                            <h6>Account Number </h6>
                          </li>
                        </ul>
                      </div>
                      <div className="detailBlockright">
                        <ul>
                          <li>
                            <h6>{settingCount.accountHolderName}</h6>
                          </li>
                          <li>
                            <h6>{settingCount.bankName}</h6>
                          </li>
                          <li>
                            <h6>{settingCount.accountNumber}</h6>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                  <Col xl={12} lg={12} md={12} xs={12} className="pt-3">
                    <div className="detailBlock">
                      <div className="detailBlockleft">
                        <ul>
                          <li>
                            <h6>The Latest Exchange Rate</h6>
                          </li>
                          <li>
                            <h6>Minimum Deposit</h6>
                          </li>
                          <li>
                            <h6>Maximum Deposit </h6>
                          </li>
                        </ul>
                      </div>
                      <div className="detailBlockright">
                        <ul>
                          <li>
                            <h6 translate="no">1 USDT ~ {parseFloat(settingCount.vndUsdtPrice).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')} {" VND"}</h6>
                          </li>
                          <li>
                            <h6>{parseFloat(settingCount.minDeposit).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$</h6>
                          </li>
                          <li>
                            <h6>{parseFloat(settingCount.maxDeposit).toFixed(4)}$</h6>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <div className="field field_view mb-4">
                    <div className="field__label">Amount</div>
                    <div className="field__wrap">
                      <input
                        className="field__input"
                        type="text"
                        onKeyPress={(event) => {
                          const { value } = event.target;
                          if (
                            (event.key === "." && value.includes(".")) || // Prevent more than one decimal point
                            (!/^\d$/.test(event.key) && event.key !== ".") // Allow only digits and one decimal point
                          ) {
                            event.preventDefault();
                          }
                        }}
                        name="amount"
                        placeholder="Enter Amount (USDT)"
                        onChange={(e) => handler(e)}
                        value={depositForm.amount}
                      />
                      <button className="field__view">

                      </button>
                    </div>
                  </div>
                </Row>
                <Row>
                  <Col xl={12} lg={12} md={12} xs={12} className="pt-3">
                    <h3>Please upload the picture of transaction</h3>
                    <div className="uploadImgbox deposit">
                      <div className="text-center">
                        <div className="uploadbox1 mb-2">
                          <ImageUploader
                            ref={imageUploaderRef}
                            style={{ backgroundImage: `url('assets/images/uploadImg1.png')` }}
                            onFileAdded={(img) => getImageFileObject(img)}
                            onFileRemoved={(img) => runAfterImageDelete(img)}
                          />
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col xl={12} lg={12} md={12} xs={12} className="pt-4">
                    <h3>comments (optional)</h3>
                    <div className="field field_view mb-4">
                      <div className="field__wrap">
                        <textarea
                          className="field__input h-auto pt-2"
                          id="exampleFormControlTextarea1"
                          rows={6}
                          placeholder="Input"
                          name="comment"
                          onChange={(e) => handler(e)}
                          value={depositForm.comment}
                        />

                        <button className="field__view"></button>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xl={6} lg={6} md={6} xs={12} className="pt-3">
              <Button variant="custombtn2" className="px-4 w-100">
                <Link to="" onClick={cancelBtn}>
                  {" "}
                  Cancel
                </Link>
              </Button>
            </Col>
            <Col xl={6} lg={6} md={6} xs={12} className="pt-3">
              <Button
                disabled={settingCount.deposit == 0}
                variant="custombtn1"
                className="px-4 w-100"
                onClick={(e) => submitForm(e)}
              >
                Confirm
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default Deposit;
