import React, { useEffect, useState, useRef } from "react";
import LoginHeader from "../directives/header";
import Footer from "../directives/footer";
import {
  pairList,
  getBalance,
  placeOrderAPI,
  cancelOrderAPI,
  getCompleteTradeHistory,
  getopentradeHistory,
  getGraphDataAPI,
  getDashboardStatisticsUserAction
} from "../coreFiles/action";
import Websocket from "react-websocket";
import toast, { Toaster } from "react-hot-toast";
import Cookies from "js-cookie";
import { useNavigate, useParams } from "react-router-dom";
import config from "../coreFiles/config";
import { Link } from "react-router-dom";
import moment from "moment";
import "../components/componentCss/exchangenew.css";
import { Container, Row, Col, Button, Tabs, Tab, Form, Offcanvas } from "react-bootstrap";
import { CiSearch } from "react-icons/ci";
import { HiMiniChevronUpDown } from "react-icons/hi2";
import { IoIosArrowRoundDown } from "react-icons/io";
import { createChart } from "lightweight-charts";
import socketIOClient from "socket.io-client";
import { CiWallet } from "react-icons/ci";
import { IoIosArrowRoundUp } from "react-icons/io";
import { MdOutlineAccessTime } from "react-icons/md";
import { VscGraph } from "react-icons/vsc";
import Spinner from "react-bootstrap/Spinner";
import { DepthChart } from "pennant";
import { FaChevronDown } from "react-icons/fa6";
import "pennant/dist/style.css";
import Swal from 'sweetalert2'
const Exchangenew = () => {


  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { symbol } = useParams();

  const [graphType, setgraphType] = useState(1);
  const [chartUpdate, setchartUpdate] = useState(true);
  const [zoomState, setZoomState] = useState({ from: 1720782420, to: 1720787642 });
  const [socket, setSocket] = useState({ emit: "", on: "", connected: false });
  const navigate = useNavigate();
  const chartContainerRef = useRef(null);
  const chartInstanceRef = useRef(null);
  const loginData = !Cookies.get("loginASIEX")
    ? {}
    : JSON.parse(Cookies.get("loginASIEX"));
  const [livepairs, setlivePairs] = useState({ data: [] });
  const [pairs, setPairs] = useState([]);
  const [allPairs, setallPairs] = useState([]);
  const [selectedPairData, setselectedPairData] = useState((old) => {
    return {
      ...old,
      price: 0,
      pair: "-",
      name: "-",
      leftSymbol: "-",
      rightSymbol: "-",
      leftCoinId: '-',
      rightCoinId: '-',
      leftCoinBalance: 0,
      rightCoinBalance: 0,
    }
  });
  const [orderBook, setorderBook] = useState({ buyOrder: [], sellOrder: [] });
  const [webSocketReset, setwebSocketReset] = useState(true);
  const [tradeData, settradeData] = useState([]);
  const [tradeHistory, settradeHistory] = useState({ complete: [], open: [] });
  const [orderData, setorderData] = useState({
    amount: 0,
    total: 0,
    isMarket: 0,
    price: selectedPairData.price,
    leftCoinId: 1,
    rightCoinId: 5,
  });
  const [chartData, setchartData] = useState([]);
  const [depthChartData, setdepthChartData] = useState([]);
  const [chartlength, setchartlength] = useState(0)
  const [interval, setIntervals] = useState(1);
  const [graphMove, setgraphMove] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [settingCount, setSettingCount] = useState({});
  const [timeStamps, settimeStamps] = useState({ difference: 0 })


  useEffect(() => {
    getPairs(0);
    getDashboardStatisticsUser()
    getGMTtime()
  }, []);

 
  /*----------------       For TIMESTAMP Setting according every Country---------------- */
  const getGMTtime = () => {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = now.getUTCMonth() + 1; // Months are zero-based, so add 1
    const day = now.getUTCDate();
    const hours = now.getUTCHours();
    const minutes = now.getUTCMinutes();
    const seconds = now.getUTCSeconds();

    const formattedDateTime = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')} ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    const currentLocalDatetime = moment().format('YYYY-MM-DD HH:mm:ss')
    settimeStamps(getDateDifference(currentLocalDatetime, formattedDateTime))
  }

  const getDateDifference = (dateStr1, dateStr2) => {
    const currentUTC = new Date();
    // Create Date objects from date strings (assuming format YYYY-MM-DD HH:mm:ss)
    const date1 = new Date(dateStr1.replace(/-/g, '/')); // Replace '-' with '/' for cross-browser compatibility
    const date2 = new Date(dateStr2.replace(/-/g, '/'));

    // Check if either date is invalid
    if (isNaN(date1) || isNaN(date2)) {
      return 'Invalid date';
    }

    // Convert both dates to UTC to handle daylight saving time changes
    const utc1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate(), date1.getHours(), date1.getMinutes(), date1.getSeconds());
    const utc2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate(), date2.getHours(), date2.getMinutes(), date2.getSeconds());

    // Calculate the difference in milliseconds
    let difference = Math.abs(utc2 - utc1) / 1000;

    const sign = utc2 >= utc1 ? '-' : '+';

    return { difference: parseFloat(`${sign}${difference}`) };

  }


  useEffect(() => {
    setgraphMove(false);
    setchartUpdate(false);
    setdepthChartData([]);
    setchartData([]);
    setZoomState(null)
    getGraphData({
      leftSymbol: selectedPairData.leftSymbol,
      rightSymbol: selectedPairData.rightSymbol,
      leftCoinId: selectedPairData.leftCoinId,
      rightCoinId: selectedPairData.rightCoinId,
      type: interval,
    });

  }, [interval])


/*---------------- get some information from admin side ---------------- */
  const getDashboardStatisticsUser = async () => {
    let res = await getDashboardStatisticsUserAction();
    if (res.success) {
      setSettingCount(res.data);
    }
    if (res.data.trade == 0) {
      await Swal.fire({
        title: '<h1 style="font-size: 20px;">Trade functionality has been temporarily disabled by the admin; please be patient.</h1>',
        icon: 'info',
        width: 500,
        confirmButtonColor: '#3772FF',
        allowOutsideClick: false,
        confirmButtonText: 'Continue',
      })
    }
  };


  useEffect(() => {
    if (!socket.connected) {
      const newSocket = socketIOClient(config.socketURL);
      newSocket.connected = true;
      setSocket(newSocket);

      newSocket.on("getGraphData", (data) => {

        let lastObj = "";
        // console.log('graphMove', graphMove)
        if (data.success && chartUpdate == true && graphMove == false) {

          if (graphType == 2 && isNaN(data.data.tradingview[0].time)) {
            setchartData(data.data.tradingview);
            setdepthChartData(data.data.depthChart);

          } else if (graphType == 1 && !isNaN(data?.data?.tradingview[0]?.time)) {

            setchartData(data.data.tradingview);
            setdepthChartData(data.data.depthChart);
          }

          lastObj = data.data.tradingview[data.data.tradingview.length - 2];
// console.log('lastObj',lastObj.time,chartlength)
          if (lastObj && lastObj.time != chartlength) {
            setchartlength(lastObj.time)
          }


        }
        

      });

      newSocket.on('aaaa', (data) => {
        // console.log('pppppppppp===========');
      })

      newSocket.on('returnVirtualCoinPrice', (data) => {
        // console.log('ppppppppp-------- returnVirtualCoinPrice');
        if (data.pairs.length > 0) {
          setLoadingStatus(false)
          let coinPriceRes = data;
          const coinList1 = data.pairs;
          var i = 0;
          for (i = 0; i < coinList1.length; i++) {
            var item = coinList1[i];

            if (item.leftCoinId == 31 && coinPriceRes.data.spacPrice) {
              virtualCoinPriceset(coinPriceRes.data, data.selectPair)
              coinList1[i].price = coinPriceRes.data.spacPrice
                ? parseFloat(coinPriceRes.data.spacPrice).toFixed(4)
                : "0";
              coinList1[i].changePercentage = coinPriceRes.data.spacPercentage ?
                parseFloat(coinPriceRes.data.spacPercentage).toFixed(4)
                : "0"
            }

            if (item.leftCoinId == 32 && coinPriceRes.data.bgpcPrice) {
              virtualCoinPriceset(coinPriceRes.data, data.selectPair)
              coinList1[i].price = coinPriceRes.data.bgpcPrice
                ? parseFloat(coinPriceRes.data.bgpcPrice).toFixed(4)
                : "0";
              coinList1[i].changePercentage = coinPriceRes.data.bgpcPercentage ?
                parseFloat(coinPriceRes.data.bgpcPercentage).toFixed(4)
                : "0"
            }

          }

          setlivePairs((old) => {
            return { ...old, ["data"]: coinList1 };
          });
        }
      })

      newSocket.on("disconnect", () => {
        newSocket.connected = false;
        setSocket(newSocket);
      });
    }
  }, [socket]);


  const virtualCoinPriceset = (data, selectPair) => {
    if (['SPAC', 'BGPC'].includes(selectPair.leftSymbol)) {
      setselectedPairData((old) => {
        return {
          ...old,
          ["price"]: selectPair.leftSymbol == 'SPAC' ? parseFloat(data.spacPrice).toFixed(4) : parseFloat(data?.bgpcPrice).toFixed(4),
          ["high"]: selectPair.leftSymbol == 'SPAC' ? parseFloat(data.spachigh).toFixed(4) : parseFloat(data?.bgpchigh).toFixed(4),
          ["low"]: selectPair.leftSymbol == 'SPAC' ? parseFloat(data.spaclow).toFixed(4) : parseFloat(data?.bgpclow).toFixed(4),
          ["volume"]: selectPair.leftSymbol == 'SPAC' ? parseFloat(data.spacvolume).toFixed(4) : parseFloat(data?.bgpcvolume).toFixed(4),
          ["changePercentage"]: selectPair.leftSymbol == 'SPAC' ? parseFloat(data.spacPercentage).toFixed(4) : parseFloat(data?.bgpcPercentage).toFixed(4),
        };
      });
    }
  }

  

/*---------------- get graph data API on intial run time ---------------- */

  const getGraphData = async (data) => {
    // setchartUpdate(false)
    data["graphType"] = graphType;
    let res = await getGraphDataAPI(data);
    if (res.success) {
      setchartData(res.data.tradingview);
      setdepthChartData(res.data.depthChart);
      setchartUpdate(true)
    }
  };

  useEffect(() => {
    if (
      !chartData ||
      !chartData.length ||
      graphType == 2 ||
      chartUpdate == false
    )
      return;


    // Initialize the chart when the component mounts
    const chart = createChart(chartContainerRef.current, {
      width: chartContainerRef.current.clientWidth,
      height: chartContainerRef.current.clientHeight,
      layout: {
        background: {
          color: "#000000",
        },
        textColor: "#ffffff",
      },
      grid: {
        vertLines: {
          color: "#363c4e",
        },
        horzLines: {
          color: "#363c4e",
        },
      },
      crosshair: {
        mode: 0,
      },
      priceScale: {
        borderColor: "#485c7b",
      },
      timeScale: {
        borderColor: "#485c7b",
        timeVisible: true,
        secondsVisible: true,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        rightOffset: 0, // Prevent right margin
        barSpacing: 10, // Adjust bar spacing if needed
      },
      localization: {
        locale: "en-US",
      },
      // Add any other chart configuration options here
    });

    // Save the chart instance to useRef for future reference
    chartInstanceRef.current = chart;

    // Add a candlestick series to the chart
    const candleSeries = chart.addCandlestickSeries({
      // Optionally, you can customize candle colors, border colors, wick colors, etc.
      priceFormat: {
        minMove: 0.0001,
        precision: 4,
      },
    });



    // Update the chart with custom trading history data
    candleSeries.setData(
      chartData.map((item) => ({
        time: item.time + (timeStamps.difference), // Timestamp of the data point
        open: item.open, // Open price
        close: item.close, // Close price
        high: item.high, // High price
        low: item.low, // Low price
        volume: item.volume, // Volume
      }))
    );

    // Scroll to the most recent data point
    chart.timeScale().scrollToRealTime();

    chart.applyOptions({
      symbol: "BTCUSDT",
    });

    const toolTipWidth = 80;
    const toolTipHeight = 80;
    const toolTipMargin = 15;

    // Create and style the tooltip html element
    const toolTip = document.createElement('div');
    toolTip.classList.add('tooltipddd')
    toolTip.style = `width: 130px; height: 130px; position: absolute; display: none; padding: 8px; box-sizing: border-box; font-size: 12px; text-align: left; z-index: 1000; top: 12px; left: 12px; pointer-events: none; border: 1px solid; border-radius: 2px;font-family: -apple-system, BlinkMacSystemFont, 'Trebuchet MS', Roboto, Ubuntu, sans-serif; -webkit-font-smoothing: antialiased; -moz-osx-font-smoothing: grayscale;`;
    toolTip.style.background = 'white';
    toolTip.style.color = 'black';
    toolTip.style.borderColor = 'rgba(255, 82, 82, 1)';

    // if(graphMove==true){
    chartContainerRef.current.appendChild(toolTip);
    // }


    // update tooltip
    chart.subscribeCrosshairMove(param => {
      if (
        param.point === undefined ||
        !param.time ||
        param.point.x < 0 ||
        param.point.x > chartContainerRef.current.clientWidth ||
        param.point.y < 0 ||
        param.point.y > chartContainerRef.current.clientHeight
      ) {
        toolTip.style.display = 'none';
      }
      else {
        // time will be in the same format that we supplied to setData.
        // thus it will be YYYY-MM-DD
        const dateStr = param.time;
        toolTip.style.display = 'block';
        const data = param.seriesData.get(candleSeries);
        const price = data.value !== undefined ? data.value : data.close;
        toolTip.innerHTML = `<div translate="no" className='tooltip' style="font-size: 12px; margin: 4px 0px; color: ${'black'}">			
        Open: <span style="font-weight:bold">${parseFloat(data.open).toFixed(4)}  </span><br/> 
        Close: <span style="font-weight:bold">${parseFloat(data.close).toFixed(4)}</span><br/> 
        High: <span style="font-weight:bold"> ${parseFloat(data.high).toFixed(4)} </span><br/>
        Low: <span style="font-weight:bold">${parseFloat(data.low).toFixed(4)}</span><br/>
        Date: <span style="font-weight:bold">${moment(parseInt(data.time) * 1000).format('YYYY-MM-DD')}</span>
			</div>`;

        const y = param.point.y;
        let left = param.point.x + toolTipMargin;
        if (left > chartContainerRef.current.clientWidth - toolTipWidth) {
          left = param.point.x - toolTipMargin - toolTipWidth;
        }

        let top = y + toolTipMargin;
        if (top > chartContainerRef.current.clientHeight - toolTipHeight) {
          top = y - toolTipHeight - toolTipMargin;
        }
        toolTip.style.left = left + 'px';
        toolTip.style.top = top + 'px';
      }
    });

    // Restore zoom state on mount
    if (zoomState) {
      chart.timeScale().setVisibleRange(zoomState);
    }

    const handleVisibleRangeChange = () => {
      const visibleRange = chart.timeScale().getVisibleRange();
      const logicalRange = chart.timeScale().getVisibleLogicalRange(); 
      const visibleBars = logicalRange.to - logicalRange.from;

       // Forcefully move left if only 14-15 candles are visible
      if (visibleBars <= 15) {
        chart.timeScale().scrollToPosition(logicalRange.from - 15, false);
      }

       // Prevent scrolling beyond the leftmost point
       if (logicalRange.from <= 0) {
        chart.timeScale().scrollToPosition(0, false);
      }

      setZoomState(visibleRange);

    };


    chart.timeScale().subscribeVisibleTimeRangeChange(handleVisibleRangeChange);


    // Cleanup function
    return () => {
      // Dispose the chart when the component unmounts
      chart.timeScale().unsubscribeVisibleTimeRangeChange(handleVisibleRangeChange);
      if (chartInstanceRef.current !== null) {
        chartInstanceRef.current.remove();
      }

    };

  }, [chartData]);

  useEffect(() => {
    if (chartInstanceRef.current && chartlength > 0) {
      chartInstanceRef.current.timeScale().scrollToRealTime()
    };
    setZoomState(null)
  }, [chartlength])



/*---------------- get trade data API on intial run time ---------------- */
  const getTradeHistory = async (leftCoinId, rightCoinId) => {
    let res = await getCompleteTradeHistory({
      leftCoinId: leftCoinId,
      rightCoinId: rightCoinId,
    });

    if (res.success) {
      settradeHistory((old) => {
        return { ...old, ["complete"]: res.data };
      });
    }
  };

  /*---------------- get open data API on intial run time ---------------- */

  const getopentradeHistorys = async (leftCoinId, rightCoinId) => {
    let res = await getopentradeHistory({
      leftCoinId: leftCoinId,
      rightCoinId: rightCoinId,
    });

    if (res.success) {
      settradeHistory((old) => {
        return { ...old, ["open"]: res.data };
      });
    }
  };

    /*---------------- get pair data API on intial run time ---------------- */

  const getPairs = async (type) => {
    setLoadingStatus(true);
    let res = await pairList();
    if (res.success) {
      // setLoadingStatus(false);
      let array = [];
      for (let x in res.data) {
        let obj = res.data[x];
        array.push(obj);
      }
      setPairs(array);
      setallPairs(array);



      if (symbol) {
        const coinItem = array.find((item) => item.leftSymbol == symbol);

        if (['BGPC', 'SPAC'].includes(symbol)) {

          setselectedPairData((old) => {
            return {
              ...old,
              ['price']: coinItem.price,
              ['changePercentage']: `${coinItem.changePercentage}`,
              ['high']: `${coinItem.high}`,
              ['low']: `${coinItem.low}`,
              ['volume']: `${coinItem.volume}`,
              ['name']: `${coinItem.leftCoin}`,
              ['pair']: `${coinItem.leftSymbol}${coinItem.rightSymbol}`,
              ['leftSymbol']: `${coinItem.leftSymbol}`,
              ['rightSymbol']: `${coinItem.rightSymbol}`,
              ['leftCoinId']: `${coinItem.leftCoinId}`,
              ['rightCoinId']: `${coinItem.rightCoinId}`,
            }


          });
        } else {
          setselectedPairData((old) => {
            return {
              ...old,
              ['price']: coinItem.price,
              ['pair']: `${coinItem.leftSymbol}${coinItem.rightSymbol}`,
              ['leftCoinId']: `${coinItem.leftCoinId}`,
              ['rightCoinId']: `${coinItem.rightCoinId}`,
              ['name']: `${coinItem.leftCoin}`,
              ['leftSymbol']: `${coinItem.leftSymbol}`,
              ['rightSymbol']: `${coinItem.rightSymbol}`,

            }

          });
        }

        setTimeout(() => {
          getGraphData({
            leftSymbol: symbol,
            rightSymbol: "USDT",
            leftCoinId: coinItem.leftCoinId,
            rightCoinId: 5,
            type: 1,
          });
        }, 2000);


        if (loginData && loginData.id && type == 0) {
          getCoinBalance(coinItem.leftCoinId, coinItem.rightCoinId);
          getTradeHistory(coinItem.leftCoinId, coinItem.rightCoinId);
          getopentradeHistorys(coinItem.leftCoinId, coinItem.rightCoinId);
        }

      } else {
        let coinItem = array.find((item) => item.leftSymbol == 'BTC')
        if (['BGPC', 'SPAC'].includes(symbol)) {
          setselectedPairData((old) => {
            return {
              ...old,
              ['price']: coinItem.price,
              ['changePercentage']: `${coinItem.changePercentage}`,
              ['high']: `${coinItem.high}`,
              ['low']: `${coinItem.low}`,
              ['volume']: `${coinItem.volume}`
            }


          });
        } else {
          setselectedPairData((old) => {
            return {
              ...old,
              ['price']: coinItem.price,
              ['pair']: `${coinItem.leftSymbol}${coinItem.rightSymbol}`,
              ['leftCoinId']: `${coinItem.leftCoinId}`,
              ['rightCoinId']: `${coinItem.rightCoinId}`,
              ['name']: `${coinItem.leftCoin}`,
              ['leftSymbol']: `${coinItem.leftSymbol}`,
              ['rightSymbol']: `${coinItem.rightSymbol}`,

            }
          });
        }

        getGraphData({
          leftSymbol: "BTC",
          rightSymbol: "USDT",
          leftCoinId: 1,
          rightCoinId: 5,
          type: 1,
        });
        if (loginData && loginData.id && type == 0) {
          getCoinBalance(coinItem.leftCoinId, coinItem.rightCoinId);
          getTradeHistory(coinItem.leftCoinId, coinItem.rightCoinId);
          getopentradeHistorys(coinItem.leftCoinId, coinItem.rightCoinId);
        }

      }

    }

  };


    /*---------------- get coin balance data API on intial run time ---------------- */
  const getCoinBalance = async (leftCoinId, rightCoinId) => {
    let res = await getBalance({
      leftCoinId: leftCoinId,
      rightCoinId: rightCoinId,
    });
    if (res.success) {
      setselectedPairData((old) => {
        return {
          ...old,
          ["leftCoinBalance"]: res.data.leftCoinBalance,
          ["rightCoinBalance"]: res.data.rightCoinBalance,
        };
      });
    }
  };

      /*---------------- get socket data from binance of binace pairs ---------------- */
  const livePairDataFromBinance = async (socketData) => {
    socketData = JSON.parse(socketData);
    if (!graphMove) {
      socket.emit("graphData", {
        leftSymbol: selectedPairData.leftSymbol,
        rightSymbol: selectedPairData.rightSymbol,
        leftCoinId: selectedPairData.leftCoinId,
        rightCoinId: selectedPairData.rightCoinId,
        type: interval,
        graphType: graphType,
      });
    }


    if (pairs.length > 0) {
      const coinList1 = pairs;
      var i = 0;
      for (i = 0; i < coinList1.length; i++) {
        var item = coinList1[i];

        var pair = `${item.leftSymbol}${item.rightSymbol}`;
        const si = socketData.findIndex((el) => el["s"] === pair);
        if (si > -1) {
          var live_price = socketData[si].c; //old

          coinList1[i].price = parseFloat(live_price).toFixed(4);
          coinList1[i].changePercentage = socketData[si].P;
          coinList1[i].high = socketData[si].h;
          coinList1[i].low = socketData[si].l;
          coinList1[i].volume = socketData[si].q;
        }

        if (
          coinList1[i].is_custom == 1 &&
          pair ==
          `${selectedPairData.leftSymbol}${selectedPairData.rightSymbol}`
        ) {

          coinList1[i].changePercentage = -parseFloat(2.5 + Math.random() * (3.5 - 2.5)).toFixed(4);
          coinList1[i].high = parseFloat(selectedPairData?.high).toFixed(4);
          coinList1[i].low = parseFloat(selectedPairData?.low).toFixed(4);
          coinList1[i].volume = parseFloat(selectedPairData?.volume).toFixed(4);
        }

        if (socketData[si]?.s == selectedPairData.pair && socketData[si]?.c && item.leftCoin != 31 && item.leftCoin != 32) {
          setselectedPairData((old) => {
            return {
              ...old,
              ["price"]: parseFloat(socketData[si]?.c).toFixed(4),
              ["high"]: parseFloat(socketData[si]?.h).toFixed(4),
              ["volume"]: parseFloat(socketData[si]?.q).toFixed(4),
              ["low"]: parseFloat(socketData[si]?.l).toFixed(4),
              ["changePercentage"]: parseFloat(socketData[si]?.P).toFixed(4),
            };
          });
        }

      }
      if (!(selectedPairData.leftSymbol == '-')) {
        socket.emit("virtualCoinPrice", { pairs: coinList1, selectPair: selectedPairData })
      }
    }
  };

  
      /*---------------- get order book  data from binance of binace pairs but now commented because there is no use ---------------- */
  // const liveOrderFromBinance = (wsdata) => {
  //   wsdata = JSON.parse(wsdata);

  //   if (wsdata && wsdata.a.length > 0 && wsdata.b.length > 0) {
  //     getLoopData(wsdata.a);
  //   }

  //   if (wsdata && wsdata.b.length > 0 && wsdata.a.length > 0) {
  //     getLoopData1(wsdata.b);
  //   }
  // };

  // const getLoopData = (data) => {
  //   let sellnewArray = orderBook.sellOrder;
  //   const toFix = 4;

  //   if (
  //     data.length == 0 &&
  //     sellnewArray.length > 0 &&
  //     sellnewArray[0]?.symbol == `${selectedPairData.pair}`
  //   ) {
  //     sellnewArray = [];
  //   }

  //   for (let x = 0; x < data.length; x++) {
  //     let obj = {};

  //     if (data && data.length > 0) {
  //       obj.usdtprice = parseFloat(data[x][0]).toFixed(toFix);
  //       obj.coinorderamount = data[x][1];
  //       obj.totalprice = parseFloat(data[x][0] * data[x][1]).toFixed(toFix);
  //       obj.symbol = data[x][2] ? data[x][2] : `${selectedPairData.pair}`;
  //       obj.quantity = parseFloat(data[x][1]).toFixed(4);
  //       obj.tradeType = "Sell";
  //     }

  //     if (obj.totalprice > 0 && sellnewArray.length <= 22) {
  //       const objIndex = sellnewArray.findIndex(
  //         (objs) => objs.usdtprice == parseFloat(data[x][0]).toFixed(toFix)
  //       );
  //       const filter = data.filter(
  //         (item) =>
  //           parseFloat(item[0]).toFixed(6) == parseFloat(data[x][0]).toFixed(6)
  //       );

  //       if (objIndex > -1) {
  //         const totalQuantity = filter.reduce(
  //           (accum, item) => accum + parseFloat(item[1]),
  //           0
  //         );

  //         sellnewArray[objIndex].quantity =
  //           parseFloat(totalQuantity).toFixed(6);
  //       } else {
  //         sellnewArray.push(obj);
  //       }
  //       if (sellnewArray.length > 21) {
  //         sellnewArray.shift();
  //       }
  //     }
  //   }

  //   sellnewArray = sellnewArray.sort((a, b) => a.usdtprice - b.usdtprice);
  //   sellnewArray = sellnewArray.filter(
  //     (obj) => obj.symbol == `${selectedPairData.pair}`
  //   );

  //   setorderBook((old) => {
  //     return { ...old, ["sellOrder"]: sellnewArray };
  //   });
  // };

  // const getLoopData1 = (data) => {
  //   let buynewArray;
  //   const toFix = 4;
  //   buynewArray = orderBook.buyOrder;

  //   if (
  //     data.length == 0 &&
  //     buynewArray.length > 0 &&
  //     buynewArray[0]?.symbol == `${selectedPairData.pair}`
  //   ) {
  //     buynewArray = [];
  //   }

  //   //     /*------------------------Remove same price existing data end----------------------------------*/
  //   // }

  //   for (let x = 0; x < data.length; x++) {
  //     let obj = {};
  //     if (data && data.length > 0) {
  //       obj.usdtprice = parseFloat(data[x][0]).toFixed(toFix);
  //       obj.coinorderamount = data[x][1];
  //       obj.totalprice = parseFloat(data[x][0] * data[x][1]).toFixed(4);
  //       obj.quantity = parseFloat(data[x][1]).toFixed(4);
  //       obj.symbol = data[x][2] ? data[x][2] : `${selectedPairData.pair}`;
  //       obj.tradeType = "Buy";
  //     }

  //     if (obj.totalprice > 0 && buynewArray.length <= 22) {
  //       const objIndex = buynewArray.findIndex(
  //         (objs) => objs.usdtprice == parseFloat(data[x][0]).toFixed(toFix)
  //       );
  //       const filter = data.filter(
  //         (item) =>
  //           parseFloat(item[0]).toFixed(6) == parseFloat(data[x][0]).toFixed(6)
  //       );

  //       if (objIndex > -1) {
  //         const totalQuantity = filter.reduce(
  //           (accum, item) => accum + parseFloat(item[1]),
  //           0
  //         );

  //         buynewArray[objIndex].quantity = parseFloat(totalQuantity).toFixed(6);
  //       } else {
  //         buynewArray.push(obj);
  //       }

  //       if (buynewArray.length > 21) {
  //         buynewArray.shift();
  //       }
  //     }
  //   }
  //   var uniq = {};
  //   buynewArray = buynewArray.sort((a, b) => b.usdtprice - a.usdtprice);
  //   buynewArray = buynewArray.filter(
  //     (obj) =>
  //       !uniq[obj.usdtprice] &&
  //       (uniq[obj.usdtprice] = true && obj.symbol == `${selectedPairData.pair}`)
  //   );

  //   setorderBook((old) => {
  //     return { ...old, ["buyOrder"]: buynewArray };
  //   });
  // };



  // const liveTradeDataFromBinance = (tradedaa) => {
  //   tradedaa = JSON.parse(tradedaa);
  //   var newArr = tradeData;
  //   if (newArr.length <= 25) {
  //     newArr.push(tradedaa);
  //     if (newArr.length > 24) {
  //       newArr.shift();
  //     }
  //   }
  //   settradeData(newArr);
  // };

  const selectCoin = (e, item) => {
    setgraphMove(false);
    setchartUpdate(false);
    setdepthChartData([]);
    setchartData([]);
    e.preventDefault();
    setwebSocketReset(false);
    setselectedPairData((old) => {
      return {
        ...old,
        ['price']: item.leftCoinId == 31 || item.leftCoinId == 32 ? item.price : 0,
        ['changePercentage']:
          item.leftCoinId == 31 || item.leftCoinId == 32
            ? item.changePercentage
            : 0,
        ['low']: item.leftCoinId == 31 || item.leftCoinId == 32 ? item.low : 0,
        ['high']: item.leftCoinId == 31 || item.leftCoinId == 32 ? item.high : 0,
        ['volume']: item.leftCoinId == 31 || item.leftCoinId == 32 ? item.volume : 0,
        ['pair']: `${item.leftSymbol}${item.rightSymbol}`,
        ['leftCoinId']: `${item.leftCoinId}`,
        ['rightCoinId']: `${item.rightCoinId}`,
        ['name']: `${item.leftCoin}`,
        ['leftSymbol']: `${item.leftSymbol}`,
        ['rightSymbol']: `${item.rightSymbol}`,
      }
    });
    getGraphData({
      leftSymbol: item.leftSymbol,
      rightSymbol: item.rightSymbol,
      leftCoinId: item.leftCoinId,
      rightCoinId: item.rightCoinId,
      type: interval,
    });

    setTimeout(() => {
      setwebSocketReset(true);

      if (loginData && loginData.id) {
        getCoinBalance(item.leftCoinId, item.rightCoinId);
        getTradeHistory(item.leftCoinId, item.rightCoinId);
        getopentradeHistorys(item.leftCoinId, item.rightCoinId);
      }
    }, 100);

    setorderData((old) => {
      return { ...old, ["price"]: orderData.isMarket == 0 ? '' : item.price, total: '', amount: '' };
    });
  };

  const searchHandler = (e) => {
    if (!e.target.value) {
      setPairs(allPairs);
    } else {
      let result = allPairs.filter((o) =>
        o.leftSymbol.toUpperCase().includes(e.target.value.toUpperCase()) || o.leftCoin.toUpperCase().includes(e.target.value.toUpperCase())
      );

      if (result.length > 0) {
        setPairs(result);
      } else {
        setPairs([]);
        setlivePairs((old) => {
          return { ...old, ["data"]: [] };
        });
      }
    }
  };

  const tradeHandler = (e) => {
    const { id, name, value } = e.target;

    const re = /^[0-9]*(\.[0-9]{0,4})?$/;

    if (name == "amount" && (re.test(value) || value == "")) {
      setorderData((old) => {
        return {
          ...old,
          tradeType: id,
          [name]: value == "" ? "" : value,
          price: orderData.price,
          leftCoinId: selectedPairData.leftCoinId,
          rightCoinId: selectedPairData.rightCoinId,
          total:
            orderData.price == "" || value == ""
              ? 0
              : parseFloat(
                parseFloat(orderData.price) * parseFloat(value)
              ).toFixed(4),
        };
      });
    } else if (name == "total" && (re.test(value) || value == "")) {
      setorderData((old) => {
        return {
          ...old,
          tradeType: id,
          [name]: value == "" ? 0 : value,
          price: orderData.price,
          leftCoinId: selectedPairData.leftCoinId,
          rightCoinId: selectedPairData.rightCoinId,
          amount:
            value == "" || orderData.price == ""
              ? 0
              : parseFloat(
                parseFloat(value) / parseFloat(orderData.price)
              ).toFixed(6),
        };
      });
    } else if (name == "price" && (re.test(value) || value == "")) {
      setorderData((old) => {
        return {
          ...old,
          tradeType: id,
          [name]: value == "" ? "" : value,
          leftCoinId: selectedPairData.leftCoinId,
          rightCoinId: selectedPairData.rightCoinId,
          total:
            orderData.amount == "" || value == ""
              ? 0
              : parseFloat(
                parseFloat(orderData.amount) *
                parseFloat(value == "" ? 0 : value)
              ).toFixed(4),
        };
      });
    }
  };

  useEffect(() => {
    setorderData((old) => {
      return {
        ...old,
        price: orderData.isMarket == 1 ? selectedPairData.price : '',
        amount: '',
        total: '',
      };
    });
  }, [orderData.isMarket]);

  const placeOrder = async (e, type) => {
    e.preventDefault();
    orderData["tradeType"] = type;

    if (parseFloat(orderData.amount) <= 0 || !orderData.amount) {
      toast.error("Amount should be greater than 0");
      return;
    }

    if (parseFloat(orderData.price) <= 0 || !orderData.price) {
      toast.error("Price should be greater than 0");
      return;
    }

    if (parseFloat(orderData.total) <= 0 || !orderData.total) {
      toast.error("Total amount should be greater than 0");
      return;
    }

    let res = await placeOrderAPI(orderData);

    if (res.success) {
      toast.success(res.msg);
      setorderData((old) => {
        return { ...old, ["total"]: 0, ["amount"]: 0, ["price"]: orderData.isMarket == 0 ? 0 : orderData.price };
      });
      getCoinBalance(selectedPairData.leftCoinId, selectedPairData.rightCoinId);
      getTradeHistory(
        selectedPairData.leftCoinId,
        selectedPairData.rightCoinId
      );
      getopentradeHistorys(
        selectedPairData.leftCoinId,
        selectedPairData.rightCoinId
      );
    } else {
      toast.error(res.msg);
    }
  };
  const [initialValue, setInitialValue] = useState(0);
  const barChange = (e) => {
    setInitialValue(e.target.value);
    const totalusdt =
      (parseFloat(selectedPairData.rightCoinBalance) *
        parseFloat(e.target.value)) /
      100;
    const amount = parseFloat(
      parseFloat(totalusdt) / parseFloat(orderData.price)
    ).toFixed(4);
    setorderData((old) => {
      return {
        ...old,
        ["total"]:
          totalusdt == "Infinity" || totalusdt == "NaN" ? 0 : totalusdt,
        ["amount"]: amount == "Infinity" || amount == "NaN" ? 0 : amount,
      };
    });
  };

  const cancelOrder = async (e, id) => {
    let res = await cancelOrderAPI({ orderId: id });
    if (res.success) {
      toast.success(res.msg);
      getCoinBalance(selectedPairData.leftCoinId, selectedPairData.rightCoinId);
      getTradeHistory(
        selectedPairData.leftCoinId,
        selectedPairData.rightCoinId
      );
      getopentradeHistorys(
        selectedPairData.leftCoinId,
        selectedPairData.rightCoinId
      );
    } else {
      toast.error(res.msg);
    }
  };

  useEffect(() => {
    getGraphData({
      leftSymbol: selectedPairData.leftSymbol,
      rightSymbol: selectedPairData.rightSymbol,
      leftCoinId: selectedPairData.leftCoinId,
      rightCoinId: selectedPairData.rightCoinId,
      type: interval,
    });

    setTimeout(() => {
      setchartUpdate(true);
    }, 1000);
  }, [graphType]);

  const changeGraphType = (value) => {
    setdepthChartData([]);
    setchartData([]);
    setchartUpdate(false);
    setgraphType(value);
  };

  const graphMouseMove = () => {

    let tooltips = Array.from(document.querySelectorAll(".tooltipddd"));

    if (tooltips && tooltips.length > 1) {
      // Remove the last element from the array
      tooltips.pop();

      // Iterate over the remaining elements and remove them from their parent nodes
      tooltips.forEach(node => {
        // console.log('Processing node:', node);
        if (node && node.parentNode && node.parentNode.contains(node)) {
          try {
            console.log('Removing node:');
            node.parentNode.removeChild(node);
          } catch (e) {
            console.error('Failed to remove node:');
          }
        } else {
          console.warn('Node or its parent is not found:');
        }
      });
    }

    // setgraphMove(true);
  };

  const withourgraphMouseMove = () => {

    let tooltips = Array.from(document.querySelectorAll(".tooltipddd"));

    if (tooltips && tooltips.length > 1) {
      // Remove the last element from the array
      tooltips.pop();

      // Iterate over the remaining elements and remove them from their parent nodes
      tooltips.forEach(node => {
        // console.log('Processing node:', node);
        if (node && node.parentNode && node.parentNode.contains(node)) {
          try {
            console.log('Removing node:');
            node.parentNode.removeChild(node);
          } catch (e) {
            console.error('Failed to remove node:');
          }
        } else {
          console.warn('Node or its parent is not found:');
        }
      });
    }

    setgraphMove(false);
  };


  const selectMobileInterval = (e) => {
    setIntervals(e.target.value)
  }

  return (
    <>
      <div  >
        <Toaster />
        {pairs && pairs.length > 0 ? (
          <Websocket
            url="wss://stream.binance.com:9443/ws/!ticker@arr"
            onMessage={livePairDataFromBinance}
          />
        ) : (
          ""
        )}

        {!webSocketReset ? (
          ""
        ) : (
          ""
        )}

        {!webSocketReset ? (
          ""
        ) : (
         ""
        )}
        <LoginHeader />
        <div className="exchange__main" onMouseMove={withourgraphMouseMove}>
          <div className="exchange__details">
            <div className="d-flex">
              <div className="exchange__box">
                <div className="exchange__line">
                  <div className="exchange__info text-white" translate="no">

                    {selectedPairData?.leftSymbol ? selectedPairData.leftSymbol : '--'}
                    /
                    {selectedPairData.rightSymbol}
                  </div>

                </div>
                <div className="exchange__content" translate="no">{selectedPairData.name}</div>
              </div>
              {/* <div className="pairDropdown d-lg-none d-md-none d-block mb-4">
                <Dropdown className="">
                  <Dropdown.Toggle variant="success" id="dropdown-basic">
                    <span><FaChevronDown /></span>
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">
                      <div className="pairTable">
                        <div className=" table-responsive">
                          <table class="table">
                            <thead>
                              <tr>
                                <th>Pair</th>
                                <th>Percentage</th>
                                <th>Price</th>
                              </tr>
                            </thead>
                            <tbody>
                              {loadingStatus == true ?
                                <tr>
                                  <td className="text-center" colSpan="4" style={{ color: "#fff" }}>
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    Loading...
                                  </td></tr> :

                                livepairs.data &&
                                  livepairs.data.length > 0 ? (
                                  livepairs.data.map((item) => {
                                    return (
                                      <tr className="active">
                                        <td
                                          onClick={(e) => {
                                            selectCoin(e, item);
                                            setInitialValue(0);
                                          }}
                                        >
                                          <div
                                            className="currencies__info"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                          >
                                            {item.leftSymbol}
                                            <span>
                                              /{item.rightSymbol}
                                            </span>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div
                                            className="currencies__col"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) => {
                                              selectCoin(e, item);
                                              setInitialValue(0);
                                            }}
                                          >
                                            <div
                                              className={
                                                item.changePercentage > 0
                                                  ? "currencies__positive"
                                                  : "currencies__negative"
                                              }
                                            >
                                              {item.changePercentage}%
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          {" "}
                                          <div
                                            translate="no"
                                            className="currencies__col"
                                            style={{
                                              cursor: "pointer",
                                            }}
                                            onClick={(e) => {
                                              selectCoin(e, item);
                                              setInitialValue(0);
                                            }}
                                          >
                                            ${parseFloat(item.price).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                          </div>{" "}
                                        </td>
                                      </tr>
                                    );
                                  })
                                ) : (
                                  <tr className="active">
                                    <td
                                      colSpan="4"
                                      className="text-center"
                                    >
                                    </td>
                                  </tr>
                                )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div> */}

              <div className="pairDropdown d-lg-none d-md-none d-block">
                <Button variant="primary" onClick={handleShow}>
                  <span><FaChevronDown /></span>
                </Button>

                <Offcanvas show={show} onHide={handleClose} backdrop="static" className="w-100" style={{ backgroundColor: "#17181b" }}>
                  <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                  </Offcanvas.Header>
                  <Offcanvas.Body>
                    <div className="pairTable h-100">
                      <div className=" table-responsive">
                        <table class="table">
                          <thead>
                            <tr>
                              <th>Pair</th>
                              <th>Percentage</th>
                              <th>Price</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loadingStatus == true ?
                              <tr>
                                <td className="text-center" colSpan="4" style={{ color: "#fff" }}>
                                  <Spinner
                                    as="span"
                                    animation="grow"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                  />
                                  Loading...
                                </td></tr> :

                              livepairs.data &&
                                livepairs.data.length > 0 ? (
                                livepairs.data.map((item) => {
                                  return (
                                    <tr className="active">
                                      <td
                                        onClick={(e) => {
                                          selectCoin(e, item);
                                          handleClose()
                                          setInitialValue(0);
                                        }}
                                      >
                                        <div
                                          className="currencies__info"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                        >
                                          {item.leftSymbol}
                                          <span>
                                            /{item.rightSymbol}
                                          </span>
                                        </div>
                                      </td>
                                      <td>
                                        {" "}
                                        <div
                                          className="currencies__col"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) => {
                                            selectCoin(e, item);
                                            setInitialValue(0);
                                          }}
                                        >
                                          <div
                                            className={
                                              item.changePercentage > 0
                                                ? "currencies__positive"
                                                : "currencies__negative"
                                            }
                                          >
                                            {item.changePercentage}%
                                          </div>
                                        </div>
                                      </td>
                                      <td>
                                        {" "}
                                        <div
                                          translate="no"
                                          className="currencies__col"
                                          style={{
                                            cursor: "pointer",
                                          }}
                                          onClick={(e) => {
                                            selectCoin(e, item);
                                            setInitialValue(0);
                                          }}
                                        >
                                          ${parseFloat(item.price).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                        </div>{" "}
                                      </td>
                                    </tr>
                                  );
                                })
                              ) : (
                                <tr className="active">
                                  <td
                                    colSpan="4"
                                    className="text-center"
                                  >
                                  </td>
                                </tr>
                              )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Offcanvas.Body>
                </Offcanvas>
              </div>

            </div>

            <div className="exchange__box">
              <div className="exchange__price" translate="no">
                {selectedPairData?.price ? parseFloat(selectedPairData.price).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "--"} {" "} USDT{" "}
              </div>
              <div className="exchange__content" translate="no">
                <svg className="icon icon-coin">
                  <use xlinkHref="#icon-coin" />
                </svg>
                {selectedPairData?.price ? parseFloat(selectedPairData.price).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "--"}/USDT
              </div>
            </div>
          </div>

          <div className="exchange__list">
            <div className="exchange__item">
              <div className="exchange__subtitle">
                <span>
                  <MdOutlineAccessTime className="updownArrow me-2" />
                </span>
                24h change
              </div>
              <div className="exchange__content" translate="no">
                {(!selectedPairData?.changePercentage || selectedPairData.changePercentage == 'undefined') ? '--' : selectedPairData?.changePercentage}%
              </div>
            </div>
            <div className="exchange__item">
              <div className="exchange__subtitle">
                <span>
                  <IoIosArrowRoundUp className="updownArrow" />
                </span>
                24h high
              </div>
              <div translate="no" className="exchange__content">{(!selectedPairData?.high || isNaN(selectedPairData.high)) ? "--" : parseFloat(selectedPairData.high).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
            </div>
            <div className="exchange__item">
              <div className="exchange__subtitle">
                <span>
                  <IoIosArrowRoundDown className="updownArrow" />
                </span>
                24h low
              </div>
              <div translate="no" className="exchange__content">{(!selectedPairData?.low || isNaN(selectedPairData.low)) ? "--" : parseFloat(selectedPairData.low).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
            </div>
            <div className="exchange__item">
              <div className="exchange__subtitle">
                <span>
                  <VscGraph className="updownArrow me-2" />
                </span>
                24h volume
              </div>
              <div translate="no" className="exchange__content">{(!selectedPairData?.volume || isNaN(selectedPairData.low)) ? '--' : parseFloat(selectedPairData.volume).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}</div>
            </div>
          </div>
        </div>
        <div className="outer__inner p-2">
          <section className="mainExchange ">
            <Container fluid className="p-0">
              <Row >
                <Col xl={9} lg={8} md={12} xs={12}>
                  <Row className="flex-lg-row flex-md-row flex-column-reverse">
                    <Col translate="no"
                      xl={4}
                      lg={4}
                      md={12}
                      xs={12}
                      onMouseMove={withourgraphMouseMove}
                    >
                      <div className="pairtableBox assetstable d-lg-block d-md-block d-none">
                        <form>
                          <div className="searchbox mb-3">
                            <span>
                              <CiSearch className="searchIcon" />
                            </span>
                            <input
                              type="search"
                              className="form-control"
                              onChange={(e) => searchHandler(e)}
                              placeholder="Search"
                            />
                          </div>
                        </form>

                        <div className="pairtabsBox mt-3">
                          <Row>
                            <Col
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              className=""
                            >
                              <Tabs
                                defaultActiveKey="usdt"
                                id="uncontrolled-tab-example"
                                className="pair_tabs mb-3 border-0 d-none"
                              >
                                <Tab eventKey="usdt" title="">
                                  <div className="pairTable">
                                    <div className=" table-responsive">
                                      <table class="table">
                                        <thead>
                                          <tr>
                                            <th>Pair</th>
                                            <th>Percentage</th>
                                            <th>Price</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {loadingStatus == true ?
                                            <tr>
                                              <td className="text-center" colSpan="4" style={{ color: "#fff" }}>
                                                <Spinner
                                                  as="span"
                                                  animation="grow"
                                                  size="sm"
                                                  role="status"
                                                  aria-hidden="true"
                                                />
                                                Loading...
                                              </td></tr> :

                                            livepairs.data &&
                                              livepairs.data.length > 0 ? (
                                              livepairs.data.map((item) => {
                                                return (
                                                  <tr className="active">
                                                    <td
                                                      onClick={(e) => {
                                                        selectCoin(e, item);
                                                        setInitialValue(0);
                                                      }}
                                                    >
                                                      <div
                                                        className="currencies__info"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                      >
                                                        {item.leftSymbol}
                                                        <span>
                                                          /{item.rightSymbol}
                                                        </span>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      <div
                                                        className="currencies__col"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={(e) => {
                                                          selectCoin(e, item);
                                                          setInitialValue(0);
                                                        }}
                                                      >
                                                        <div
                                                          className={
                                                            item.changePercentage > 0
                                                              ? "currencies__positive"
                                                              : "currencies__negative"
                                                          }
                                                        >
                                                          {item.changePercentage}%
                                                        </div>
                                                      </div>
                                                    </td>
                                                    <td>
                                                      {" "}
                                                      <div
                                                        translate="no"
                                                        className="currencies__col"
                                                        style={{
                                                          cursor: "pointer",
                                                        }}
                                                        onClick={(e) => {
                                                          selectCoin(e, item);
                                                          setInitialValue(0);
                                                        }}
                                                      >
                                                        ${parseFloat(item.price).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                                      </div>{" "}
                                                    </td>
                                                  </tr>
                                                );
                                              })
                                            ) : (
                                              <tr className="active">
                                                <td
                                                  colSpan="4"
                                                  className="text-center"
                                                >
                                                </td>
                                              </tr>
                                            )}
                                        </tbody>
                                      </table>
                                    </div>
                                  </div>
                                </Tab>
                              </Tabs>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Col>
                    <Col xl={8} lg={8} md={12} xs={12} className="mb-lg-0 mb-md-0 mb-4">
                      <Row>
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          xs={12}
                          className="px-lg-0 px-md-0"
                        >
                          <div className="exchange__col" translate="no">
                            <div className="charts js-tabs js-exchange-item js-exchange-chart">
                              <div className="charts__head" onMouseMove={withourgraphMouseMove}>
                                <div className="charts__group">
                                  <select onChange={e => selectMobileInterval(e)} className="select" >
                                    <option>Time</option>

                                    <option value={5}>1M</option>
                                    <option value={4}>1W</option>
                                    <option value={3}>1H</option>
                                    <option value={2}>30m</option>
                                    <option value={1}>1m</option>
                                  </select>
                                  <div className="nav">
                                    <button className="nav__link ">Time</button>
                                    <button
                                      className={
                                        interval == 5
                                          ? "nav__link active"
                                          : "nav__link"
                                      }
                                      onClick={(e) => setIntervals(5)}
                                    >
                                      1M
                                    </button>
                                    <button
                                      className={
                                        interval == 4
                                          ? "nav__link active"
                                          : "nav__link"
                                      }
                                      onClick={(e) => setIntervals(4)}
                                    >
                                      1W
                                    </button>
                                    <button
                                      className={
                                        interval == 3
                                          ? "nav__link active"
                                          : "nav__link"
                                      }
                                      onClick={(e) => setIntervals(3)}
                                    >
                                      1H
                                    </button>
                                    <button
                                      className={
                                        interval == 2
                                          ? "nav__link active"
                                          : "nav__link"
                                      }
                                      onClick={(e) => setIntervals(2)}
                                    >
                                      30m
                                    </button>
                                    <button
                                      className={
                                        interval == 1
                                          ? "nav__link active"
                                          : "nav__link"
                                      }
                                      onClick={(e) => setIntervals(1)}
                                    >
                                      1m
                                    </button>
                                  </div>
                                </div>
                                <div className="nav">
                                  <button
                                    className={
                                      graphType == 1
                                        ? "nav__link js-tabs-link active"
                                        : "nav__link js-tabs-link"
                                    }
                                    onClick={(e) => changeGraphType(1)}
                                  >
                                    Trading view
                                  </button>
                                  <button
                                    className={
                                      graphType == 2
                                        ? "nav__link js-tabs-link active"
                                        : "nav__link js-tabs-link"
                                    }
                                    onClick={(e) => changeGraphType(2)}
                                  >
                                    Depth
                                  </button>
                                </div>
                              </div>

                              {graphType == 1 &&
                                chartUpdate == true &&
                                chartData &&
                                chartData.length > 0 ? (
                                <div
                                  translate="no"
                                  ref={chartContainerRef}
                                  onMouseMove={graphMouseMove}
                                  style={{
                                    width: "100%",
                                    height: "488px",
                                    backgroundColor: "#000",
                                  }}
                                />
                              ) : chartUpdate == true &&
                                graphType == 2 &&
                                depthChartData?.buy &&
                                depthChartData?.buy.length > 0 ? (
                                <div className="depthchart">
                                  <DepthChart
                                    onMouseMove={graphMouseMove}
                                    data={depthChartData}
                                  />{" "}
                                </div>
                              ) : chartUpdate == false ? (
                                <div className="loarderBox " style={{
                                  width: "100%",
                                  height: "488px",
                                  backgroundColor: "#000",
                                }}>
                                  <Button
                                    variant="primary"
                                    className="loaderbtn"
                                    disabled
                                  >
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    Loading...
                                  </Button>
                                </div>
                              ) : (
                                <div className="loarderBox">
                                  <Button
                                    variant="primary"
                                    className="loaderbtn"
                                    disabled
                                  >
                                    <Spinner
                                      as="span"
                                      animation="grow"
                                      size="sm"
                                      role="status"
                                      aria-hidden="true"
                                    />
                                    Loading...
                                  </Button>
                                </div>
                              )}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col xl={3} lg={4} md={12} xs={12}>
                  <Row>
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      xs={12}
                      onMouseMove={withourgraphMouseMove}
                    >
                      <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="">
                          <div className="pairtableBox">
                            <div className="pairtabsBox limitDiv mt-3">
                              <Row className="">
                                <Col
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  className=""
                                >
                                  <Button
                                    variant={
                                      orderData.isMarket == 0
                                        ? "custombtn7"
                                        : "custombtn8"
                                    }
                                    className="px-2 w-100"
                                    onClick={(e) => {
                                      setorderData((old) => {
                                        return { ...old, ["isMarket"]: 0 };
                                      });
                                      setInitialValue(0);
                                    }}
                                  >
                                    Limit
                                  </Button>
                                </Col>

                                <Col
                                  xl={6}
                                  lg={6}
                                  md={6}
                                  sm={6}
                                  xs={6}
                                  className=""
                                >
                                  <Button
                                    variant={
                                      orderData.isMarket == 1
                                        ? "custombtn7"
                                        : "custombtn8"
                                    }
                                    className="px-2 w-100"
                                    onClick={(e) => {
                                      setorderData((old) => {
                                        return { ...old, ["isMarket"]: 1 };
                                      });
                                      setInitialValue(0);
                                    }}
                                  >
                                    Market
                                  </Button>
                                </Col>
                              </Row>
                            </div>
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl={12} lg={12} md={12} xs={12}>
                          <div className="buyselltabsBox ">
                            <Row>
                              <Col
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                className=""
                              >
                                <Tabs
                                  defaultActiveKey="buy"
                                  id="uncontrolled-tab-example"
                                  className="buysell_tabs mb-3 border-0"
                                >
                                  <Tab eventKey="buy" title="Buy">
                                    {loginData && loginData.id ? (
                                      <div className="bargaining__fieldset">
                                        <div className="availabelhead mb-3">
                                          <h6 translate="no">
                                            <span>
                                              <CiWallet className="walletIcons" />
                                            </span>
                                            {selectedPairData?.leftCoinBalance ? parseFloat(
                                              selectedPairData.leftCoinBalance
                                            ).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}{" "}
                                            {selectedPairData.leftSymbol}
                                          </h6>
                                          <h6 translate="no">
                                            <span>
                                              <CiWallet className="walletIcons" />
                                            </span>
                                            {selectedPairData?.rightCoinBalance ? parseFloat(
                                              selectedPairData.rightCoinBalance
                                            ).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,') : "0"}{" "}
                                            USDT
                                          </h6>
                                        </div>
                                        <div className="bargaining__field mb-3">
                                          <input
                                            className="bargaining__input"
                                            type="tel"
                                            name="price"
                                            placeholder="Price"
                                            autocomplete="off"
                                            id="BUY"
                                            disabled={orderData.isMarket == 1}
                                            onChange={(e) => tradeHandler(e)}
                                            value={orderData.price}
                                          />
                                          <div className="bargaining__currency">
                                            USDT
                                          </div>
                                        </div>
                                        <div className="bargaining__field mb-3">
                                          <input
                                            className="bargaining__input"
                                            type="tel"
                                            autocomplete="off"
                                            id="BUY"
                                            placeholder="Amount"
                                            name="amount"
                                            value={orderData.amount}
                                            onChange={(e) => tradeHandler(e)}
                                          />
                                          <div className="bargaining__currency">
                                            {selectedPairData.leftSymbol}
                                          </div>
                                        </div>

                                        <div className="rangeBox mb-2">
                                          <div className="rangelableBox">
                                            <div className="rangepercent">
                                              <Form.Label className="textWhite">
                                                0%
                                              </Form.Label>
                                            </div>
                                            <div className="rangepercent">
                                              <Form.Label className="textWhite">
                                                25%
                                              </Form.Label>
                                            </div>
                                            <div className="rangepercent">
                                              <Form.Label className="textWhite">
                                                50%
                                              </Form.Label>
                                            </div>
                                            <div className="rangepercent">
                                              <Form.Label className="textWhite">
                                                75%
                                              </Form.Label>
                                            </div>
                                            <div className="rangepercent">
                                              <Form.Label className="textWhite">
                                                100%
                                              </Form.Label>
                                            </div>
                                          </div>
                                          <Form.Range
                                            min={0}
                                            max={100}
                                            step={25}
                                            value={initialValue} // Set your initial value here
                                            onChange={(e) => barChange(e)}
                                          />
                                        </div>

                                        <div className="bargaining__field mb-3">
                                          <div className="bargaining__label">
                                            Total
                                          </div>
                                          <input
                                            className="bargaining__input"
                                            type="tel"
                                            name="total"
                                            id="BUY"
                                            autocomplete="off"
                                            onChange={(e) => tradeHandler(e)}
                                            value={
                                              orderData.tradeType == "BUY" ||
                                                orderData.tradeType == undefined
                                                ? parseFloat(orderData.total) > 0
                                                  ? parseFloat(
                                                    orderData.total
                                                  ).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')
                                                  : 0
                                                : 0
                                            }
                                          />
                                          <div className="bargaining__currency">
                                            {selectedPairData.rightSymbol}
                                          </div>
                                        </div>

                                        <Row className="mt-5">
                                          <Col
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={6}
                                            className=""
                                          >
                                            <Button
                                              disabled={settingCount.trade == 0}
                                              variant="custombtn5"
                                              className="px-2 w-100"
                                              onClick={(e) => placeOrder(e, "BUY")}
                                            >
                                              Buy/Long
                                            </Button>
                                          </Col>

                                          <Col
                                            xl={6}
                                            lg={6}
                                            md={6}
                                            sm={6}
                                            xs={6}
                                            className=""
                                          >
                                            <Button
                                              disabled={settingCount.trade == 0}
                                              variant="custombtn6"
                                              className="px-2 w-100"
                                              onClick={(e) => placeOrder(e, "SELL")}
                                            >
                                              Sell/Short
                                            </Button>
                                          </Col>
                                        </Row>
                                      </div>
                                    ) : (
                                      <div className="">
                                        <div className="buysellbtnBox">
                                          <Col
                                            xl={9}
                                            lg={9}
                                            md={5}
                                            sm={12}
                                            xs={12}
                                            className="mx-auto py-5"
                                          >
                                            <Link to={`${config.baseUrl}sign-in`}>
                                              <Button
                                                variant="custombtn1"
                                                className="px-2 w-100"
                                              >

                                                {" "}
                                                LOGIN

                                              </Button>
                                            </Link>
                                            <p className=" my-3 text-center">Or</p>
                                            <Link to={`${config.baseUrl}sign-up`}>
                                              <Button
                                                variant="custombtn2"
                                                className="px-2 w-100"
                                              >

                                                {" "}
                                                CREATE AN ACCOUNT

                                              </Button>
                                            </Link>
                                          </Col>
                                        </div>
                                      </div>
                                    )}
                                  </Tab>
                                </Tabs>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>

              </Row>

              <Row onMouseMove={withourgraphMouseMove}>
                <Col xl={12} lg={12} md={12} xs={12} className="pt-2">
                  <div className="pairtableBox tradehistorydiv">
                    <div className="pairtabsBox mt-3">
                      <Row>
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="mx-auto"
                        >
                          <Tabs
                            defaultActiveKey="openrders2"
                            id="uncontrolled-tab-example"
                            className="order_tabs mb-3 border-0"
                          >
                            <Tab
                              eventKey="openrders2"
                              title={`Open Orders(${tradeHistory?.open.length})`}
                            >
                              <div className="">
                                <div className=" table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th>
                                          Date{" "}
                                          <span>
                                            <HiMiniChevronUpDown className="dropIcon" />
                                          </span>
                                        </th>
                                        <th scope="col">ID</th>
                                        <th scope="col">Pair</th>
                                        <th scope="col">
                                          Amount({selectedPairData.leftSymbol})
                                        </th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tradeHistory.open &&
                                        tradeHistory.open.length > 0 ? (
                                        tradeHistory.open.map((item) => {
                                          return (
                                            <tr>
                                              <td>
                                                {moment(item.datetime).format(
                                                  "LLL"
                                                )}
                                              </td>
                                              <td>{item.id}</td>
                                              <td>
                                                {item.leftSymbol}/
                                                {item.rightSymbol}
                                              </td>
                                              <td translate="no">
                                                {parseFloat(
                                                  item.quantity
                                                ).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                              </td>
                                              <td translate="no">
                                                {parseFloat(item.price).toFixed(
                                                  2
                                                ).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                              </td>
                                              <td translate="no">
                                                {parseFloat(
                                                  item.quantity * item.price
                                                ).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                              </td>
                                              <td>
                                                <div className="buysellBtn">
                                                  <Button
                                                    variant="custombtn1"
                                                    className="px-4"
                                                    onClick={(e) =>
                                                      cancelOrder(e, item.id)
                                                    }
                                                  >
                                                    Cancel{" "}
                                                  </Button>
                                                </div>
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan="8"
                                            className="text-center"
                                          >
                                            No Open Order Found
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Tab>
                            <Tab eventKey="tradehistory" title="Trade history">
                              <div className="">
                                <div className=" table-responsive">
                                  <table class="table">
                                    <thead>
                                      <tr>
                                        <th>
                                          Date{" "}
                                          <span>
                                            <HiMiniChevronUpDown className="dropIcon" />
                                          </span>
                                        </th>
                                        <th scope="col">ID</th>
                                        <th scope="col">Pair</th>
                                        <th scope="col">
                                          Amount({selectedPairData.leftSymbol})
                                        </th>
                                        <th scope="col">Price</th>
                                        <th scope="col">Total</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {tradeHistory.complete &&
                                        tradeHistory.complete.length > 0 ? (
                                        tradeHistory.complete.map((item) => {
                                          return (
                                            <tr>
                                              <td>
                                                {moment(item.datetime).format(
                                                  "LLL"
                                                )}
                                              </td>
                                              <td>{item.id}</td>
                                              <td>
                                                {item.leftSymbol}/
                                                {item.rightSymbol}
                                              </td>
                                              <td>
                                                {parseFloat(
                                                  item.quantity
                                                ).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                              </td>
                                              <td translate="no">
                                                {parseFloat(item.price).toFixed(
                                                  2
                                                ).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                              </td>
                                              <td translate="no">
                                                {parseFloat(
                                                  item.quantity * item.price
                                                ).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                                              </td>
                                            </tr>
                                          );
                                        })
                                      ) : (
                                        <tr>
                                          <td
                                            colSpan="8"
                                            className="text-center"
                                          >
                                            No Trade Found
                                          </td>
                                        </tr>
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </Tab>
                          </Tabs>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </div>
      </div>

      <Footer />

    </>
  );
};
export default Exchangenew;
