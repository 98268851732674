import React, { Component, useEffect, useState } from "react";
import LoginHeader from "../directives/header";
import Footer from "../directives/footer";


const Notification = () => {



    return (
        <>
            <div className="outer">
                <LoginHeader />
                <div className="outer__inner">
                    <div className="notifications">
                        <div className="notifications__center center">
                            <div className="notifications__body">
                                <div className="notifications__top">
                                    <h4 className="notifications__title h4">Notifications</h4>
                                    <button className="button-stroke button-small notifications__button">
                                        Mark all as read
                                    </button>
                                    <button className="button-stroke button-small notifications__button">
                                        Clear all
                                    </button>
                                </div>
                                <div className="notifications__row">
                                    <button className="button-small notifications__toggle">
                                        Advanced filter
                                    </button>
                                    <div className="notifications__filters">
                                        <div className="notifications__info">Filters</div>
                                        <div className="notifications__group">
                                            <label className="checkbox">
                                                <input
                                                    className="checkbox__input"
                                                    type="checkbox"
                                                    defaultChecked="checked"
                                                />
                                                <span className="checkbox__inner">
                                                    <span className="checkbox__tick" />
                                                    <span className="checkbox__text">Security</span>
                                                </span>
                                            </label>
                                            <label className="checkbox">
                                                <input className="checkbox__input" type="checkbox" />
                                                <span className="checkbox__inner">
                                                    <span className="checkbox__tick" />
                                                    <span className="checkbox__text">Wallet</span>
                                                </span>
                                            </label>
                                            <label className="checkbox">
                                                <input className="checkbox__input" type="checkbox" />
                                                <span className="checkbox__inner">
                                                    <span className="checkbox__tick" />
                                                    <span className="checkbox__text">Trade</span>
                                                </span>
                                            </label>
                                            <label className="checkbox">
                                                <input
                                                    className="checkbox__input"
                                                    type="checkbox"
                                                    defaultChecked="checked"
                                                />
                                                <span className="checkbox__inner">
                                                    <span className="checkbox__tick" />
                                                    <span className="checkbox__text">Deposit</span>
                                                </span>
                                            </label>
                                            <label className="checkbox">
                                                <input className="checkbox__input" type="checkbox" />
                                                <span className="checkbox__inner">
                                                    <span className="checkbox__tick" />
                                                    <span className="checkbox__text">Transder</span>
                                                </span>
                                            </label>
                                            <label className="checkbox">
                                                <input
                                                    className="checkbox__input"
                                                    type="checkbox"
                                                    defaultChecked="checked"
                                                />
                                                <span className="checkbox__inner">
                                                    <span className="checkbox__tick" />
                                                    <span className="checkbox__text">Withdrawals</span>
                                                </span>
                                            </label>
                                            <label className="checkbox">
                                                <input
                                                    className="checkbox__input"
                                                    type="checkbox"
                                                    defaultChecked="checked"
                                                />
                                                <span className="checkbox__inner">
                                                    <span className="checkbox__tick" />
                                                    <span className="checkbox__text">News</span>
                                                </span>
                                            </label>
                                        </div>
                                        <div className="notifications__control">
                                            <button className="button-stroke button-small notifications__button">
                                                Select all
                                            </button>
                                            <button className="button-stroke button-small notifications__button">
                                                Unslect all
                                            </button>
                                        </div>
                                    </div>
                                    <div className="notifications__wrapper">
                                        <div className="notifications__list">
                                            <div className="notifications__item active">
                                                <div className="notifications__icon">
                                                    <svg className="icon icon-lightning">
                                                        <use xlinkHref="#icon-lightning" />
                                                    </svg>
                                                </div>
                                                <div className="notifications__details">
                                                    <div className="notifications__line">
                                                        <div className="notifications__subtitle">
                                                            Login attempted from new IP
                                                        </div>
                                                        <div className="notifications__date">
                                                            2021-03-10 20:19:15
                                                        </div>
                                                        <div className="notifications__status" />
                                                    </div>
                                                    <div className="notifications__content">
                                                        Hello, you've reset the Google Authentication on your
                                                        account successfully. Your old security items have expired
                                                        and new security items have now been enabled.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="notifications__item active">
                                                <div className="notifications__icon">
                                                    <svg className="icon icon-wallet">
                                                        <use xlinkHref="#icon-wallet" />
                                                    </svg>
                                                </div>
                                                <div className="notifications__details">
                                                    <div className="notifications__line">
                                                        <div className="notifications__subtitle">
                                                            Login attempted from new IP
                                                        </div>
                                                        <div className="notifications__date">
                                                            2021-03-10 20:19:15
                                                        </div>
                                                        <div className="notifications__status" />
                                                    </div>
                                                    <div className="notifications__content">
                                                        Hello, you've reset the Google Authentication on your
                                                        account successfully. Your old security items have expired
                                                        and new security items have now been enabled.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="notifications__item active">
                                                <div className="notifications__icon">
                                                    <svg className="icon icon-coin">
                                                        <use xlinkHref="#icon-coin" />
                                                    </svg>
                                                </div>
                                                <div className="notifications__details">
                                                    <div className="notifications__line">
                                                        <div className="notifications__subtitle">
                                                            Login attempted from new IP
                                                        </div>
                                                        <div className="notifications__date">
                                                            2021-03-10 20:19:15
                                                        </div>
                                                        <div className="notifications__status" />
                                                    </div>
                                                    <div className="notifications__content">
                                                        Hello, you've reset the Google Authentication on your
                                                        account successfully. Your old security items have expired
                                                        and new security items have now been enabled.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="notifications__item">
                                                <div className="notifications__icon">
                                                    <svg className="icon icon-lightning">
                                                        <use xlinkHref="#icon-lightning" />
                                                    </svg>
                                                </div>
                                                <div className="notifications__details">
                                                    <div className="notifications__line">
                                                        <div className="notifications__subtitle">
                                                            Login attempted from new IP
                                                        </div>
                                                        <div className="notifications__date">
                                                            2021-03-10 20:19:15
                                                        </div>
                                                        <div className="notifications__status" />
                                                    </div>
                                                    <div className="notifications__content">
                                                        Hello, you've reset the Google Authentication on your
                                                        account successfully. Your old security items have expired
                                                        and new security items have now been enabled.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="notifications__item">
                                                <div className="notifications__icon">
                                                    <svg className="icon icon-wallet">
                                                        <use xlinkHref="#icon-wallet" />
                                                    </svg>
                                                </div>
                                                <div className="notifications__details">
                                                    <div className="notifications__line">
                                                        <div className="notifications__subtitle">
                                                            Login attempted from new IP
                                                        </div>
                                                        <div className="notifications__date">
                                                            2021-03-10 20:19:15
                                                        </div>
                                                        <div className="notifications__status" />
                                                    </div>
                                                    <div className="notifications__content">
                                                        Hello, you've reset the Google Authentication on your
                                                        account successfully. Your old security items have expired
                                                        and new security items have now been enabled.
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="notifications__item">
                                                <div className="notifications__icon">
                                                    <svg className="icon icon-coin">
                                                        <use xlinkHref="#icon-coin" />
                                                    </svg>
                                                </div>
                                                <div className="notifications__details">
                                                    <div className="notifications__line">
                                                        <div className="notifications__subtitle">
                                                            Login attempted from new IP
                                                        </div>
                                                        <div className="notifications__date">
                                                            2021-03-10 20:19:15
                                                        </div>
                                                        <div className="notifications__status" />
                                                    </div>
                                                    <div className="notifications__content">
                                                        Hello, you've reset the Google Authentication on your
                                                        account successfully. Your old security items have expired
                                                        and new security items have now been enabled.
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="notifications__btns">
                                            <button className="button-stroke button-small notifications__button">
                                                <span>Load more</span>
                                                <svg className="icon icon-calendar">
                                                    <use xlinkHref="#icon-calendar" />
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="post">
                                <div className="post__list">
                                    <div className="post__item">
                                        <div
                                            className="post__preview"
                                            style={{ backgroundColor: "#E4D7CF" }}
                                        >
                                            <img
                                                srcSet="img/content/actions-pic-1@2x.png 2x"
                                                src="img/content/actions-pic-1.png"
                                                alt="Post"
                                            />
                                        </div>
                                        <div className="post__details">
                                            <div className="post__title">Bank deposit</div>
                                            <div className="post__content">
                                                Deposit crypto or cash currency to your wallet and start trading
                                                on the world largest exchange!
                                            </div>
                                            <a
                                                className="button-stroke button-small post__button"
                                                href="deposit-fiat.html"
                                            >
                                                Bank deposit
                                            </a>
                                        </div>
                                    </div>
                                    <div className="post__item">
                                        <div
                                            className="post__preview"
                                            style={{ backgroundColor: "#CDB4DB" }}
                                        >
                                            <img
                                                srcSet="img/content/actions-pic-2@2x.png 2x"
                                                src="img/content/actions-pic-2.png"
                                                alt="Post"
                                            />
                                        </div>
                                        <div className="post__details">
                                            <div className="post__title">Transfer</div>
                                            <div className="post__content">
                                                Internal transfers are free on Bitcloud.
                                            </div>
                                            <a
                                                className="button-stroke button-small post__button js-popup-open"
                                                href="#popup-transfer"
                                                data-effect="mfp-zoom-in"
                                            >
                                                Transfer coin
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popup popup_transfer mfp-hide" id="popup-transfer">
                        <div className="popup__title h4">
                            <svg className="icon icon-arrow-left">
                                <use xlinkHref="#icon-arrow-left" />
                            </svg>
                            Transfer
                        </div>
                        <div className="popup__wrap">
                            <div className="popup__category">
                                Available <br />
                                balance
                            </div>
                            <div className="popup__details">
                                <div className="popup__currency">2.56213968 ETH</div>
                                <div className="popup__price">$10,095.35</div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="field__label">from</div>
                            <div className="field__wrap">
                                <select className="select">
                                    <option>Margin</option>
                                    <option>Fiat and Spot</option>
                                </select>
                            </div>
                        </div>
                        <div className="popup__sign">
                            <svg className="icon icon-arrows">
                                <use xlinkHref="#icon-arrows" />
                            </svg>
                        </div>
                        <div className="field">
                            <div className="field__label">to</div>
                            <div className="field__wrap">
                                <select className="select">
                                    <option>Fiat and Spot</option>
                                    <option>Margin</option>
                                </select>
                            </div>
                        </div>
                        <div className="field">
                            <div className="field__label">coin</div>
                            <div className="field__wrap">
                                <select className="select">
                                    <option>ETH Ethereum</option>
                                    <option>BTC Bitcoin</option>
                                    <option>TRX Tron</option>
                                </select>
                            </div>
                        </div>
                        <div className="field field_button">
                            <div className="field__label">Amount to transfer</div>
                            <div className="field__wrap">
                                <input
                                    className="field__input"
                                    type="text"
                                    name="amount"
                                    required="required"
                                />
                                <button className="button-stroke button-small field__button">
                                    Max amount
                                </button>
                            </div>
                            <div className="field__note">2.14646231064565653 ETH available</div>
                        </div>
                        <button className="button popup__button">Transfer</button>
                    </div>
                </div>






                <Footer />
            </div>
        </>
    );
};
export default Notification;
