import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Tabs, Tab, Form, NavDropdown, Dropdown, Modal } from 'react-bootstrap';

import toast, { Toaster } from "react-hot-toast";
import '../components/componentCss/profile.css'
import config from "../coreFiles/config";
import { coinListAPI, depositRequest } from '../coreFiles/action'

import { Link } from 'react-router-dom';

import 'react-image-upload/dist/index.css'
import LoginHeader from "../directives/header";
import '../components/componentCss/rewardhistory.css'
import { IoChevronBackOutline } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { BsCalendarRange } from "react-icons/bs";
import { HiMiniChevronUpDown } from "react-icons/hi2";
import Footer from '../directives/footer';
import { IoIosAdd } from "react-icons/io";
import '../components/componentCss/ticket.css'
import { GrAttachment } from "react-icons/gr";





const Ticket = () => {

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [coins, setCoins] = useState([])
    const [depositForm, setdepositForm] = useState({ amount: 0, currencyId: 1, receipt: '' })

    function getImageFileObject(imageFile) {
        let file = { imageFile }
        setdepositForm((old) => {
            return { ...old, ['receipt']: file.imageFile.file }
        })
    }

    function runAfterImageDelete(file) {
        console.log({ file })
    }

    useEffect(() => {
        getCoins()
    }, [])

    const getCoins = async () => {
        let res = await coinListAPI()
        if (res.success) {
            setCoins(res.data)
        }
    }

    const handler = (e) => {
        const { name, value } = e.target
        setdepositForm((old) => {
            return { ...old, [name]: value }
        })
    }

    const submitForm = async (e) => {
        let res = await depositRequest(depositForm)
        if (res.success) {
            toast.success(res.msg);
            setdepositForm({ amount: 0, currencyId: 1, receipt: '' })
        }
    }


    return (
        <>
            <LoginHeader />


            <Toaster />


            <div>
                <Container className=''>
                    <Row>
                        <Col xl={6} lg={6} md={6} xs={6} className="">
                            <div className='backArrowhead mt-lg-5 mt-md-5 mt-3'>
                                <span className='me-3'><Link to={`${config.baseUrl}`}> <span><IoChevronBackOutline /></span></Link></span><h1 className='mb-0'>Ticket Management</h1>
                            </div>
                        </Col>
                        <Col xl={6} lg={6} md={6} xs={6} className="d-flex align-items-center justify-content-end">
                            <Button
                                variant="custombtn1" onClick={handleShow}
                                className="px-4 mt-lg-5 mt-md-5 mt-3 "
                            >
                                <span><IoIosAdd className='addIcons' /></span>Raise Ticket
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </div>

            <section className='ticket_sec pt-lg-5 pt-md-5 pt-3 pb-5 '>
                <Container className=''>
                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="pt-3">
                            <div className=" rewardshistoyTable">
                                <div className=" table-responsive">
                                    <table class="table">
                                        <thead>
                                            <tr>
                                                <th>Ticket No <span><HiMiniChevronUpDown className="dropIcon" /></span></th>
                                                <th>Title <span><HiMiniChevronUpDown className="dropIcon" /></span></th>
                                                <th>Message <span><HiMiniChevronUpDown className="dropIcon" /></span></th>
                                                <th>Date <span><HiMiniChevronUpDown className="dropIcon" /></span></th>
                                                <th>Ticket Status <span><HiMiniChevronUpDown className="dropIcon" /></span></th>
                                                <th>Action <span><HiMiniChevronUpDown className="dropIcon" /></span></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="active">
                                                <td>
                                                    <div>
                                                        <Link>2291952</Link>
                                                    </div>
                                                </td>
                                                <td>ticket </td>
                                                <td>test1 </td>
                                                <td>25-04-24 </td>
                                                <td>
                                                    <div>
                                                        <Link className='maincolor2'>Open</Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <Link to={`${config.baseUrl}supportchat`}>
                                                            <Button
                                                                variant="custombtn1" onClick={handleShow}
                                                                className="px-4"
                                                            >
                                                                Chat
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="">
                                                <td>
                                                    <div>
                                                        <Link>8159553</Link>
                                                    </div>
                                                </td>
                                                <td>p </td>
                                                <td>p </td>
                                                <td>25-04-24 </td>
                                                <td>
                                                    <div>
                                                        <Link className='maincolor2'>Open</Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <Link to={`${config.baseUrl}supportchat`}>
                                                            <Button
                                                                variant="custombtn1" onClick={handleShow}
                                                                className="px-4"
                                                            >
                                                                Chat
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="">
                                                <td>
                                                    <div>
                                                        <Link>8762891</Link>
                                                    </div>
                                                </td>
                                                <td>Hello admin </td>
                                                <td>I am new user </td>
                                                <td>25-04-24 </td>
                                                <td>
                                                    <div>
                                                        <Link className='maincolor2'>Open</Link>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div>
                                                        <Link to={`${config.baseUrl}supportchat`}>
                                                            <Button
                                                                variant="custombtn1" onClick={handleShow}
                                                                className="px-4"
                                                            >
                                                                Chat
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </td>
                                            </tr>


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container >
            </section >




            {/*------------------ Raise Ticket Modal Start ------------------ */}



            <div className='raiseModal'>
                <Modal show={show} onHide={handleClose}
                    aria-labelledby="contained-modal-title-vcenter" className="modalBox"
                    centered >
                    <Modal.Header closeButton>
                        <Modal.Title>Raise A Ticket</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Row>
                            <Col xl={12} lg={12} md={12} xs={12} className="mt-4">
                                <div className="field field_view mb-3">
                                    <div className="field__label">Title</div>
                                    <div className="field__wrap">
                                        <input
                                            className="field__input"
                                            type="text"
                                            name="old_password"
                                            placeholder="Enter Title"
                                            required=""
                                        />
                                        <button className="field__view">

                                        </button>
                                    </div>
                                </div>

                                <div className="field field_view mb-3">
                                    <div className="field__label">Message</div>
                                    <textarea
                                        className="field__input h-auto"
                                        id="exampleFormControlTextarea1" placeholder='Type your message here'
                                        rows={5}
                                    />
                                </div>

                                <div className='attachFile'>
                                    <Link to="#"><span><GrAttachment /></span>Attach File</Link>
                                </div>
                                <div className='text-end'>
                                    <Button variant="custombtn1" className="px-4" type="submit">
                                        Raise Ticket
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </div>



            {/*------------------ Raise Ticket Modal Exit ------------------ */}




            <Footer />

        </>
    )
}

export default Ticket;
