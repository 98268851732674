import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Tabs, Tab, Form, NavDropdown, Dropdown } from 'react-bootstrap';

import toast, { Toaster } from "react-hot-toast";
import '../components/componentCss/profile.css'
import config from "../coreFiles/config";
import { coinListAPI, depositRequest } from '../coreFiles/action'

import { Link } from 'react-router-dom';

import 'react-image-upload/dist/index.css'
import LoginHeader from "../directives/header";
import '../components/componentCss/rewardhistory.css'
import { IoChevronBackOutline } from "react-icons/io5";
import { CiSearch } from "react-icons/ci";
import { BsCalendarRange } from "react-icons/bs";
import { HiMiniChevronUpDown } from "react-icons/hi2";
import Footer from '../directives/footer';
import '../components/componentCss/supportchat.css'
// import { FaLongArrowAltRight } from "react-icons/fa";
import { BsFillSendFill } from "react-icons/bs";

import { Widget } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';


const Supportchat = () => {

    const [coins, setCoins] = useState([])
    const [depositForm, setdepositForm] = useState({ amount: 0, currencyId: 1, receipt: '' })

    function getImageFileObject(imageFile) {
        let file = { imageFile }
        setdepositForm((old) => {
            return { ...old, ['receipt']: file.imageFile.file }
        })
    }

    function runAfterImageDelete(file) {
        console.log({ file })
    }

    useEffect(() => {
        getCoins()
    }, [])

    const getCoins = async () => {
        let res = await coinListAPI()
        if (res.success) {
            setCoins(res.data)
        }
    }

    const handler = (e) => {
        const { name, value } = e.target
        setdepositForm((old) => {
            return { ...old, [name]: value }
        })
    }

    const submitForm = async (e) => {
        let res = await depositRequest(depositForm)
        if (res.success) {
            toast.success(res.msg);
            setdepositForm({ amount: 0, currencyId: 1, receipt: '' })
        }
    }



    return (
        <>
            <LoginHeader />


            <Toaster />


            <div>
                <Container className=''>
                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="">
                            <div className='backArrowhead mt-lg-5 mt-md-5 mt-3'>
                                <span className='me-3'><Link to={`${config.baseUrl}`}> <span><IoChevronBackOutline /></span></Link></span><h1 className='mb-0'>Support Chat</h1>
                            </div>

                            <div className='ticket_num pt-4'>
                                <h5>Ticket Number: <span>8762891</span></h5>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>



            <section className='supprtchat_sec pt-5 pb-5'>
                <Container className=''>
                    <Row className=''>
                        <Col xl={12} lg={12} md={12} xs={12} className="">
                            <div className="chatForm">
                                <div className='chatContent'>
                                    {/* <div className='chatLeft'>
                                        <div className='chatBlock1'></div>
                                    </div>
                                    <div className='chatlRight'>
                                        <div className='chatBlock2'>
                                            <p>I am new user</p>
                                        </div>
                                        <div className='datetime'>
                                            09-April-2024 18:53:57
                                        </div>
                                    </div> */}


                                    <div className='msger-chat'>

                                        <div className='msg right-msg'>
                                            <div className='msg-bubble'>
                                                <div className='msg-info d-none'></div>
                                                <div className='msg-text'>
                                                    I am new user
                                                </div>
                                            </div>
                                            <div className='dateTime'>
                                                09-April-2024 18:53:57
                                            </div>
                                        </div>

                                        <div className='msg left-msg'>
                                            <div className='msg-bubble'>
                                                <div className='msg-info d-none'></div>
                                                <div className='msg-text'>
                                                    Hello Pawan
                                                </div>
                                            </div>
                                            <div className='dateTime'>
                                                09-April-2024 22:14:10
                                            </div>
                                        </div>
                                        <div className='msg left-msg'>
                                            <div className='msg-bubble'>
                                                <div className='msg-info d-none'></div>
                                                <div className='msg-text'>
                                                    Are you there?
                                                </div>
                                            </div>
                                            <div className='dateTime'>
                                                09-April-2024 22:14:10
                                            </div>
                                        </div>
                                        <div className='msg left-msg'>
                                            <div className='msg-bubble'>
                                                <div className='msg-info d-none'></div>
                                                <div className='msg-text'>
                                                    Hi, welcome to SimpleChat! Go ahead and send me a message.
                                                </div>
                                            </div>
                                            <div className='dateTime'>
                                                09-April-2024 22:14:10
                                            </div>
                                        </div>
                                        <div className='msg left-msg'>
                                            <div className='msg-bubble'>
                                                <div className='msg-info d-none'></div>
                                                <div className='msg-text'>
                                                    Hi, welcome to SimpleChat! Go ahead and send me a message.
                                                </div>
                                            </div>
                                            <div className='dateTime'>
                                                09-April-2024 22:14:10
                                            </div>
                                        </div>
                                        <div className='msg left-msg'>
                                            <div className='msg-bubble'>
                                                <div className='msg-info d-none'></div>
                                                <div className='msg-text'>
                                                    Hi, welcome to SimpleChat! Go ahead and send me a message.
                                                </div>
                                            </div>
                                            <div className='dateTime'>
                                                09-April-2024 22:14:10
                                            </div>
                                        </div>
                                        <div className='msg left-msg'>
                                            <div className='msg-bubble'>
                                                <div className='msg-info d-none'></div>
                                                <div className='msg-text'>
                                                    Hi, welcome to SimpleChat! Go ahead and send me a message.
                                                </div>
                                            </div>
                                            <div className='dateTime'>
                                                09-April-2024 22:14:10
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div>
                                <form class="subscription">
                                    <input class="subscription__input" type="email" name="email" placeholder="Type a message" required="required" />
                                    <button class="subscription__btn">
                                        <BsFillSendFill className="subsIcon" />
                                    </button>
                                </form>
                            </div>

                        </Col>
                    </Row>
                </Container >
            </section >




            <Footer />

        </>
    )
}

export default Supportchat;
