import React from 'react';
import { Container, Row, Col, Button, Tabs, Tab, Form, NavDropdown, Dropdown } from 'react-bootstrap';
import { FaCopy } from "react-icons/fa";
import '../components/componentCss/profile.css'
import copy from "copy-to-clipboard";



const Profile2fa = (props) => {

    const nextScreen = () => {
        props.nextScreen(7)
    }

    const copyToClipboard = (e, code) => {
        copy(`${code}`);
        alert(`You have copied ${code}`);
    }

    return (
        <>

            <Col xl={8} lg={8} md={12} xs={12} className="pt-lg-5 pt-md-5 pt-3">
                <div className="profileContent p-lg-5 p-md-5 p-3 pt-4 mt-lg-0 mt-md-0 mt-4">
                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="">
                            <div className='profileqrcode'>
                                <h2 className='mb-4'>2FA<span className='ms-1'>{props.enabled == 1 ? "Enabled" : "Disabled"}</span></h2>
                                <p>1. Open the Google Authenticate app and scan this QR code to {props.enabled == 1 ? "Disable" : "Enable"} 2FA</p>
                                <div className='qrcodeBox text-center py-5'>
                                    <img src={props.QRcode} className="qrcodeImg" />
                                </div>
                                <div className='text-center mb-4'>
                                    <p className='mb-1'>Or enter the following code manually:</p>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <p className='mb-0'>{props.google_auth_code}</p><FaCopy onClick={e => copyToClipboard(e, props.google_auth_code)} className='copyicons ms-2' />
                                    </div>
                                </div>

                                <p>2. Once Spacebit is registered, you'll start seeing 6-digit verification codes in the app</p>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={2} lg={2} md={2} xs={12} className="mt-3 mx-auto text-center">
                            <Button
                                variant="custombtn1"
                                className="px-4 w-100"
                                onClick={e => nextScreen(e)}
                            >
                                Next
                            </Button>
                        </Col>
                    </Row>

                </div>
            </Col>

        </>
    )
}

export default Profile2fa;
