import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LoginHeader from "../directives/header";
import Footer from "../directives/footer";
import '../components/componentCss/profile.css'
import config from "../coreFiles/config";
import { FiChevronRight } from "react-icons/fi";
import { Link, useNavigate } from 'react-router-dom';

import { getprofileDetail } from '../coreFiles/action'
import ProfileDetail from './profileDetail';
import ProfileTransfer from './profiletransfer';
import Profile2fa from './profile2fa';
import ProfileEdit from './profileEdit';
import Deposit from './deposit';
import Changepassword from './changepassword';
import Profile2faOne from './profile2faOne';
import Profile2fasecond from './profile2fasecond';
import Profile2fathird from './profile2fathird';
import Withdraw from './withdraw';
import Referral from './referral';
import ReferralHistory from './referralHistory';
import RewardHistory from './rewardHistory';
import toast, { Toaster } from "react-hot-toast";





const Profile = () => {
    const navigate = useNavigate();
    const [profile, setProfile] = useState({})
    const [screenType, setscreenType] = useState(1)

    const editProfile = () => {
        setscreenType(2)
    }

    useEffect(() => {
        getDetail()
        if (window.location.pathname == '/Deposit') {
            setscreenType(3)
        }
        if (window.location.pathname == '/Withdraw') {
            setscreenType(4)
        }
        if (window.location.pathname == '/2faauthentication') {
            setscreenType(6)
        }
        if (window.location.pathname == '/editprofile') {
            setscreenType(2)
        }
        if (window.location.pathname == '/referral') {
            setscreenType(10)
        }
        if (window.location.pathname == '/changepassword') {
            setscreenType(12)
        }

    }, [])

    const getDetail = async () => {
        let res = await getprofileDetail()
        if (res.success) {
            setProfile(res.data)
        }
    }

    const nextScreen = (value) => {

        getDetail()
        setscreenType(value)

        if (value == 3) {
            navigate(`${config.baseUrl}Deposit`);
        }
        if (value == 4) {
            navigate(`${config.baseUrl}Withdraw`);
        }
        if (value == 6) {
            navigate(`${config.baseUrl}2faauthentication`);
        }
        if (value == 2) {
            navigate(`${config.baseUrl}editprofile`);
        }
        if (value == 10) {
            navigate(`${config.baseUrl}referral`);
        }
        if (value == 12) {
            navigate(`${config.baseUrl}changepassword`);
        }
    }

    const changeTabUrl = (value) => {
        if (value == 1) {
            window.location.href = `${config.baseUrl}profile`
        }
        if (value == 3) {
            window.location.href = `${config.baseUrl}Deposit`
        }
        if (value == 4) {
            window.location.href = `${config.baseUrl}Withdraw`
        }
        if (value == 6) {
            window.location.href = `${config.baseUrl}2faauthentication`
        }
        if (value == 10) {
            window.location.href = `${config.baseUrl}referral`
        }
        if (value == 12) {
            window.location.href = `${config.baseUrl}changepassword`
        }

        setscreenType(value)
    }

    return (
        <>

            <LoginHeader />
            <Toaster />
            <section className='profile_sec pt-lg-5 pt-md-5 pt-3 pb-5'>
                <Container>
                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="">
                            <div className='headMenubox pb-4'>
                                <Row>
                                    <Col xl={6} lg={6} md={6} xs={6} className="">
                                        <h1 className='text-white'>Profile Info</h1>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} xs={6} className="d-flex align-items-center justify-content-end ">
                                        <div className="headtopMenu">
                                            <p>Home</p>
                                            <span><FiChevronRight className="ledtchevIcon" /></span>
                                            <h6>{window.location.pathname == '/Withdraw' ? "Withdraw" : window.location.pathname == '/Deposit' ? "Deposit" : window.location.pathname == '/2faauthentication' ? "2FA" : window.location.pathname == '/editprofile' ? "Edit Profile" : window.location.pathname == '/referral' ? "Referral" : window.location.pathname == '/changepassword' ? "Change password" : "Profile Info"}</h6>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col xl={4} lg={4} md={12} xs={12} className="pt-lg-5 pt-md-5 pt-0 ">
                            <div className='prosideMenu pe-lg-5 pe-md-5 pe-0'>
                                <ul>
                                    <li className={screenType == 1 || screenType == 2 ? 'active' : ''} onClick={e => changeTabUrl(1)}>
                                        <Link className=""  >
                                            <span>
                                                {/* <LuUser2 /> */}
                                                <img src='assets/icons/p-userIcon.svg' className='promenuIcons' />
                                            </span>
                                            <span style={{ color: screenType == 1 || screenType == 2 ? '#fff' : '' }}>

                                                Profile
                                            </span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link className={screenType == 3 ? 'active' : ''} onClick={e => changeTabUrl(3)} >
                                            <span>
                                                {/* <IoBagHandleOutline /> */}
                                                <img src='assets/icons/p-depositIcon.svg' className='promenuIcons' />
                                            </span>
                                            <span style={{ color: screenType == 3 ? '#fff' : '' }}>
                                                Deposit
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={screenType == 4 ? 'active' : ''} onClick={e => changeTabUrl(4)}>
                                            <span>
                                                {/* <FaArrowUpRightFromSquare /> */}
                                                {/* <TbArrowAutofitUp /> */}
                                                <img src='assets/icons/p-withdrawIcon.svg' className='promenuIcons' />
                                            </span>
                                            <span style={{ color: screenType == 4 ? '#fff' : '' }}>
                                                Withdraw

                                            </span>
                                        </Link>
                                    </li>
                                    <li style={{ display: "none" }}>
                                        <Link className={screenType == 5 ? 'active' : ''} onClick={e => changeTabUrl(5)}>
                                            <span>
                                                {/* <IoIosArrowRoundForward className='transArrowicon'/> */}
                                                {/* <GoArrowRight /> */}
                                                <img src='assets/icons/p-transferIcon.svg' className='promenuIcons' />
                                            </span>
                                            Transfer
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={screenType == 6 ? 'active' : ''} onClick={e => changeTabUrl(6)}>
                                            <span>
                                                {/* <PiBatteryFullDuotone /> */}
                                                <img src='assets/icons/p-2faIcon.svg' className='promenuIcons' />
                                            </span>
                                            <span style={{ color: screenType == 6 ? '#fff' : '' }}>

                                                2FA
                                            </span>
                                        </Link>
                                    </li>

                                    <li>
                                        <Link className={screenType == 8 ? 'active' : ''} onClick={e => changeTabUrl(10)}>
                                            <span>
                                                <img src='assets/icons/refferalIcon.svg' className='refferalIcon' />
                                            </span>
                                            <span style={{ color: screenType == 10 ? '#fff' : '' }}>

                                                Referrals
                                            </span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className={screenType == 7 ? 'active' : ''} onClick={e => changeTabUrl(12)}>
                                            <span>
                                                {/* <SlLock /> */}
                                                <img src='assets/icons/p-lockIcon.svg' className='promenuIcons' />
                                            </span>
                                            <span style={{ color: screenType == 12 ? '#fff' : '' }}>

                                                Change password
                                            </span>
                                        </Link>
                                    </li>


                                </ul>
                            </div>
                        </Col>
                        {screenType == 1 ? < ProfileDetail modalEdit={editProfile} /> :
                            screenType == 2 ? <ProfileEdit /> :
                                screenType == 3 ? <Deposit /> :
                                    screenType == 4 ? <Withdraw /> :
                                        screenType == 6 ? <Profile2fa nextScreen={nextScreen} QRcode={profile.QR_code} google_auth_code={profile.google_auth_code} enabled={profile['2fa_enabled']} /> :
                                            screenType == 7 ? <Profile2faOne nextScreen={nextScreen} enabled={profile['2fa_enabled']} /> :
                                                // screenType == 8 ? <Profile2fasecond nextScreen={nextScreen} /> :
                                                // screenType == 9 ? <Profile2fathird  nextScreen={nextScreen} /> :
                                                screenType == 10 ? <Referral nextScreen={nextScreen} code={profile.referral_code} /> :
                                                    screenType == 11 ? <ReferralHistory nextScreen={nextScreen} /> :
                                                        screenType == 12 ? <Changepassword profile={profile} /> :
                                                            screenType == 13 ? <RewardHistory nextScreen={nextScreen} /> :

                                                                ""}

                        {/* <Profile2faOne /> */}

                        {/* <Profile2fasecond /> */}

                        {/* <Profile2fathird /> */}

                        {/* <Withdraw /> */}
                        {/* <Referral /> */}


                    </Row>
                </Container>
            </section>



            <Footer />

            {/* <Dashboardfooter /> */}
        </>
    )
}

export default Profile;
