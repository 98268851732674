import React, { Component, useEffect, useState } from "react";
import LoginHeader from "../directives/header";
import Footer from "../directives/footer";


const Discover = () => {



    return (
        <>
            <div className="outer">
                <LoginHeader />
                <div className="outer__inner">
                    <div className="section discover">
                        <div className="discover__center center">
                            <div className="discover__head">
                                <h1 className="discover__title h1">Blockchain &amp; Crypto</h1>
                                <div className="discover__info">A Beginner’s Guide to TradingView</div>
                                <div className="discover__btns">
                                    <a className="button discover__button js-scroll" href="#releases">
                                        Learn now
                                    </a>
                                    <a
                                        className="button-stroke discover__button js-scroll"
                                        href="#catalog"
                                    >
                                        Video tutorial
                                    </a>
                                </div>
                            </div>
                            <div className="discover__video">
                                <img src="img/content/video-bg.jpg" alt="Video preview" />
                                <button className="play">
                                    <svg className="icon icon-play">
                                        <use xlinkHref="#icon-play" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="section-bg section-mb0 releases">
                        <div className="releases__anchor anchor" id="releases" />
                        <div className="releases__center center">
                            <div className="releases__head">
                                <div className="stage-small releases__stage">Our Blog</div>
                                <div className="releases__wrap">
                                    <h2 className="releases__title h2">Latest Releases</h2>
                                    <div className="releases__info">
                                        Stacks is a production-ready library of stackable content blocks
                                        built in React Native.
                                    </div>
                                    <div className="nav">
                                        <button className="nav__link active">Bitcoin</button>
                                        <button className="nav__link">Blockchain</button>
                                        <button className="nav__link">Tutorials</button>
                                    </div>
                                    <select className="select">
                                        <option>Bitcoin</option>
                                        <option>Blockchain</option>
                                        <option>Tutorials</option>
                                    </select>
                                </div>
                            </div>
                            <div className="releases__list">
                                <a className="releases__item" href="learn-crypto-details.html">
                                    <div className="releases__preview">
                                        <img
                                            srcSet="img/content/releases-pic-1@2x.jpg 2x"
                                            src="img/content/releases-pic-1.jpg"
                                            alt="Releases"
                                        />
                                    </div>
                                    <div className="releases__details">
                                        <div className="category-red releases__category">new</div>
                                        <h4 className="releases__subtitle h4">
                                            A Beginner's Guide to TradingView
                                        </h4>
                                        <div className="releases__currency">Ethereum</div>
                                        <div className="releases__content">
                                            A fully-featured landing page kit, including design files, and
                                            beautiful 3D illustrations editable.
                                        </div>
                                    </div>
                                    <div className="releases__arrow">
                                        <svg className="icon icon-arrow-next">
                                            <use xlinkHref="#icon-arrow-next" />
                                        </svg>
                                    </div>
                                </a>
                                <a className="releases__item" href="learn-crypto-details.html">
                                    <div className="releases__preview">
                                        <img
                                            srcSet="img/content/releases-pic-2@2x.jpg 2x"
                                            src="img/content/releases-pic-2.jpg"
                                            alt="Releases"
                                        />
                                    </div>
                                    <div className="releases__details">
                                        <div className="category-green releases__category">beginner</div>
                                        <h4 className="releases__subtitle h4">
                                            What Is Crypto Market Sentiment?
                                        </h4>
                                        <div className="releases__currency">Ethereum</div>
                                        <div className="releases__content">
                                            A fully-featured landing page kit, including design files, and
                                            beautiful 3D illustrations editable.
                                        </div>
                                    </div>
                                    <div className="releases__arrow">
                                        <svg className="icon icon-arrow-next">
                                            <use xlinkHref="#icon-arrow-next" />
                                        </svg>
                                    </div>
                                </a>
                                <a className="releases__item" href="learn-crypto-details.html">
                                    <div className="releases__preview">
                                        <img
                                            srcSet="img/content/releases-pic-3@2x.jpg 2x"
                                            src="img/content/releases-pic-3.jpg"
                                            alt="Releases"
                                        />
                                    </div>
                                    <div className="releases__details">
                                        <div className="category-red releases__category">new</div>
                                        <h4 className="releases__subtitle h4">
                                            What Is the Ethereum Hard Fork?
                                        </h4>
                                        <div className="releases__currency">Ethereum</div>
                                        <div className="releases__content">
                                            A fully-featured landing page kit, including design files, and
                                            beautiful 3D illustrations editable.
                                        </div>
                                    </div>
                                    <div className="releases__arrow">
                                        <svg className="icon icon-arrow-next">
                                            <use xlinkHref="#icon-arrow-next" />
                                        </svg>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="section-padding section-mb0 catalog">
                        <div className="catalog__anchor anchor" id="catalog" />
                        <div className="catalog__center center">
                            <form className="catalog__form">
                                <input
                                    className="catalog__input"
                                    type="text"
                                    name="search"
                                    placeholder="Search anything about crypto"
                                    required=""
                                />
                                <button className="button-circle catalog__result">
                                    <svg className="icon icon-search">
                                        <use xlinkHref="#icon-search" />
                                    </svg>
                                </button>
                            </form>
                            <div className="catalog__sorting">
                                <select className="select">
                                    <option>Recently added</option>
                                    <option>Long added</option>
                                </select>
                                <div className="nav">
                                    <button className="nav__link active">Tutorial</button>
                                    <button className="nav__link">Usecase</button>
                                    <button className="nav__link">Trading</button>
                                    <button className="nav__link">Wallet</button>
                                </div>
                                <div className="tablet-show">
                                    <select className="select">
                                        <option>Tutorial</option>
                                        <option>Usecase</option>
                                        <option>Trading</option>
                                        <option>Wallet</option>
                                    </select>
                                </div>
                            </div>
                            <div className="catalog__list">
                                <a className="card" href="learn-crypto-details.html">
                                    <div className="card__preview">
                                        <img
                                            srcSet="img/content/article-pic-1@2x.jpg 2x"
                                            src="img/content/article-pic-1.jpg"
                                            alt="Card preview"
                                        />
                                        <div className="category-purple card__category">video tutorial</div>
                                    </div>
                                    <div className="card__body">
                                        <div className="card__avatar" />
                                        <div className="card__details">
                                            <div className="card__title">Top 7 NFT usecase</div>
                                            <div className="card__author">First name Last name</div>
                                        </div>
                                        <div className="category-stroke-green card__status">popular</div>
                                    </div>
                                </a>
                                <a className="card" href="learn-crypto-details.html">
                                    <div className="card__preview">
                                        <img
                                            srcSet="img/content/article-pic-2@2x.jpg 2x"
                                            src="img/content/article-pic-2.jpg"
                                            alt="Card preview"
                                        />
                                        <div className="category card__category">popular</div>
                                    </div>
                                    <div className="card__body">
                                        <div className="card__avatar" />
                                        <div className="card__details">
                                            <div className="card__title">Top 7 NFT usecase</div>
                                            <div className="card__author">First name Last name</div>
                                        </div>
                                        <div className="category-stroke-green card__status">popular</div>
                                    </div>
                                </a>
                                <a className="card" href="learn-crypto-details.html">
                                    <div className="card__preview">
                                        <img
                                            srcSet="img/content/article-pic-3@2x.jpg 2x"
                                            src="img/content/article-pic-3.jpg"
                                            alt="Card preview"
                                        />
                                        <div className="category-green card__category">new</div>
                                    </div>
                                    <div className="card__body">
                                        <div className="card__avatar" />
                                        <div className="card__details">
                                            <div className="card__title">Top 7 NFT usecase</div>
                                            <div className="card__author">First name Last name</div>
                                        </div>
                                        <div className="category-stroke-green card__status">popular</div>
                                    </div>
                                </a>
                                <a className="card" href="learn-crypto-details.html">
                                    <div className="card__preview">
                                        <img
                                            srcSet="img/content/article-pic-4@2x.jpg 2x"
                                            src="img/content/article-pic-4.jpg"
                                            alt="Card preview"
                                        />
                                        <div className="category-purple card__category">video tutorial</div>
                                        <button className="play play_small">
                                            <svg className="icon icon-play">
                                                <use xlinkHref="#icon-play" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="card__body">
                                        <div className="card__avatar" />
                                        <div className="card__details">
                                            <div className="card__title">Top 7 NFT usecase</div>
                                            <div className="card__author">First name Last name</div>
                                        </div>
                                        <div className="category-stroke-green card__status">popular</div>
                                    </div>
                                </a>
                                <a className="card" href="learn-crypto-details.html">
                                    <div className="card__preview">
                                        <img
                                            srcSet="img/content/article-pic-5@2x.jpg 2x"
                                            src="img/content/article-pic-5.jpg"
                                            alt="Card preview"
                                        />
                                        <div className="category-purple card__category">video tutorial</div>
                                        <button className="play play_small">
                                            <svg className="icon icon-play">
                                                <use xlinkHref="#icon-play" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="card__body">
                                        <div className="card__avatar" />
                                        <div className="card__details">
                                            <div className="card__title">Top 7 NFT usecase</div>
                                            <div className="card__author">First name Last name</div>
                                        </div>
                                        <div className="category-stroke-green card__status">popular</div>
                                    </div>
                                </a>
                                <a className="card" href="learn-crypto-details.html">
                                    <div className="card__preview">
                                        <img
                                            srcSet="img/content/article-pic-6@2x.jpg 2x"
                                            src="img/content/article-pic-6.jpg"
                                            alt="Card preview"
                                        />
                                        <div className="category-blue card__category">featured</div>
                                    </div>
                                    <div className="card__body">
                                        <div className="card__avatar" />
                                        <div className="card__details">
                                            <div className="card__title">Top 7 NFT usecase</div>
                                            <div className="card__author">First name Last name</div>
                                        </div>
                                        <div className="category-stroke-green card__status">popular</div>
                                    </div>
                                </a>
                            </div>
                            <div className="catalog__btns">
                                <button className="button-stroke button-small catalog__button">
                                    <span>Learn more</span>
                                    <svg className="icon icon-arrow-down">
                                        <use xlinkHref="#icon-arrow-down" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="section-bg section-mb0 news">
                        <div className="news__center center">
                            <div className="news__head">
                                <div className="news__logo">
                                    <img className="some-icon" src="img/logo-light.svg" alt="BitCloud" />
                                    <img
                                        className="some-icon-dark"
                                        src="img/logo-dark.svg"
                                        alt="BitCloud"
                                    />
                                </div>
                                <h2 className="news__title h2">
                                    Stay in the know on crypto with BitCloud
                                </h2>
                                <div className="news__info">
                                    A creative agency that lead and inspire
                                </div>
                            </div>
                            <div className="news__wrapper">
                                <div className="news__slider js-slider-news">
                                    <div className="news__item">
                                        <div className="news__preview">
                                            <img
                                                srcSet="img/content/news-pic-1@2x.png 2x"
                                                src="img/content/news-pic-1.png"
                                                alt="Card"
                                            />
                                        </div>
                                        <div className="news__wrap">
                                            <div className="stage-small news__stage">crypto news</div>
                                            <div className="news__subtitle">
                                                Be Part of our Global Community
                                            </div>
                                            <div className="news__content">
                                                Let’s stay in touch. Join our communities to keep up with the
                                                BitCloud team and our traders from across the world.
                                            </div>
                                            <a
                                                className="button-stroke button-small news__button"
                                                href="learn-crypto-details.html"
                                            >
                                                Join now
                                            </a>
                                        </div>
                                    </div>
                                    <div className="news__item">
                                        <div className="news__preview">
                                            <img
                                                srcSet="img/content/news-pic-1@2x.png 2x"
                                                src="img/content/news-pic-1.png"
                                                alt="Card"
                                            />
                                        </div>
                                        <div className="news__wrap">
                                            <div className="stage-small news__stage">crypto news</div>
                                            <div className="news__subtitle">
                                                Be Part of our Global Community
                                            </div>
                                            <div className="news__content">
                                                Let’s stay in touch. Join our communities to keep up with the
                                                BitCloud team and our traders from across the world.
                                            </div>
                                            <a
                                                className="button-stroke button-small news__button"
                                                href="learn-crypto-details.html"
                                            >
                                                Join now
                                            </a>
                                        </div>
                                    </div>
                                    <div className="news__item">
                                        <div className="news__preview">
                                            <img
                                                srcSet="img/content/news-pic-1@2x.png 2x"
                                                src="img/content/news-pic-1.png"
                                                alt="Card"
                                            />
                                        </div>
                                        <div className="news__wrap">
                                            <div className="stage-small news__stage">crypto news</div>
                                            <div className="news__subtitle">
                                                Be Part of our Global Community
                                            </div>
                                            <div className="news__content">
                                                Let’s stay in touch. Join our communities to keep up with the
                                                BitCloud team and our traders from across the world.
                                            </div>
                                            <a
                                                className="button-stroke button-small news__button"
                                                href="learn-crypto-details.html"
                                            >
                                                Join now
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>




                <Footer />
            </div>
        </>
    );
};
export default Discover;
