import {
  getRequest,
  postRequest,
  // putRequest,
  // deleteRequest,
  postRequestFormData,
} from "./helper";



export const getWebContentAction = (data) => {
  return getRequest('getWebContent', data).then(res => { return res.data })
}

export const userregister = (data) => {
  return postRequest('userregistration', data).then(res => { return res.data })
}

export const login = (data) => {
  return postRequest('login', data).then(res => { return res.data })
}

export const countryList = (data) => {
  return getRequest('countryList', data).then(res => { return res.data })
}

export const addSubscriberAction = (data) => {
  return postRequest('addSubscriber', data).then(res => { return res.data })
}

export const forgotPasswordAction= (data) => {
  return postRequest('forgotPassword', data).then(res => { return res.data })
}

export const resetAction= (data) => {
  return postRequest('resetPassword', data).then(res => { return res.data })
}




/*----------------------------------------------Exchange Pair List----------------------------------------------------------------*/

export const pairList = (data) => {
  return postRequest('pairList', data).then(res => { return res.data })
}

export const getBalance = (data) => {
  return getRequest('getBalance', data).then(res => { return res.data })
}

export const placeOrderAPI = (data) => {
  return postRequest('placeOrder', data).then(res => { return res.data })
}
export const cancelOrderAPI = (data) => {
  return postRequest('cancelOrder', data).then(res => { return res.data })
}


export const getCompleteTradeHistory = (data) => {
  return getRequest('completetradeHistory', data).then(res => { return res.data })
}

export const getopentradeHistory = (data) => {
  return getRequest('opentradeHistory', data).then(res => { return res.data })
}

export const getGraphDataAPI = (data) => {
  return getRequest('getGraphData', data).then(res => { return res.data })
}

export const getVirtualCoinPriceAction = (data) => {
  return getRequest('getVirtualCoinPrice', data).then(res => { return res.data })
}


/*----------------------------------------------------------------Profile API'S-------------------------------------------------------*/

export const getprofileDetail = (data) => {
  return getRequest('getuserprofile', data).then(res => { return res.data })
}

export const updateProfile = (data) => {
  return postRequestFormData('updateProfile', data).then(res => { return res.data })
}

export const coinListAPI = (data) => {
  return getRequest('coinList', data).then(res => { return res.data })
}

export const depositRequest = (data) => {
  return postRequestFormData('depositRequest', data).then(res => { return res.data })
}


export const withdrawRequest = (data) => {
  return postRequest('withdrawRequest', data).then(res => { return res.data })
}

export const twofaenableDisable = (data) => {
  return postRequest('2faenableDisable', data).then(res => { return res.data })
}

export const check2faCode = (data) => {
  return postRequest('check2faCode', data).then(res => { return res.data })
}


export const getPortFolio = (data) => {
  return getRequest('getPortfolio', data).then(res => { return res.data })
}

export const getWallet = (data) => {
  return getRequest('getWallet', data).then(res => { return res.data })
}

export const getDeposit = (data) => {
  return getRequest('getDeposit', data).then(res => { return res.data })
}

export const getWithdraw = (data) => {
  return getRequest('getWithdraw', data).then(res => { return res.data })
}

export const getReferrals = (data) => {
  return getRequest('getReferrals', data).then(res => { return res.data })
}

export const getReferralsEarning = (data) => {
  return getRequest('getReferralsEarning', data).then(res => { return res.data })
}

export const getReferralsEarningList = (data) => {
  return getRequest('getReferralsEarningList', data).then(res => { return res.data })
}





export const changepassword = (data) => {
  return postRequest('changepassword', data).then(res => { return res.data })
}

export const getWidgetListAction = (data) => {
  return getRequest('getDataForWidget', data).then(res => { return res.data })
}

export const getDashboardStatisticsUserAction = (data) => {
  return getRequest('getDashboardStatisticsUser', data).then(res => { return res.data })
}
export const getFaqListAction = (data) => {
  return getRequest('getFaq', data).then(res => { return res.data })
}

export const getHomeContetntAction = (data) => {
  return getRequest('getHomeContent', data).then(res => { return res.data })
}



// --------------------------------------------  CMS Management ---------------------------------

export const getAboutUsAction = (data) => {
  return getRequest('getAboutUs', data).then(res => { return res.data })
}

export const getPrivacyPolicyAction = (data) => {
  return getRequest('getPrivacyPolicy', data).then(res => { return res.data })
}

export const getTermsConditionAction = (data) => {
  return getRequest('getTermsandcondition', data).then(res => { return res.data })
}

export const getannouncementAction = (data) => {
  return getRequest('getAnnouncement', data).then(res => { return res.data })
}



























