import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Button,

} from "react-bootstrap";
import toast, { Toaster } from "react-hot-toast";
import "../components/componentCss/profile.css";
import config from "../coreFiles/config";
import { Link } from "react-router-dom";
import { getprofileDetail, updateProfile } from "../coreFiles/action";
import ImageUploader from "react-image-upload";
import "react-image-upload/dist/index.css";

const ProfileEdit = () => {
  const [profile, setProfile] = useState({});
  const [error, seterror] = useState([])
  const [frontbackUpload, setfrontbackUpload] = useState(false)

  function getImageFileObject(imageFile, type) {
    let file = { imageFile };
    setProfile((old) => {
      return {
        ...old,
        [type == 1 ? "frontImage" : type == 2 ? "backImage" : "profile_image"]:
          file.imageFile.file,
      };
    });
  }

  useEffect(() => {
    setfrontbackUpload(true)
  }, [typeof profile.frontImage])

  function runAfterImageDelete(file) { }

  useEffect(() => {
    getDetail();
  }, []);

  const getDetail = async () => {
    let res = await getprofileDetail();
    if (res.success) {
      setProfile(res.data);
    }
  };

  const submitForm = async (e) => {
    const isValid = validate();
    if (!isValid) {
    } else {
      let res = await updateProfile(profile);
      if (res.success) {
        getDetail();
        toast.success(res.msg);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }
  };
  const validate = () => {

    let errors = [];

    if (!profile.frontImage || profile.frontImage == 'null') {
      errors.push({ name: 'frontImage', err: "ID Front image is Required!" });
    }
    if (!profile.backImage || profile.backImage == 'null') {
      errors.push({ name: 'backImage', err: "ID Back image is Required!" });
    }

    if (!profile.documentNumber || profile.documentNumber == 'null') {
      errors.push({ name: 'documentNumber', err: "Document Number is Required!" });
    }



    if (errors.length > 0) {

      seterror(errors)

      return false;
    }
    seterror(errors)
    return true;
  }

  return (
    <>
      <Toaster />
      <Col xl={8} lg={8} md={12} xs={12} className="pt-lg-5 pt-md-5 pt-3">
        <div className="profileContent p-lg-5 p-md-5 p-3 pt-4 mt-lg-0 mt-md-0 mt-0 ">
          <Row>
            <Col xl={12} lg={12} md={12} xs={12} className="">
              <div className="profileedit">
                <h2 className=""></h2>

                <div className="login__col pt-0 pb-0 p-0">


                  <div className="login__wrap">
                    <div className="entry">
                      <div className="entry__top pb-0 border-0">
                        <h1 className="entry__title text-white">Update Profile Info</h1>
                      </div>
                      <div className="entry__form">
                        <div className="entry__tabs js-tabs">
                          <div className="entry__container">
                            <div
                              className="entry__item js-tabs-item"
                              style={{ display: "block" }}
                            >
                              <div className="field field_view mb-4">
                                <div className="field__label">User Name</div>
                                <div className="field__wrap">
                                  <input
                                    className="field__input"
                                    type="text"
                                    value={profile.username}
                                    onChange={(e) =>
                                      setProfile((old) => {
                                        return {
                                          ...old,
                                          ["username"]: e.target.value,
                                        };
                                      })
                                    }
                                    name="username"
                                    placeholder="Enter Username"
                                    required=""
                                  />
                                  <button className="field__view">

                                  </button>
                                </div>
                              </div>

                              <div className="field field_view mb-4">
                                <div className="field__label">PHONE NUMBER</div>
                                <div className="field__wrap">
                                  <input
                                    className="field__input"
                                    style={{ backgroundColor: "#343539" }}
                                    type="text"
                                    value={profile.phone}
                                    disabled={true}
                                    name="phone"
                                    placeholder="+ 84 9858886666"
                                    required=""
                                  />
                                  <button className="field__view">

                                  </button>
                                </div>
                              </div>

                              <div className="field field_view mb-4">
                                <div className="field__label">
                                  Identification Number
                                </div>
                                <div className="field__wrap">
                                  <input
                                    className="field__input"
                                    style={{
                                      backgroundColor:
                                        profile.documentNumberStatus
                                          ? "#343539"
                                          : "",
                                    }}
                                    type="text"
                                    disabled={
                                      profile.documentNumberStatus == 1
                                        ? true
                                        : false
                                    }
                                    onChange={(e) =>
                                      setProfile((old) => {
                                        return {
                                          ...old,
                                          ["documentNumber"]: e.target.value,
                                        };
                                      })
                                    }
                                    value={profile.documentNumber}
                                    name="documentNumber"
                                    placeholder="Enter Identification Number"
                                    required=""
                                  />
                                  <button className="field__view">

                                  </button>
                                </div>
                                {error.length > 0 && error[0].name == 'documentNumber' ? <div><span className='alert_validation text-danger'>{error[0].err}</span></div> : ''}

                              </div>
                              <div className="field mb-4">
                                <div className="field__label">
                                  Upload identification
                                </div>
                                <div className="uploadImgbox">
                                  <div className="text-center">
                                    <div className="uploadbox1 mb-2">
                                      {profile.frontImage == "null" ||
                                        profile.status == 2 ? (
                                        <ImageUploader
                                          style={{ backgroundImage: `url('assets/images/uploadImg1.png')` }}

                                          onFileAdded={(img) =>
                                            getImageFileObject(img, 1)
                                          }
                                          onFileRemoved={(img) =>
                                            runAfterImageDelete(img)
                                          }
                                        />
                                      ) :
                                        typeof profile.frontImage == 'object' || profile.frontImage == '' ?
                                          (
                                            <ImageUploader
                                              onFileAdded={(img) =>
                                                getImageFileObject(img, 1)
                                              }
                                              onFileRemoved={(img) =>
                                                runAfterImageDelete(img)
                                              }
                                            />
                                          ) : (
                                            <img
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                              }}
                                              src={
                                                config.imageUrl + profile.frontImage
                                              }
                                            />
                                          )}
                                      {error.length > 0 && error[0].name == 'frontImage' ? <div><span className='alert_validation text-danger'>{error[0].err}</span></div> : ''}

                                    </div>
                                    <span>Front side photo</span>
                                  </div>
                                  <div className="text-center ms-lg-4 ms-md-4">
                                    <div className="uploadbox1 mb-2">
                                      {profile.backImage == "null" ||
                                        profile.status == 2 ? (
                                        <ImageUploader
                                          style={{ backgroundImage: `url('assets/images/uploadImg1.png')` }}
                                          onFileAdded={(img) =>
                                            getImageFileObject(img, 2)
                                          }
                                          onFileRemoved={(img) =>
                                            runAfterImageDelete(img)
                                          }
                                        />
                                      ) :
                                        typeof profile.backImage == 'object' ?
                                          (
                                            <ImageUploader

                                              onFileAdded={(img) =>
                                                getImageFileObject(img, 2)
                                              }
                                              onFileRemoved={(img) =>
                                                runAfterImageDelete(img)
                                              }
                                            />
                                          ) : (
                                            <img
                                              style={{
                                                height: "50px",
                                                width: "50px",
                                              }}
                                              src={
                                                config.imageUrl + profile.backImage
                                              }
                                            />
                                          )}
                                      {error.length > 0 && error[0].name == 'backImage' ? <div><span className='alert_validation text-danger'>{error[0].err}</span></div> : ''}

                                    </div>
                                    <span>Back side photo</span>
                                  </div>
                                </div>
                              </div>

                              <div className="field mb-4">
                                <div className="field__label">
                                  Upload Profile Image
                                </div>
                                <div className="uploadbox1 mb-2">
                                  {profile.profile_image !== null ?
                                    <ImageUploader
                                      style={{ backgroundImage: `url(${config.imageUrl}${profile.profile_image})`, backgroundPosition: "center" }}
                                      onFileAdded={(img) =>
                                        getImageFileObject(img, 3)
                                      }
                                      onFileRemoved={(img) =>
                                        runAfterImageDelete(img)
                                      }
                                    /> : ""}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="entry__wrap">
                        <div className="entry__box">
                          <div className="entry__details">
                            <div className="entry__code">
                              <img
                                src="img/content/qr-code.jpg"
                                alt="Qr code"
                              />
                            </div>
                            <button className="button-stroke entry__button">
                              Download app
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          {profile.status == 1 ? (
            <p style={{ color: "green", textAlign: "center" }}>
              Your Documents are Approved
            </p>
          ) : profile.status == 2 ? (
            <p style={{ color: "red", textAlign: "center" }}>
              Your Documents are Rejected. Please try again.
            </p>
          ) : (
            ""
          )}
          <Row>
            <Col
              xl={6}
              lg={6}
              md={6}
              xs={12}
              className="mt-3 mx-auto text-center"
            >
              <Button
                variant="custombtn1"
                className="px-4 w-100"
                onClick={(e) => submitForm(e)}
              >
                Save
              </Button>
            </Col>
          </Row>
        </div>
      </Col>
    </>
  );
};

export default ProfileEdit;
