import React, { Component, useEffect, useState } from "react";
import LoginHeader from "../directives/header";
import Footer from "../directives/footer";
import {  Row, Col,  Dropdown } from 'react-bootstrap';
import { CiSearch } from "react-icons/ci";
import { BsCalendarRange } from "react-icons/bs";
import { IoIosArrowRoundForward } from "react-icons/io";



const Activity = () => {


    return (
        <>
            <div className="outer">
                <LoginHeader />
                <div className="outer__inner">
                    <div className="activity">
                        <div className="activity__center center">
                            <div className="activity__wrapper">

                                <Row className=''>
                                    <Col xl={6} lg={6} md={12} xs={12} className="d-flex align-items-center mb-lg-0 mb-md-5 mb-5">
                                        <div className="nav">
                                            <button className="nav__link active">All type</button>
                                            <button className="nav__link">Withdrawals</button>
                                            <button className="nav__link">Deposit</button>
                                            <button className="nav__link">Transfer</button>
                                            <button className="nav__link">Trade</button>
                                        </div>
                                    </Col>
                                    <Col xl={6} lg={6} md={12} xs={12} className="">
                                        <Row className=''>
                                            <Col xl={7} lg={7} md={6} xs={12} className="d-flex align-items-center">
                                                <div className="searchbox ">
                                                    <input
                                                        type="search"
                                                        className="form-control"
                                                        placeholder="Search"
                                                    />
                                                    <span>
                                                        <CiSearch className="searchIcon" />
                                                    </span>
                                                </div>
                                            </Col>

                                            <Col xl={5} lg={5} md={6} xs={12} className="">
                                                <div className='calanderdrop ms-lg-2 ms-md-2 ms-0'>
                                                    <Dropdown className=''>
                                                        <Dropdown.Toggle variant="" id="dropdown-basic" className=' '>
                                                            All time <span><BsCalendarRange className='calenderIcon' /></span>
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                                                            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                                                            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <div className="activity__top">
                                </div>



                                <div className="activity__line">
                                    <h2 className="activity__title h4">Activity</h2>
                                    <div className="activity__item activity__item_export js-activity-item">
                                        {/* <button className="button-small activity__button js-activity-button">
                                            <svg className="icon icon-arrow-bottom">
                                                <use xlinkHref="#icon-arrow-bottom" />
                                            </svg>
                                            <span>Export</span>
                                        </button> */}
                                        <div className="activity__body js-activity-body">
                                            <div className="activity__datepicker js-date-container" />
                                            <input
                                                className="activity__range js-date-range"
                                                type="text"
                                                data-single-month="true"
                                                data-container=".js-date-container"
                                            />
                                            <button className="button activity__button js-activity-button disabled">
                                                Download .CSV
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="activity__table">
                                    <div className="activity__row">
                                        <div className="activity__col">
                                            <div className="sorting">Type</div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="sorting">Coin</div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="sorting">Amount</div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="sorting">Address</div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="sorting">Transaction ID</div>
                                        </div>
                                        <div className="activity__col">Date</div>
                                    </div>
                                    <a className="activity__row" href="buy-crypto.html">
                                        <div className="activity__col">
                                            <div className="category-blue activity__category">Withdrew</div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__coin">
                                                <div className="activity__icon">
                                                    <img src="img/content/currency/bitcoin.svg" alt="Bitcoin" />
                                                </div>
                                                <div className="activity__currency">Bitcoin</div>
                                            </div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Amount</div>1,641.20 BTC
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Address</div>3DkQyAdif6kQLPMBu
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Transaction ID</div>
                                            3DkQyAdif6kQLPMBu
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Date</div>2021-06-05 04:12:30
                                        </div>
                                    </a>
                                    <a className="activity__row" href="buy-crypto.html">
                                        <div className="activity__col">
                                            <div className="category-green activity__category">Deposited</div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__coin">
                                                <div className="activity__icon">
                                                    <img src="img/content/currency/bitcoin.svg" alt="Bitcoin" />
                                                </div>
                                                <div className="activity__currency">Bitcoin</div>
                                            </div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Amount</div>1,641.20 BTC
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Address</div>3DkQyAdif6kQLPMBu
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Transaction ID</div>
                                            3DkQyAdif6kQLPMBu
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Date</div>2021-06-05 04:12:30
                                        </div>
                                    </a>
                                    <a className="activity__row" href="buy-crypto.html">
                                        <div className="activity__col">
                                            <div className="category-blue activity__category">Withdrew</div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__coin">
                                                <div className="activity__icon">
                                                    <img src="img/content/currency/bitcoin.svg" alt="Bitcoin" />
                                                </div>
                                                <div className="activity__currency">Bitcoin</div>
                                            </div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Amount</div>1,641.20 BTC
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Address</div>3DkQyAdif6kQLPMBu
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Transaction ID</div>
                                            3DkQyAdif6kQLPMBu
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Date</div>2021-06-05 04:12:30
                                        </div>
                                    </a>
                                    <a className="activity__row" href="buy-crypto.html">
                                        <div className="activity__col">
                                            <div className="category-green activity__category">Deposited</div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__coin">
                                                <div className="activity__icon">
                                                    <img src="img/content/currency/bitcoin.svg" alt="Bitcoin" />
                                                </div>
                                                <div className="activity__currency">Bitcoin</div>
                                            </div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Amount</div>1,641.20 BTC
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Address</div>3DkQyAdif6kQLPMBu
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Transaction ID</div>
                                            3DkQyAdif6kQLPMBu
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Date</div>2021-06-05 04:12:30
                                        </div>
                                    </a>
                                    <a className="activity__row" href="buy-crypto.html">
                                        <div className="activity__col">
                                            <div className="category-green activity__category">Deposited</div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__coin">
                                                <div className="activity__icon">
                                                    <img src="img/content/currency/bitcoin.svg" alt="Bitcoin" />
                                                </div>
                                                <div className="activity__currency">Bitcoin</div>
                                            </div>
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Amount</div>1,641.20 BTC
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Address</div>3DkQyAdif6kQLPMBu
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Transaction ID</div>
                                            3DkQyAdif6kQLPMBu
                                        </div>
                                        <div className="activity__col">
                                            <div className="activity__label">Date</div>2021-06-05 04:12:30
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="post">
                                <div className="post__list">
                                    <div className="post__item">
                                        <div
                                            className="post__preview"
                                            style={{ backgroundColor: "#E88C03" }}
                                        >
                                            <img
                                                src="assets/images/bankDeposit.png"
                                                alt="Post"
                                            />
                                        </div>
                                        <div className="post__details">
                                            <div className="post__title">Bank deposit</div>
                                            <div className="post__content">
                                                Deposit crypto or cash currency to your wallet and start trading
                                                on the world largest exchange!
                                            </div>
                                            <a
                                                className="button-stroke button-small post__button"
                                                href="#"
                                            >
                                                Bank deposit <span><IoIosArrowRoundForward className="arrowIcons" /></span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="post__item">
                                        <div
                                            className="post__preview"
                                            style={{ backgroundColor: "#CC52E8" }}
                                        >
                                            <img
                                                src="assets/images/transferImg.png"
                                                alt="Post"
                                            />
                                        </div>
                                        <div className="post__details">
                                            <div className="post__title">Transfer</div>
                                            <div className="post__content">
                                                Internal transfers are free on Bitcloud.
                                            </div>
                                            <a
                                                className="button-stroke button-small post__button js-popup-open"
                                                href="#"
                                                data-effect="mfp-zoom-in"
                                            >
                                                Transfer coin <span><IoIosArrowRoundForward className="arrowIcons" /></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="popup popup_transfer mfp-hide" id="popup-transfer">
                        <div className="popup__title h4">
                            <svg className="icon icon-arrow-left">
                                <use xlinkHref="#icon-arrow-left" />
                            </svg>
                            Transfer
                        </div>
                        <div className="popup__wrap">
                            <div className="popup__category">
                                Available <br />
                                balance
                            </div>
                            <div className="popup__details">
                                <div className="popup__currency">2.56213968 ETH</div>
                                <div className="popup__price">$10,095.35</div>
                            </div>
                        </div>
                        <div className="field">
                            <div className="field__label">from</div>
                            <div className="field__wrap">
                                <select className="select">
                                    <option>Margin</option>
                                    <option>Fiat and Spot</option>
                                </select>
                            </div>
                        </div>
                        <div className="popup__sign">
                            <svg className="icon icon-arrows">
                                <use xlinkHref="#icon-arrows" />
                            </svg>
                        </div>
                        <div className="field">
                            <div className="field__label">to</div>
                            <div className="field__wrap">
                                <select className="select">
                                    <option>Fiat and Spot</option>
                                    <option>Margin</option>
                                </select>
                            </div>
                        </div>
                        <div className="field">
                            <div className="field__label">coin</div>
                            <div className="field__wrap">
                                <select className="select">
                                    <option>ETH Ethereum</option>
                                    <option>BTC Bitcoin</option>
                                    <option>TRX Tron</option>
                                </select>
                            </div>
                        </div>
                        <div className="field field_button">
                            <div className="field__label">Amount to transfer</div>
                            <div className="field__wrap">
                                <input
                                    className="field__input"
                                    type="text"
                                    name="amount"
                                    required="required"
                                />
                                <button className="button-stroke button-small field__button">
                                    Max amount
                                </button>
                            </div>
                            <div className="field__note">2.14646231064565653 ETH available</div>
                        </div>
                        <button className="button popup__button">Transfer</button>
                    </div>
                </div>



                <Footer />
            </div>
        </>
    );
};
export default Activity;
