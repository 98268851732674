import React, { useState, useRef } from 'react';
import { Row, Col, Button, Form } from 'react-bootstrap';
import Header from "../directives/header";
import toast, { Toaster } from "react-hot-toast";
import '../components/componentCss/profile.css'
import config from "../coreFiles/config";
import { check2faCode } from '../coreFiles/action'
import Cookies from 'js-cookie';
import Swal from 'sweetalert2'




const Profile2faOne = (props) => {
  const loginData = (!Cookies.get('loginASIEX2FA')) ? {} : JSON.parse(Cookies.get('loginASIEX2FA'));
  const [code, setCode] = useState({ one: '', two: '', three: '', four: '', five: '', six: "", tab: 1 })

  const inputOneRef = useRef(null);
  const inputTwoRef = useRef(null);
  const inputThreeRef = useRef(null);
  const inputFourRef = useRef(null);
  const inputFiveRef = useRef(null);
  const inputSixRef = useRef(null);

  const submitCode = async () => {
    let res = await check2faCode({ secretKey: `${code.one}${code.two}${code.three}${code.four}${code.five}${code.six}`, userId: loginData.id })
    if (res.success) {

      Cookies.set("loginASIEX", JSON.stringify(loginData));
      setCode({ one: '', two: '', three: '', four: '', five: '', six: "", tab: 1, userId: loginData.id })
      if (loginData.kycupload == false) {

        await Swal.fire({
          title: 'Please Update Your Personal Info!',
          icon: 'info',
          width: 500,
          confirmButtonColor: '#3772FF',
          allowOutsideClick: false,
          confirmButtonText: 'Continue',
        });

        setTimeout(() => {
          window.location.href = `${config.baseUrl}editprofile`;
        }, 200);

      } else {
        toast.success(res.msg);
        setTimeout(() => {
          window.location.href = `${config.baseUrl}Wallet`;
        }, 200);
      }

    } else {
      toast.error(res.msg)
    }
  }

  const focusNextInput = (currentTab, value) => {

    if (currentTab === 1 && value) inputTwoRef.current.focus();
    else if (currentTab === 2 && value) inputThreeRef.current.focus();
    else if (currentTab === 3 && value) inputFourRef.current.focus();
    else if (currentTab === 4 && value) inputFiveRef.current.focus();
    else if (currentTab === 5 && value) inputSixRef.current.focus();
    else if (currentTab === 2 && !value) inputOneRef.current.focus();
    else if (currentTab === 3 && !value) inputTwoRef.current.focus();
    else if (currentTab === 4 && !value) inputThreeRef.current.focus();
    else if (currentTab === 5 && !value) inputFourRef.current.focus();
    else if (currentTab === 6 && !value) inputFiveRef.current.focus();
  };

  return (
    <>
      <div className="outer">
        <Header />
        <Toaster />
        <div className="outer__inner">
          <div className="login">

            <Col xl={8} lg={8} md={12} xs={12} className="pt-5 mx-auto">
              <div className="profileContent p-5">
                <Row>
                  <Col xl={12} lg={12} md={12} xs={12} className="">

                    <div className='profile'>
                      <h2 className='mb-4 text-white'>2FA<span className='ms-1 maincolor2'>Enabled</span></h2>
                      <p className='text-center'>Enter 6-digit code from Google Authenticate to make sure <br />everything works</p>
                    </div>
                  </Col>
                </Row>

                <Form className='pt-4'>
                  <Row>
                    <Col xl={9} lg={12} md={12} xs={12} className="verifyContent mx-auto">
                      <Row>
                        <Col xl={2} lg={2} md={2} xs={2} className="">
                          <Form.Group className="mb-3 verifyFiled">
                            <Form.Control
                              type="tel"
                              onKeyPress={(event) => {
                                if (!/^\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              ref={inputOneRef}
                              value={code.one}
                              placeholder=""
                              autoFocus={code.one.length === 0 && code.tab === 1}

                              onChange={(e) => {
                                const newValue = e.target.value.slice(0, 1);
                                if (code.one.length <= 1) {
                                  setCode((prevCode) => ({
                                    ...prevCode,
                                    one: newValue,
                                  }));
                                }
                                focusNextInput(1, newValue);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl={2} lg={2} md={2} xs={2} className="">
                          <Form.Group className="mb-3 verifyFiled">
                            <Form.Control
                              type="tel"
                              onKeyPress={(event) => {
                                if (!/^\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder=""
                              value={code.two}
                              ref={inputTwoRef}
                              onChange={(e) => {
                                const newValue = e.target.value.slice(0, 1);
                                if (code.two.length <= 1) {
                                  setCode((prevCode) => ({
                                    ...prevCode,
                                    two: newValue,
                                  }));
                                }
                                focusNextInput(2, newValue);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl={2} lg={2} md={2} xs={2} className="">
                          <Form.Group className="mb-3 verifyFiled">
                            <Form.Control
                              type="tel"
                              onKeyPress={(event) => {
                                if (!/^\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder=""
                              value={code.three}
                              ref={inputThreeRef}
                              onChange={(e) => {
                                const newValue = e.target.value.slice(0, 1);
                                if (code.three.length <= 1) {
                                  setCode((prevCode) => ({
                                    ...prevCode,
                                    three: newValue,
                                  }));
                                }
                                focusNextInput(3, newValue);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl={2} lg={2} md={2} xs={2} className="">
                          <Form.Group className="mb-3 verifyFiled">
                            <Form.Control
                              type="tel"
                              onKeyPress={(event) => {
                                if (!/^\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder=""
                              value={code.four}
                              ref={inputFourRef}
                              onChange={(e) => {
                                const newValue = e.target.value.slice(0, 1);
                                if (code.four.length <= 1) {
                                  setCode((prevCode) => ({
                                    ...prevCode,
                                    four: newValue,
                                  }));
                                }
                                focusNextInput(4, newValue);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl={2} lg={2} md={2} xs={2} className="">
                          <Form.Group className="mb-3 verifyFiled">
                            <Form.Control
                              type="tel"
                              onKeyPress={(event) => {
                                if (!/^\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder=""
                              value={code.five}
                              ref={inputFiveRef}
                              onChange={(e) => {
                                const newValue = e.target.value.slice(0, 1);
                                if (code.five.length <= 1) {
                                  setCode((prevCode) => ({
                                    ...prevCode,
                                    five: newValue,
                                  }));
                                }
                                focusNextInput(5, newValue);
                              }}
                            />
                          </Form.Group>
                        </Col>
                        <Col xl={2} lg={2} md={2} xs={2} className="">
                          <Form.Group className="mb-3 verifyFiled">
                            <Form.Control
                              type="tel"
                              onKeyPress={(event) => {
                                if (!/^\d{0,1}$/.test(event.key)) {
                                  event.preventDefault();
                                }
                              }}
                              placeholder=""

                              ref={inputSixRef}
                              value={code.six}
                              onChange={(e) => {
                                const newValue = e.target.value.slice(-1); // Only take the last character
                                if (code.six.length <= 1) {
                                  setCode((prevCode) => ({
                                    ...prevCode,
                                    six: newValue,
                                  }));
                                }
                                focusNextInput(6, newValue);
                              }}
                            />
                          </Form.Group>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Form>

                <Row>
                  <Col xl={6} lg={6} md={6} xs={12} className=" mx-auto">
                    <Row>
                      <Col xl={6} lg={6} md={6} xs={12} className="pt-4">
                        <Button
                          variant="custombtn2"
                          className="px-4 w-100"
                          onClick={e => window.location.href = `${config.baseUrl}sign-in`}
                        >
                          Back
                        </Button>
                      </Col>
                      <Col xl={6} lg={6} md={6} xs={12} className="pt-4">
                        <Button
                          variant="custombtn1"
                          className="px-4 w-100"
                          onClick={e => submitCode(e)}
                        >
                          Submit
                        </Button>
                      </Col>
                    </Row>
                  </Col>

                </Row>



              </div>
            </Col>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    </>
  )
}

export default Profile2faOne;
