import React, { useEffect, useState } from 'react';
import {  Row, Col, Button,} from 'react-bootstrap';

import toast, { Toaster } from "react-hot-toast";
import '../components/componentCss/profile.css'

import { coinListAPI, getBalance, withdrawRequest, getDashboardStatisticsUserAction } from '../coreFiles/action'

import { Link } from 'react-router-dom';

import 'react-image-upload/dist/index.css'
import Swal from 'sweetalert2'



const Withdraw = () => {

    const [withdrawForm, setwithdrawForm] = useState({ amount: "", currencyId: 5, bankName: '', accountName: '', accountNumber: '' })
    const [balance, setbalance] = useState(0)
    const [settingCount, setSettingCount] = useState("");

    useEffect(() => {
        getbanks()
        getCoinBalance()
        getDashboardStatisticsUser()
    }, [])

    const getDashboardStatisticsUser = async () => {
        let res = await getDashboardStatisticsUserAction();
        if (res.success) {
            setSettingCount(res.data);
        }
        if (res.data.withdraw == 0) {
            await Swal.fire({
                title: '<h1 style="font-size: 20px;">Withdraw functionality has been temporarily disabled by the admin; please be patient.</h1>',
                icon: 'info',
                width: 500,
                // font-size:1.6,
                //  fontSize:1.6,
                confirmButtonColor: '#3772FF',
                allowOutsideClick: false,
                confirmButtonText: 'Continue',
            })
        }

    };


    const getCoinBalance = async () => {
        let res = await getBalance({ leftCoinId: 1, rightCoinId: 5 });
        if (res.success) {
            setbalance(res.data.rightCoinBalance)
        }
    }

    const getbanks = async () => {
        let res = await coinListAPI()
        if (res.success) {
        }
    }

    const handler = (e) => {
        const { name, value } = e.target
        setwithdrawForm((old) => {
            return { ...old, [name]: value }
        })
    }

    const submitForm = async (e) => {
        var amount = parseFloat(withdrawForm.amount);
        var minWithdraw = parseFloat(settingCount.minWithdraw);
        var maxWithdraw = parseFloat(settingCount.maxWithdraw);

        if (amount < minWithdraw) {
            toast.error("Amount should be greater than Min Withdraw");
            return;
        }

        else if (amount > maxWithdraw) {
            toast.error("Amount should be less than Max Withdraw");
            return;
        }

        else {

            let res = await withdrawRequest(withdrawForm)
            if (res.status == true || res.success == true) {
                toast.success(res.msg);
                setwithdrawForm({ amount: 0, currencyId: 5, bankName: '', accountName: '', accountNumber: '' })
                getCoinBalance()
            } else {
                toast.error(res.msg)
            }
        }
    }

    const cancelBtn = () => {
        window.location.reload();
    };


    return (
        <>
            <Toaster />
            <Col xl={8} lg={8} md={12} xs={12} className="pt-lg-5 pt-md-5 pt-3">
                <div className="profileContent p-lg-5 p-md-5 p-3 pt-4 mt-lg-0 mt-md-0 mt-0">
                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="">

                            <div className='withdraw_sec'>
                                <h2 className='mb-4'>Withdraw</h2>

                                <div className='balanceDiv mt-5 mb-5'>
                                    <span>Balance</span>
                                    <h6 translate="no">{parseFloat(balance).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')} USDT </h6>
                                </div>
                                <Row>
                                    <Col xl={12} lg={12} md={12} xs={12} className="pt-3">
                                        <div className="detailBlock">
                                            <div className="detailBlockleft">
                                                <ul>
                                                    <li>
                                                        <h6>Minimum Withdraw</h6>
                                                    </li>
                                                    <li>
                                                        <h6>Maximum Withdraw </h6>
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className="detailBlockright">
                                                <ul>
                                                    <li>
                                                        <h6 translate="no">{parseFloat(settingCount.minWithdraw).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$</h6>
                                                    </li>
                                                    <li>
                                                        <h6 translate="no">{parseFloat(settingCount.maxWithdraw).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}$</h6>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col xl={12} lg={12} md={12} xs={12} className="">
                                     

                                        <div className="field field_view mb-4">
                                            <div className="field__label">Bank Name</div>
                                            <div className="field__wrap">
                                                <input
                                                    className="field__input"
                                                    type="text"
                                                    name="bankName"
                                                    onChange={e => handler(e)}
                                                    value={withdrawForm.bankName}
                                                    placeholder="Enter Bank Name"
                                                    required=""
                                                />

                                              
                                                <button className="field__view">
                                                 
                                                </button>
                                            </div>
                                        </div>

                                        <div className="field field_view mb-4">
                                            <div className="field__label">Bank account number</div>
                                            <div className="field__wrap">
                                                <input
                                                    className="field__input"
                                                    type="text"
                                                    onKeyPress={(event) => {
                                                        if (!/^\d{0,1}$/.test(event.key)) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    name="accountNumber"
                                                    onChange={e => handler(e)}
                                                    value={withdrawForm.accountNumber}
                                                    placeholder="Enter Bank Account Number"
                                                    required=""
                                                />
                                                <button className="field__view">
                                                   
                                                </button>
                                            </div>
                                        </div>

                                        <div className="field field_view mb-4">
                                            <div className="field__label">Bank Account Holder name</div>
                                            <div className="field__wrap">
                                                <input
                                                    className="field__input"
                                                    type="text"
                                                    name="accountName"
                                                    onChange={e => handler(e)}
                                                    value={withdrawForm.accountName}
                                                    placeholder="Enter Bank account Holder Name"
                                                    required=""
                                                />
                                                <button className="field__view">
                                                   
                                                </button>
                                            </div>
                                        </div>

                                        <div className="field field_view mb-4">
                                            <div className="field__label">Amount</div>
                                            <div className="field__wrap">
                                                <input
                                                    className="field__input"
                                                    type="text"
                                                    onKeyPress={(event) => {
                                                        const { value } = event.target;
                                                        if (
                                                            (event.key === "." && value.includes(".")) || // Prevent more than one decimal point
                                                            (!/^\d$/.test(event.key) && event.key !== ".") // Allow only digits and one decimal point
                                                        ) {
                                                            event.preventDefault();
                                                        }
                                                    }}
                                                    name="amount"
                                                    onChange={e => handler(e)}
                                                    value={withdrawForm.amount}
                                                    placeholder="Enter Amount (USDT)"
                                                    required=""
                                                />
                                                <button className="field__view">
                                         
                                                </button>
                                            </div>
                                        </div>

                                    </Col>
                                </Row>
                                <Row>
                                    <div className=" freeInputbox mb-4">
                                 
                                        <div className="input-group mb-3">
                                            <span className="input-group-text ps-0" id="basic-addon1">
                                                Fee
                                            </span>
                                            <input
                                                type="text"
                                                className="form-control pe-0"
                                                placeholder="0.00"
                                                value={`${settingCount.withdrawFee}%`}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                            />
                                        </div>

                                    </div>
                                </Row>
                                <Row>
                                    <div className="field field_view mb-4">
                                        <div className="field__label">another chóices</div>
                                        <Row>
                                            <Col xl={4} lg={4} md={6} xs={12} className="pt-3">
                                                <Button
                                                    variant="custombtn2"
                                                    className="px-4 w-100"
                                                    onClick={e => setwithdrawForm(old => {
                                                        return { ...old, ['amount']: 50 }
                                                    })}
                                                >
                                                    50 USDT
                                                </Button>
                                            </Col>

                                            <Col xl={4} lg={4} md={6} xs={12} className="pt-3">
                                                <Button
                                                    variant="custombtn2"
                                                    className="px-4 w-100"
                                                    onClick={e => setwithdrawForm(old => {
                                                        return { ...old, ['amount']: 100 }
                                                    })}
                                                >
                                                    100 USDT
                                                </Button>
                                            </Col>
                                            <Col xl={4} lg={4} md={6} xs={12} className="pt-3">
                                                <Button
                                                    variant="custombtn2"
                                                    className="px-4 w-100"
                                                    onClick={e => setwithdrawForm(old => {
                                                        return { ...old, ['amount']: 200 }
                                                    })}
                                                >
                                                    200 USDT
                                                </Button>
                                            </Col>
                                            <Col xl={4} lg={4} md={6} xs={12} className="pt-3">
                                                <Button
                                                    variant="custombtn2"
                                                    className="px-4 w-100"
                                                    onClick={e => setwithdrawForm(old => {
                                                        return { ...old, ['amount']: 500 }
                                                    })}
                                                >
                                                    500 USDT
                                                </Button>
                                            </Col>
                                            <Col xl={4} lg={4} md={6} xs={12} className="pt-3">
                                                <Button
                                                    variant="custombtn2"
                                                    className="px-4 w-100"
                                                    onClick={e => setwithdrawForm(old => {
                                                        return { ...old, ['amount']: 1000 }
                                                    })}
                                                >
                                                    1000 USDT
                                                </Button>
                                            </Col>
                                            <Col xl={4} lg={4} md={6} xs={12} className="pt-3">
                                                <Button
                                                    variant="custombtn2"
                                                    className="px-4 w-100"
                                                    onClick={e => setwithdrawForm(old => {
                                                        return { ...old, ['amount']: 10000 }
                                                    })}
                                                >
                                                    10000 USDT
                                                </Button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Row>
                                <Row>
                                    <Col xl={6} lg={6} md={6} xs={12} className="pt-lg-5 pt-md-5 pt-3">
                                        <Button
                                            variant="custombtn2"
                                            className="px-4 w-100"
                                        >
                                            <Link to="" onClick={cancelBtn}> Cancel</Link>
                                        </Button>
                                    </Col>
                                    <Col xl={6} lg={6} md={6} xs={12} className="pt-lg-5 pt-md-5 pt-3">
                                        <Button
                                            disabled={settingCount.withdraw == 0}
                                            variant="custombtn1"
                                            className="px-4 w-100"
                                            onClick={e => submitForm(e)}
                                        >
                                            Confirm
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>


                </div>
            </Col>



        </>
    )
}

export default Withdraw;
