
import './App.css';
import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Cookies from 'js-cookie';
import config from './coreFiles/config';
import Home from './components/home'
import SignUp from './components/signup';
import SignIn from './components/signin';
import Forgot from './components/forgotpassword';
import Market from './components/market';
import Discover from './components/discover';
import Wallet from './components/wallet';
import Activity from './components/activity';
import Depositbank from './components/depositbank';
import Notification from './components/notification';
import Verification from './components/verification';
import ResetPassword from './components/resetPassword';
import Exchangenew from './components/exchangenew';
import Profile from './components/profile';
import Profile2fa from './components/profile2fa';
import Refferral from './components/referral';
import Rewardshistory from './components/rewardshistory';
import Referralshistory from './components/referralshistory';
import Login2fa from './components/login2fa';
import Ticket from './components/ticket';
import Supportchat from './components/supportchat';
import AboutUs from './components/aboutus';
import PrivacyPolicy from './components/privacyPolicy';
import TermsCondition from './components/termsCondition';
import Announcement from './components/announcement';


function App() {
  const loginData = (!Cookies.get('loginASIEX')) ? {} : JSON.parse(Cookies.get('loginASIEX'));
  useEffect(() => {
    const hideGoogleTranslateIcon = () => {
      const styleElement = document.createElement('style');
      styleElement.innerHTML = `
        .goog-te-banner-frame.skiptranslate {
          display: none !important;
        }
        body {
          top: 0px !important;
        }
      `;
      document.head.appendChild(styleElement);
    };

    hideGoogleTranslateIcon();
  }, []);

  return (
    <BrowserRouter>
      <div>
        {loginData && loginData?.authToken ?
          <Routes>
            <Route path={`*`} element={<Home />} />
            <Route path={`${config.baseUrl}`} element={<Home />} />
            <Route path={`${config.baseUrl}Market`} element={<Market />} />
            <Route path={`${config.baseUrl}Discover`} element={<Discover />} />
            <Route path={`${config.baseUrl}Wallet`} element={<Wallet />} />
            <Route path={`${config.baseUrl}Activity`} element={<Activity />} />
            <Route path={`${config.baseUrl}Depositbank`} element={<Depositbank />} />
            <Route path={`${config.baseUrl}Notification`} element={<Notification />} />
            <Route path={`${config.baseUrl}resetPassword/:encryptemail`} element={<ResetPassword />} />
            <Route path={`${config.baseUrl}exchangenew`} element={<Exchangenew />} />
            <Route path={`${config.baseUrl}exchangenew/:symbol`} element={<Exchangenew />} />
            <Route path={`${config.baseUrl}profile`} element={<Profile />} />
            <Route path={`${config.baseUrl}profile2fa`} element={<Profile2fa />} />
            <Route path={`${config.baseUrl}rewardshistory`} element={<Rewardshistory />} />
            <Route path={`${config.baseUrl}referralshistory`} element={<Referralshistory />} />
            <Route path={`${config.baseUrl}ticket`} element={<Ticket />} />
            <Route path={`${config.baseUrl}supportchat`} element={<Supportchat />} />
            <Route path={`${config.baseUrl}refferral`} element={<Refferral />} />
            <Route path={`${config.baseUrl}Deposit`} element={<Profile />} />
            <Route path={`${config.baseUrl}Withdraw`} element={<Profile />} />
            <Route path={`${config.baseUrl}2faauthentication`} element={<Profile />} />
            <Route path={`${config.baseUrl}editprofile`} element={<Profile />} />
            <Route path={`${config.baseUrl}referral`} element={<Profile />} />
            <Route path={`${config.baseUrl}changepassword`} element={<Profile />} />
            <Route path={`${config.baseUrl}about-us`} element={<AboutUs />} />
            <Route path={`${config.baseUrl}privacy-policy`} element={<PrivacyPolicy />} />
            <Route path={`${config.baseUrl}terms-condition`} element={<TermsCondition />} />
            <Route path={`${config.baseUrl}announcement`} element={<Announcement />} />
            {/* <Route path={`${config.baseUrl}Deposit`} element={<Profile />} /> */}

          </Routes> : <Routes>
            <Route path={`*`} element={<SignIn />} />
            <Route path={`${config.baseUrl}`} element={<Home />} />
            <Route path={`${config.baseUrl}sign-up`} element={<SignUp />} />
            <Route path={`${config.baseUrl}login2fa`} element={<Login2fa />} />
            <Route path={`${config.baseUrl}Market`} element={<Market />} />

            <Route path={`${config.baseUrl}sign-up/:referralcode`} element={<SignUp />} />
            <Route path={`${config.baseUrl}sign-in`} element={<SignIn />} />
            <Route path={`${config.baseUrl}Forgot`} element={<Forgot />} />

            <Route path={`${config.baseUrl}resetPassword/:encryptemail`} element={<ResetPassword />} />
            <Route path={`${config.baseUrl}exchangenew`} element={<Exchangenew />} />
            <Route path={`${config.baseUrl}exchangenew/:symbol`} element={<Exchangenew />} />

            <Route path={`${config.baseUrl}about-us`} element={<AboutUs />} />
            <Route path={`${config.baseUrl}privacy-policy`} element={<PrivacyPolicy />} />
            <Route path={`${config.baseUrl}terms-condition`} element={<TermsCondition />} />
            <Route path={`${config.baseUrl}announcement`} element={<Announcement />} />
          </Routes>}
      </div>
    </BrowserRouter>
  );
}

export default App;
