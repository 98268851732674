import React, { Component, useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { Container, Row, Col, Button, Accordion, Table } from "react-bootstrap";
import "../components/componentCss/home.css";
import Spinner from "react-bootstrap/Spinner";
import config from "../coreFiles/config";
import {
  getWidgetListAction,
  getDashboardStatisticsUserAction,
  getFaqListAction,
  getannouncementAction
} from "../coreFiles/action";
import Cookies from "js-cookie";
import { Link, useNavigate } from 'react-router-dom';
const Home = () => {
  const navigate = useNavigate();
  const [widgetList, setWidgetList] = useState([]);
  const [faqList, setFaqList] = useState([]);
  const [announcement, setannouncement] = useState({ title: "" });
  const [userCount, setUserCount] = useState([]);
  const loginData = !Cookies.get("loginASIEX")
    ? {}
    : JSON.parse(Cookies.get("loginASIEX"));

  useEffect(() => {
    getWidgetList();
    getDashboardStatisticsUser();
    getannouncement()
    getFaqList();
    // if (loginData.id == undefined && window.location.href == `${config.baseUrl}sign-in`) {
    //   window.location.href=`${config.baseUrl}sign-in`
    // } 
  }, []);


  const getannouncement = async () => {
    let res = await getannouncementAction();
    if (res.success) {
      setannouncement(res.data);
    }
  };

  const getWidgetList = async () => {
    let res = await getWidgetListAction();
    if (res.success) {
      setWidgetList(res.data);
    }
  };

  const getFaqList = async () => {
    let res = await getFaqListAction();
    if (res.status) {
      setFaqList(res.data);
    }
  };

  const getDashboardStatisticsUser = async () => {
    let res = await getDashboardStatisticsUserAction();
    if (res.success) {
      setUserCount(res.data);
    }
  };

  const signupPage = async () => {
    if (loginData?.id == undefined) {
      navigate(`${config.baseUrl}sign-up`);
    } else {
      navigate(`${config.baseUrl}market`);
    }
  };

  return (
    <>
      <Header />

      <section className="banner pt-lg-5 pt-md-5 pt-3 pb-lg-5 pb-md-5 pb-3">
        <Container>
          <Row className="">
            <Col lg={6} md={6} sm={6} xs={12}>
              <div className="bnrtText mt-lg-5 mt-md-5 pt-lg-5 pt-md-5">
                {userCount.userCount ? <h1 className="mb-3">{String(userCount.userCount).replace(/^[+-]?\d+/, function (int) {
                  return int.replace(/(\d)(?=(\d{3})+$)/g, '$1,');
                })}</h1> : <h1>Loading...</h1>}
                <h2 className="mb-4">User Trust Us</h2>
                <p>
                  Trade Bitcoin, Ethereum, USDT, and the top altcoins on the
                  legendary crypto asset exchange.
                </p>
                {loginData?.id == undefined ? (
                  <h6>Sign up now and get up to 100 USDT in rewards</h6>
                ) : (
                  ""
                )}
                <div className="pt-4">
                  <Button
                    variant="custombtn1"
                    onClick={signupPage}
                    className="px-4 "
                  >
                    {loginData?.id == undefined ? "Sign Up Now" : "Explore"}
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg={6} md={6} sm={6} xs={12} className="pt-5 mt-5">
              <div className="bnrtableBox mb-4">
                <div className="bnrtableinrBox">
                  <div>
                    <img
                      src="assets/icons/circleImg.png"
                      className="circleImg"
                    />
                    <img
                      src="assets/icons/sendarrow.png"
                      className="sendarrow"
                    />
                    <img
                      src="assets/icons/cursorIcon.png"
                      className="cursorIcon"
                    />
                  </div>
                  <ul>
                    {widgetList.length > 0 ? widgetList.slice(0, 4).map((item, index) => (
                      <li>
                        <div className="innerDetail">
                          <div>
                            <img
                              src={`img/coinImage/${item.symbol}.png`}
                              style={{ width: "32px", height: "32px" }}
                              className="btcIcon me-3"
                            />
                            <span>{item.symbol}</span>
                          </div>
                          <p translate="no" className="mb-0">
                            ${parseFloat(item.price).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}
                          </p>
                          <span>
                            <a translate="no" href="#" className="orangebtn">
                              {parseFloat(item.percentage24).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}%
                            </a>
                          </span>
                        </div>
                      </li>
                    )) : <li> <Spinner
                      as="span"
                      animation="border"

                      role="status"
                      aria-hidden="true"
                    />
                      Loading...</li>}
                  </ul>

                  <div className="viewbox">
                    <a href={`${config.baseUrl}Market`}>
                      View All 100+ Coins{" "}
                      <span>
                        <img
                          src="assets/icons/viewarrow.svg"
                          className="viewarrow ms-3"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>

              <div className="bnrtableBox">
                {/* <p>
                  Báo cáo Nghiên cứu Thị trường hàng tháng của Binance - Báo cáo
                  T9 2023
                </p>
                <p>
                  Ủy Ban Giám Sát Tài Chính Đài Loan Tiết Lộ 52 Nền Tảng Crypto
                  Đang Nhắm Đến Việc Thâm Nhập Thị Trường Địa
                </p>
                <p>
                  Ủy Ban Giám Sát Tài Chính Đài Loan Tiết Lộ 52 Nền Tảng Crypto
                  Đang Nhắm Đến Việc Thâm Nhập Thị Trường Địa
                </p> */}
                {announcement?.title?.length == 0 ?
                  <li> <Spinner
                    as="span"
                    animation="border"

                    role="status"
                    aria-hidden="true"
                  />
                    Loading...</li> : <div dangerouslySetInnerHTML={{ __html: announcement?.title?.length > 0 ? announcement?.title.substr(0, 400) : "" }}></div>
                }

                <div className="viewbox">
                  <a href={`${config.baseUrl}announcement`}>
                    View All News{" "}
                    <span>
                      <img
                        src="assets/icons/viewarrow.svg"
                        className="viewarrow ms-3"
                      />
                    </span>
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="faq_sec pt-lg-5 pt-md-5 pt-3 pb-lg-5 pb-md-5 pb-3">
        <Container>
          <Row className="">
            <Col lg={12} className="pt-5">
              <h1 className="text-white fw-bold fs-1">
                Frequently Asked Questions
              </h1>
            </Col>
          </Row>
          <Row className="">
            <Col lg={12} className="pt-5 mb-4">
              <div className="inner_block">
                <Row className="">
                  <Col lg={12} className="">
                    <div className="faq_accordion">
                      {/* <Accordion defaultActiveKey={['0']} alwaysOpen> */}
                      {faqList.map((item, index) => {
                        return (
                          <Accordion defaultActiveKey={[1]}>
                            <Accordion.Item
                              eventKey={index + 1}
                              className="mb-3  active"
                            >
                              <Accordion.Header>
                                <div className="d-flex align-items-center">
                                  <div className="numBox">{index + 1}</div>
                                  <div>{item.question}</div>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body className=" pt-0 ps-lg-5 ps-md-5 ps-3 ms-lg-4 ms-md-4 ms-0">
                                <p className=" mb-1">{item.answer}</p>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        );
                      })}
                    </div>
                    <div className="text-center mt-4"></div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="verified_sec pt-lg-5 pt-md-5 pt-3 pb-lg-5 pb-md-5 pb-3">
        <Container>
          <Row className="">
            <Col lg={12} className="">

              <h1 className="">
                For Verified Users <br />
                Get up to<span> 100 USDT</span> in rewards
              </h1>


              <div className="text-center pt-4">
                <Button
                  variant="custombtn1"
                  className="px-4 me-3"
                  onClick={signupPage}
                >
                  {loginData?.id == undefined ? "Sign Up Now" : "Explore"}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
};
export default Home;
