import React, { Component, useEffect, useState } from "react";
import LoginHeader from "../directives/header";
import Footer from "../directives/footer";


const Depositbank = () => {


    return (
        <>
            <div className="outer">
                <LoginHeader />
                <div className="outer__inner">
                    <div className="bidding js-bidding">
                        <div className="bidding__head">
                            <div className="bidding__center center">
                                <h2 className="bidding__title h2">Deposit fiat</h2>
                                <div className="bidding__info">
                                    How to buy crypto on Bitcloud
                                    <a className="bidding__link" href="learn-crypto.html">
                                        Learn now
                                        <svg className="icon icon-arrow-right">
                                            <use xlinkHref="#icon-arrow-right" />
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="bidding__body">
                            <div className="bidding__center center">
                                <div className="bidding__steps">
                                    <div className="bidding__step next js-bidding-step">
                                        <div className="bidding__number">1</div>Select currency
                                    </div>
                                    <div className="bidding__step js-bidding-step">
                                        <div className="bidding__number">2</div>Important notes
                                    </div>
                                    <div className="bidding__step js-bidding-step">
                                        <div className="bidding__number">3</div>Payment details
                                    </div>
                                </div>
                                <div className="bidding__wrapper">
                                    <div className="bidding__item js-bidding-item">
                                        <div className="currency">
                                            <div className="currency__title">
                                                Select currency and payment method
                                            </div>
                                            <div className="currency__row">
                                                <div className="currency__col">
                                                    <div className="currency__label">Select currency</div>
                                                    <div className="field">
                                                        <select className="select">
                                                            <option>USD</option>
                                                            <option>EUR</option>
                                                            <option>RUB</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="currency__col">
                                                    <div className="currency__label">Select payment</div>
                                                    <div className="field">
                                                        <select className="select">
                                                            <option>Bank (SWIFT)</option>
                                                            <option>Bank (EUR)</option>
                                                            <option>Bank (USD)</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="currency__label">Amount</div>
                                            <div className="currency__payment">
                                                <div className="currency__sign h4">$</div>
                                                <div className="currency__field">
                                                    <div className="currency__value">50.00</div>
                                                    <input
                                                        className="currency__input"
                                                        type="text"
                                                        defaultValue={50.0}
                                                    />
                                                </div>
                                            </div>
                                            <div className="currency__price">
                                                {" "}
                                                <span className="currency__result">50</span>
                                                <span className="currency__note">USDT</span>
                                            </div>
                                            <div className="currency__variants">
                                                <button className="button-stroke button-small currency__button active">
                                                    50.00$
                                                </button>
                                                <button className="button-stroke button-small currency__button">
                                                    100.00$
                                                </button>
                                                <button className="button-stroke button-small currency__button">
                                                    200.00$
                                                </button>
                                                <button className="button-stroke button-small currency__button">
                                                    500.00$
                                                </button>
                                            </div>
                                            <div className="currency__btns">
                                                <button className="button currency__button js-bidding-button">
                                                    Continue
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bidding__item js-bidding-item">
                                        <div className="notes">
                                            <div className="notes__title">Important notes</div>
                                            <div className="notes__text">
                                                We ONLY accept fundings from a bank account under your own name:
                                            </div>
                                            <div className="notes__info">Veum Cecilia</div>
                                            <div className="notes__text">
                                                You MUST include the Reference Code in your deposit in order to
                                                credit your account! <br />
                                                Reference Code:
                                            </div>
                                            <div className="notes__code">BLUTUKHY34PB</div>
                                            <div className="notes__btns">
                                                <button className="button notes__button js-bidding-button">
                                                    Continue
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="bidding__item js-bidding-item">
                                        <div className="notes">
                                            <div className="notes__title">Payment details</div>
                                            <div className="notes__info">Bank account</div>
                                            <div className="notes__list">
                                                <div className="notes__line">
                                                    <div className="notes__subtitle">Account name</div>
                                                    <div className="notes__details">
                                                        <div className="notes__content">Veum Cecilia</div>
                                                        <button className="notes__copy">
                                                            <svg className="icon icon-copy">
                                                                <use xlinkHref="#icon-copy" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="notes__line">
                                                    <div className="notes__subtitle">Account number</div>
                                                    <div className="notes__details">
                                                        <div className="notes__content">548422222221</div>
                                                        <button className="notes__copy">
                                                            <svg className="icon icon-copy">
                                                                <use xlinkHref="#icon-copy" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="notes__line">
                                                    <div className="notes__subtitle">Address</div>
                                                    <div className="notes__details">
                                                        <div className="notes__content">079 Dariana Knoll, CA</div>
                                                        <button className="notes__copy">
                                                            <svg className="icon icon-copy">
                                                                <use xlinkHref="#icon-copy" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="notes__line">
                                                    <div className="notes__subtitle">SWIFT Code</div>
                                                    <div className="notes__details">
                                                        <div className="notes__content">UI8</div>
                                                        <button className="notes__copy">
                                                            <svg className="icon icon-copy">
                                                                <use xlinkHref="#icon-copy" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="notes__line">
                                                    <div className="notes__subtitle">Bank Address</div>
                                                    <div className="notes__details">
                                                        <div className="notes__content">
                                                            55416 Powlowski Spring, CA
                                                        </div>
                                                        <button className="notes__copy">
                                                            <svg className="icon icon-copy">
                                                                <use xlinkHref="#icon-copy" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="notes__info">Reference code</div>
                                            <div className="notes__text">
                                                You MUST include the Reference Code in your deposit in order to
                                                credit your account! <br />
                                                Reference Code:
                                            </div>
                                            <div className="notes__code">BLUTUKHY34PB</div>
                                            <div className="notes__foot">
                                                <a
                                                    className="button-stroke notes__button"
                                                    href="wallet-overview.html"
                                                >
                                                    View wallet
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-bg section-mb0 popular">
                        <div className="popular__center center">
                            <div className="popular__head">
                                <h2 className="popular__title h2">Become a crypto trader in seconds</h2>
                                <div className="popular__info">
                                    We've got everything you need to start trading.
                                </div>
                            </div>
                            <div className="popular__wrapper">
                                <div className="popular__slider js-slider-popular">
                                    <div className="popular__item">
                                        <div className="popular__preview">
                                            <img
                                                srcSet="img/content/card-pic-1@2x.png 2x"
                                                src="img/content/card-pic-1.png"
                                                alt="Card"
                                            />
                                        </div>
                                        <div className="popular__subtitle">Buy &amp; Sell Crypto</div>
                                        <div className="popular__content">
                                            We realize ideas from simple to complex, everything becomes easy
                                            to use and reach the most potential customers.
                                        </div>
                                        <a
                                            className="button-stroke button-small popular__button"
                                            href="buy-crypto.html"
                                        >
                                            Buy crypto
                                        </a>
                                    </div>
                                    <div className="popular__item">
                                        <div className="popular__preview">
                                            <img
                                                srcSet="img/content/card-pic-2@2x.png 2x"
                                                src="img/content/card-pic-2.png"
                                                alt="Card"
                                            />
                                        </div>
                                        <div className="popular__subtitle">Trade Assets</div>
                                        <div className="popular__content">
                                            We realize ideas from simple to complex, everything becomes easy
                                            to use and reach the most potential customers.
                                        </div>
                                        <a
                                            className="button-stroke button-small popular__button"
                                            href="exchange.html"
                                        >
                                            Trade now
                                        </a>
                                    </div>
                                    <div className="popular__item">
                                        <div className="popular__preview">
                                            <img
                                                srcSet="img/content/card-pic-3@2x.png 2x"
                                                src="img/content/card-pic-3.png"
                                                alt="Card"
                                            />
                                        </div>
                                        <div className="popular__subtitle">Learn crypto</div>
                                        <div className="popular__content">
                                            We realize ideas from simple to complex, everything becomes easy
                                            to use and reach the most potential customers.
                                        </div>
                                        <a
                                            className="button-stroke button-small popular__button"
                                            href="learn-crypto.html"
                                        >
                                            Learn now
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className="popular__btns">
                                <a className="button popular__button" href="contact.html">
                                    Contact Us
                                </a>
                            </div>
                        </div>
                    </div>
                </div>





                <Footer />
            </div>
        </>
    );
};
export default Depositbank;
