import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Form, InputGroup } from 'react-bootstrap';
import { getReferralsEarning } from '../coreFiles/action'
import toast, { Toaster } from "react-hot-toast";
import '../components/componentCss/profile.css';
import config from "../coreFiles/config";
import copy from "copy-to-clipboard";
import { Link } from 'react-router-dom';
import { FaCopy } from "react-icons/fa";

import 'react-image-upload/dist/index.css'




const Refferral = (props) => {

    const [earning, setearning] = useState(0)

    const nextScreen = (v) => {
        props.nextScreen(v)
    }

    useEffect(() => {
        getReferralEarning()
    }, [])

    const getReferralEarning = async () => {
        let ress = await getReferralsEarning()
        if (ress.success) {
            setearning(ress.data.amount)
        }
    }

    const copyToClipboard = (e) => {
        copy(`${config.webSiteUrl}${config.baseUrl}sign-up/${props.code}`);
        alert(`You have copied ${config.webSiteUrl}${config.baseUrl}sign-up/${props.code}"`);
    }


    return (
        <>
            <Toaster />
            <Col xl={8} lg={8} md={12} xs={12} className="pt-lg-5 pt-md-5 pt-3">
                <div className="profileContent p-lg-5 p-md-5 p-3 pt-4 mt-lg-0 mt-md-0 mt-0">
                    <Row>
                        <Col xl={12} lg={12} md={12} xs={12} className="">

                            <div className='refferral_sec'>
                                <h2 className='mb-4'>Referrals</h2>
                                <div className='tatalReward mt-5'>
                                    <h6>Total rewards</h6>
                                    <h2 translate="no" className='mb-3'>{parseFloat(earning).toFixed(4).replace(/\d(?=(\d{3})+\.)/g, '$&,')}<span className='ms-1 maincolor2'>USDT</span></h2>
                                    <p>You're earning of the trading fees your <br />
                                        referrals pay. Learn more</p>
                                </div>
                                <div className='inviteDiv mt-5 mb-5'>
                                    <h3>Invite friends to earn</h3>
                                    <div className="field field_view mb-4 pt-4">
                                        <div className="field__label">Referral link</div>

                                        <div className="field__wrap">
                                            <InputGroup className="">
                                                <Form.Control
                                                    value={`${config.webSiteUrl}${config.baseUrl}sign-up/${props.code}`}
                                                    placeholder="https://spacebitz.espsofttech.org/referrals"
                                                    aria-label="Username"
                                                    aria-describedby="basic-addon1" className='field__input'
                                                    type="text"
                                                    name=""
                                                />
                                                <InputGroup.Text id="basic-addon1"><FaCopy onClick={e => copyToClipboard(e)} className='copyicons' /></InputGroup.Text>
                                            </InputGroup>
                                        </div>

                                    </div>
                                </div>

                                <Row>
                                    <Col xl={10} lg={10} md={12} xs={12} className="mx-auto">
                                        <Row>
                                            <Col xl={6} lg={6} md={6} xs={12} className="mb-3">
                                                <Button
                                                    variant="custombtn2"
                                                    className="px-4 w-100"
                                                    onClick={e => nextScreen(11)}
                                                >
                                                    Referrals Histories
                                                </Button>
                                            </Col>
                                            <Col xl={6} lg={6} md={6} xs={12} className="mb-3">
                                                <Button
                                                    variant="custombtn2"
                                                    className="px-4 w-100"
                                                    onClick={e => nextScreen(13)}
                                                >
                                                    Rewards Histories
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>


                </div>
            </Col>
        </>
    )
}

export default Refferral;
