import React, { Component, useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import { Link, NavLink, useParams } from 'react-router-dom'
import Cookies from 'js-cookie';
import config from "../coreFiles/config";
import { userregister, countryList, getDashboardStatisticsUserAction } from '../coreFiles/action'
import toast, { Toaster } from "react-hot-toast";
import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";
import { MdOutlineMail } from "react-icons/md";
import Swal from 'sweetalert2';
import { IoArrowBackOutline } from "react-icons/io5";



const SignUp = () => {
    const { referralcode } = useParams()
    const [userData, setuserData] = useState({ username: '', referrercode: referralcode ? referralcode : '', countryCode: '', email: '', password: '', confirm_password: "", phone: '', checkBox: false })
    const [error, seterror] = useState([])
    const [phideshow, setphideshow] = useState(false)
    const [cphideshow, setcphideshow] = useState(false)
    const [countryLists, setcountryList] = useState([])
    const [settingCount, setSettingCount] = useState({});

    useEffect(() => {
        getCountry()
        getDashboardStatisticsUser();
    }, [])

    const getDashboardStatisticsUser = async () => {
        let res = await getDashboardStatisticsUserAction();
        if (res.success) {
            setSettingCount(res.data);
        }
        if (res.data.register == 0) {

            await Swal.fire({
                title: '<h1 style="font-size: 20px;">Register functionality has been temporarily disabled by the admin; please be patient.</h1>',
                icon: 'info',
                width: 500,
                confirmButtonColor: '#3772FF',
                allowOutsideClick: false,
                confirmButtonText: 'Continue',
            });


        }
    };

    const getCountry = async () => {
        let res = await countryList()
        if (res.success) {
            let array = []
            for (let x in res.data) {
                let obj = {}
                obj.label = `+${res.data[x].phonecode}`
                obj.value = res.data[x].phonecode
                array.push(obj)
            }
            setcountryList(array)
        }

    }

    const changeHandler = (e) => {
        const { name, value } = e.target
        setuserData((old) => {
            return { ...old, [name]: value }
        })
    }

    const validate = () => {

        let errors = [];

        if (userData.email == '') {
            errors.push({ name: 'email', err: "Email is Required!" });
        }

        if (userData.email.search(/\S+@\S+\.\S+/) < 0) {
            errors.push({ name: "email", err: "Enter Valid E-mail!" });
        }

        if (userData.password == '') {
            errors.push({ name: "password", err: "Password is Required!" });
        }


        if (userData.password.length < 6) {
            errors.push({ name: "password", err: "Your password must be at least 6 characters" });
        }
        if (userData.password.search(/[a-z]/i) < 0) {
            errors.push({ name: "password", err: "Your password must contain at least one letter." });
        }
        if (userData.password.search(/[0-9]/) < 0) {
            errors.push({ name: "password", err: "Your password must contain at least one number." });
        }


        if (userData.confirm_password == '') {
            errors.push({ name: "confirm_password", err: "Confirm password is required!" });
        }

        if (userData.phone == '') {
            errors.push({ name: 'phone', err: "Phone number is required!" });
        }

        if (!(/^(\+|\d)[0-9]{7,16}$/).test(userData.phone)) {
            errors.push({ name: 'phone', err: "Phone number is not valid!" });
        }

        if (userData.referrercode == '') {
            errors.push({ name: 'referrercode', err: "Referral code is required!" });
        }

        if (userData.checkBox == false) {
            errors.push({ name: 'checkBox', err: "Please Check the Box to proceed!" });
        }

        if (errors.length > 0) {

            seterror(errors)

            return false;
        }
        seterror(errors)
        return true;
    }

    const SubmitForm = async (e) => {
        e.preventDefault();
        const isValid = validate();
        if (!isValid) {
        } else {
            let res = await userregister(userData);
            if (res.success) {
                toast.success(res.msg);
                setTimeout(() => {
                    window.location.href = `${config.baseUrl}sign-in`;
                }, 200);
            } else {
                toast.error(res.msg);
            }
        }
    }

    const loginDirect = () => {
        window.location.href = `${config.baseUrl}`
    }



    return (
        <>
            <div className="outer">

                <Toaster />
                <div className="outer__inner">
                    <div className="login">
                        <div onClick={loginDirect}
                            className="login__col"
                            style={{ backgroundImage: 'url("assets/images/loginBack.png")', cursor: "pointer" }}
                        >
                            <Link className="login__link" to={`${config.baseUrl}`}></Link>

                        </div>

                        <div className="login__col " style={{ paddingTop: "90px" }}>
                            <div className="backdiv d-lg-none d-md-none d-block">
                                <Link to={`${config.baseUrl}`}><span><IoArrowBackOutline className="backbtnIcon" /></span></Link>
                            </div>
                            <div className="respologo"  >
                                <img src="assets/images/mainLogo2.svg" className="" />
                            </div>
                            <div className="login__head">
                                Have an account?
                                <Link className="login__link" to={`${config.baseUrl}sign-in`}>
                                    Log in now
                                </Link>
                            </div>
                            <div className="login__wrap">
                                <div className="registration">
                                    <div className="registration__form">
                                        <div className="registration__top">
                                            <h3 className="registration__title h3 text-white">Register to Spacebit</h3>
                                        </div>

                                        <div className="registration__fieldset">

                                            <div className="field field_view mb-3">
                                                <div className="field__label">email</div>
                                                <div className="field__wrap mb-3">
                                                    <input
                                                        className="field__input"
                                                        value={userData.email}
                                                        onChange={e => changeHandler(e)}
                                                        type="email"
                                                        name="email"
                                                        placeholder="Email address"
                                                        required=""
                                                    />

                                                    <span className="field__view eye_color" >
                                                        <MdOutlineMail className="mailIcon" />
                                                    </span>
                                                </div>
                                                {error.length > 0 && error[0].name == 'email' ? <div><span className='alert_validation'>{error[0].err}</span></div> : ''}
                                            </div>
                                            <div className="field field_view mb-3">
                                                <div className="field__label">password</div>
                                                <div className="field__wrap mb-3">
                                                    <input
                                                        className="field__input"
                                                        type={phideshow == false ? "password" : "text"}
                                                        value={userData.password}
                                                        onChange={e => changeHandler(e)}
                                                        name="password"
                                                        placeholder="Password"
                                                        required=""
                                                    />
                                                    <span className="field__view eye_color" onClick={e => setphideshow(!phideshow)}>
                                                        {phideshow == false ?
                                                            <FaRegEye className="eyeIcon" />
                                                            : <FaRegEyeSlash className="eyeIcon" />}
                                                    </span>
                                                </div>
                                                {error.length > 0 && error[0].name == 'password' ? <div><span className='alert_validation'>{error[0].err}</span></div> : ''}
                                            </div>
                                            <div className="field field_view mb-3">
                                                <div className="field__label">confirm password</div>
                                                <div className="field__wrap mb-3">
                                                    <input
                                                        className="field__input"
                                                        type={cphideshow == false ? "password" : "text"}
                                                        value={userData.confirm_password}
                                                        onChange={e => changeHandler(e)}
                                                        name="confirm_password"
                                                        placeholder="Password"
                                                        required=""
                                                    />
                                                    <span className="field__view eye_color" onClick={e => setcphideshow(!cphideshow)}>
                                                        {cphideshow == false ?
                                                            <FaRegEye className="eyeIcon" />
                                                            : <FaRegEyeSlash className="eyeIcon" />}
                                                    </span>
                                                </div>
                                                {error.length > 0 && error[0].name == 'confirm_password' ? <div><span className='alert_validation'>{error[0].err}</span></div> : ''}
                                            </div>
                                            <div className="field field_view mb-3">
                                                <div className="field__label">Phone Number</div>
                                                <div className="field__wrap mb-3">
                                                    <div className="row">

                                                        <div className="col-lg-12 col-md-12 col-12">
                                                            <input
                                                                className="field__input"
                                                                type="text"
                                                                onKeyPress={(event) => {
                                                                    if (!/^\d*[]?\d{0,1}$/.test(event.key)) {
                                                                        event.preventDefault();
                                                                    }
                                                                }}
                                                                value={userData.phone}
                                                                onChange={e => changeHandler(e)}
                                                                name="phone"
                                                                placeholder="Enter phone number"
                                                                required=""
                                                            />
                                                        </div>
                                                    </div>
                                                    <button className="field__view">
                                                        <svg className="icon icon-eye">
                                                            <use xlinkHref="#icon-eye" />
                                                        </svg>
                                                    </button>
                                                </div>
                                                {error.length > 0 && error[0].name == 'phone' ? <div><span className='alert_validation'>{error[0].err}</span></div> : ''}
                                                {error.length > 0 && error[0].name == 'countryCode' ? <div><span className='alert_validation'>{error[0].err}</span></div> : ''}

                                            </div>
                                            <div className="field mb-3">
                                                <div className="field__label">Referral Code</div>
                                                <div className="field__wrap mb-3">
                                                    <input
                                                        className="field__input"
                                                        onChange={e => changeHandler(e)}
                                                        value={userData.referrercode}
                                                        type="text"
                                                        name="referrercode"
                                                        placeholder="Enter referral code"
                                                        required=""
                                                    />
                                                </div>
                                                {error.length > 0 && error[0].name == 'referrercode' ? <div><span className='alert_validation'>{error[0].err}</span></div> : ''}
                                            </div>
                                            <label className="checkbox">
                                                <input
                                                    className="checkbox__input"
                                                    type="checkbox"
                                                    onClick={e => setuserData((old) => {
                                                        return { ...old, ['checkBox']: !userData.checkBox }
                                                    })}
                                                    defaultChecked={userData.checkBox}
                                                />
                                                <span className="checkbox__inner">
                                                    <span className="checkbox__tick" />
                                                    <span className="checkbox__text">
                                                        By signing up I agree <a className="checkbox__link" href={`${config.baseUrl}privacy-policy`}>
                                                            Privacy Policy
                                                        </a>{" "}
                                                        ,{" "}
                                                        <a className="checkbox__link" href={`${config.baseUrl}terms-condition`}>
                                                            Terms & Conditions
                                                        </a>{" "}
                                                        and
                                                        I’m 18 years of age or older
                                                        .
                                                    </span>
                                                </span>
                                                {error.length > 0 && error[0].name == 'checkBox' ? <div><span className='alert_validation'>{error[0].err}</span></div> : ''}
                                            </label>
                                        </div>
                                        <button className="button registration__button" disabled={settingCount.register == 0} onClick={e => SubmitForm(e)}>Sign up</button>
                                    </div>
                                    <div className="registration__confirm">
                                        <h3 className="registration__title h3">
                                            Let’s confirm it’s really you
                                        </h3>
                                        <div className="registration__info">
                                            <p>Help us secure your account.</p>
                                            <p>Please complete the verifications below</p>
                                        </div>
                                        <div className="registration__variants">
                                            <label className="radio">
                                                <input
                                                    className="radio__input"
                                                    type="radio"
                                                    name="confirm"
                                                    defaultChecked=""
                                                />
                                                <span className="radio__inner">
                                                    <span className="radio__tick" />
                                                    <span className="radio__text">
                                                        Get the code by text message (SM) at{" "}
                                                        <span className="radio__phone">+1 234 567 890</span>
                                                    </span>
                                                </span>
                                            </label>
                                            <label className="radio">
                                                <input className="radio__input" type="radio" name="confirm" />
                                                <span className="radio__inner">
                                                    <span className="radio__tick" />
                                                    <span className="radio__text">
                                                        Get the code by email at{" "}
                                                        <span className="radio__email">
                                                            tranm••••••••••••@gm•••.com
                                                        </span>
                                                    </span>
                                                </span>
                                            </label>
                                        </div>
                                        <button className="button registration__button">
                                            <span>Continue</span>
                                            <svg className="icon icon-arrow-right">
                                                <use xlinkHref="#icon-arrow-right" />
                                            </svg>
                                        </button>
                                    </div>
                                    <div className="registration__code">
                                        <h3 className="registration__title h3">Enter your security code</h3>
                                        <div className="registration__info">
                                            We texted your code to +1 234 567 890
                                        </div>
                                        <div className="registration__numbers">
                                            <div className="registration__number">
                                                <input type="tel" />
                                            </div>
                                            <div className="registration__number">
                                                <input type="tel" />
                                            </div>
                                            <div className="registration__number">
                                                <input type="tel" />
                                            </div>
                                            <div className="registration__number">
                                                <input type="tel" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </>
    );
};
export default SignUp;
