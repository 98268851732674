import React, { Component, useEffect, useState } from "react";
import Header from "../directives/header";
import Footer from "../directives/footer";
import config from "../coreFiles/config";
import toast, { Toaster } from "react-hot-toast";
import { login } from "../coreFiles/action";
import { resetAction } from '../coreFiles/action'
import { Link, useNavigate, useParams } from "react-router-dom";


import { FaRegEye, FaRegEyeSlash } from "react-icons/fa";


const ResetPassword = () => {
  let { encryptemail } = useParams();
  const [data, setdata] = useState({
    confirmPassword: "",
    password: "",
    authuserToken: encryptemail,
  });
  const [validationerror, setvalidationerror] = useState({
    confirmPasswordError: "",
    passworderror: "",
  });
  const [eyeDisplay, setEyedisplay] = useState(true);
  const [eyeDisplay1, setEyedisplay1] = useState(true);

  const [showpassword, setshowpassword] = useState(false);
  const [showpassword1, setshowpassword1] = useState(false);

  const [resendMailShow, setResendMailShow] = useState(false);
  const navigate = useNavigate();

  const inputhandler = (e) => {
    const { name, value } = e.target;
    setdata({ ...data, [name]: value });
    setvalidationerror({ ...validationerror, [name]: "" });
  };

  const validations = () => {
    let isValid = true;
    const errors = {};
    const passwordFormat =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.*[^\s]).{8,}$/;

    if (!data.password) {
      errors.passworderror = "Create Your Password";
      isValid = false;
    } 
    else if (data.password.length < 6) {
      errors.passworderror = "Password must be at least 6 characters long";
      isValid = false;
    }
  

    if (!data.confirmPassword) {
      errors.confirmPasswordError = "Enter Your Confirm Password";
      isValid = false;
    }
     else if (data.confirmPassword.length < 6) {
      errors.confirmPasswordError =
        "Confirm Password must be at least 6 characters long";
      isValid = false;
    } 
  

    if (data.password != data.confirmPassword) {
      errors.confirmPasswordError = "Password Not Matched";
      isValid = false;
    }

    setvalidationerror(errors);
    return isValid;
  };

  const showpasswordfunc1 = () => {
    setEyedisplay(false);
    setshowpassword(!showpassword);
  };

  const showpasswordfunc = () => {
    setEyedisplay(true);

    setshowpassword(!showpassword);
  };

  const showpasswordfunc2 = () => {
    setEyedisplay1(true);
    setshowpassword1(!showpassword1);
  };

  const showpasswordfunc3 = () => {
    setEyedisplay1(false);

    setshowpassword1(!showpassword1);
  };

  const resetPasswordAction = async (e) => {
    e.preventDefault();

    const isValid = validations();

    if (!isValid) {
    } else {
      try {
        let res = await resetAction(data);

        if (res.success) {
          toast.success(res.msg);

          setTimeout(() => {
            navigate(`${config.baseUrl}sign-in`);
          }, 1000);
        } else {
          setResendMailShow(true);
          toast.error(res.msg);
        }
      } catch (error) {
        toast.error("Something Went Wrong! Please Try Again");
      }
    }
  };

  return (
    <>
      <div className="outer">
        {/* <Header /> */}
        <Toaster />
        <div className="outer__inner">
          <div className="login">
            <div
              className="login__col"
              style={{ backgroundImage: 'url("assets/images/loginBack.png")' }}
            >
          
            </div>
            <div className="login__col">
              <div className="respologo">
                <img src="assets/images/mainLogo.svg" className="" />
              </div>
              <div className="login__head">
                Don’t have an account?
                <Link className="login__link" to={`${config.baseUrl}sign-up`}>
                  Register for free
                </Link>
              </div>
              <div className="login__wrap">
                <div className="entry">
                  <div className="entry__top border-0 p-0">
                    <h3 className="entry__title h3 text-white">New password</h3>
                  </div>
                  <div className="entry__form">
                    <div className="entry__tabs js-tabs">
                      <div className="field field_view mb-4">
                        <div className="field__label">Enter Your New password</div>
                        <div className="field__wrap">
                          <input
                            className="field__input"
                            type={`${showpassword ? "name" : "password"}`}
                            name="password"
                            onChange={inputhandler}
                            placeholder="Password"
                            required=""
                          />
                          <button className="field__view">
                            {eyeDisplay == false ? (
                              <FaRegEye
                                className="eyeIcon"
                                style={{ cursor: "pointer" }}
                                onClick={showpasswordfunc}
                              />
                            ) : (
                              <FaRegEyeSlash
                                className="eyeIcon"
                                style={{ cursor: "pointer" }}
                                onClick={showpasswordfunc1}
                              />
                            )}
                          </button>
                        </div>
                        <div className="validationerror">
                            {validationerror.passworderror}
                          </div>
                      </div>

                      <div className="field field_view">
                        <div className="field__label">Renter your new password </div>
                        <div className="field__wrap">
                          <input
                            className="field__input"
                            type={`${showpassword1 ? "name" : "password"}`}
                              name="confirmPassword"
                              onChange={inputhandler}
                            placeholder="Password"
                            required=""
                          />
                          <button className="field__view">
                          {eyeDisplay1 == false ? (
                                <FaRegEye
                                  className="eyeIcon"
                                  style={{ cursor: "pointer" }}
                                  onClick={showpasswordfunc2}
                                />
                              ) : (
                                <FaRegEyeSlash
                                  className="eyeIcon"
                                  style={{ cursor: "pointer" }}
                                  onClick={showpasswordfunc3}
                                />
                              )}
                          </button>
                        </div>
                        <div className="validationerror">
                            {validationerror.confirmPasswordError}
                          </div>
                      </div>

                      <a
                        className="button entry__button"
                        onClick={resetPasswordAction}

                      >
                        Continue
                      </a>
                    </div>
                  </div>
                  <div className="entry__wrap">
                    <div className="entry__box">
                      <div className="entry__details">
                        <div className="entry__code">
                          <img src="img/content/qr-code.jpg" alt="Qr code" />
                        </div>
                        <button className="button-stroke entry__button">
                          Download app
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* <Footer /> */}
      </div>
    </>
  );
};
export default ResetPassword;
